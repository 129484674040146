import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Provider } from "react-redux";
import store from "./store";
import axios from "axios";
import { Router } from "react-router-dom";
import { GlobalStyles } from "@mui/material";
import { createBrowserHistory } from 'history';
import ScrollToTop from "./components/ScrollToTop";

axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

const globalStyle = {
  html: {
    "scroll-behavior": "smooth",
  },
  body: {
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
      width: "0.4em",
      height: "0.4em",
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
      backgroundColor: "#FF6104",
      outline: "1px solid slategrey",
      borderRadius: "5px",
    },
    "& .cookies": {
      background: "rgb(255, 255, 255) !important",
      color: "#000 !important",
      "& button": {
        fontSize: "16px !important",
        borderRadius: "5px !important",
        paddingRight: "22px !important",
        paddingLeft: "22px !important",
      },
      "& button:first-of-type": {
        backgroundColor: "#8C6AD4 !important",
      },
      "& button:nth-of-type(2)": {
        backgroundColor: "#FF6104 !important",
        color: "#FFF !important",
      },
    },
  },
};

const browserHistory = createBrowserHistory();
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={browserHistory}>
        <>
          <GlobalStyles styles={globalStyle} />
          <ScrollToTop />
          <App />
        </>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
