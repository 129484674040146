import { PurchasedPack } from "./Pack";
import { ShoppingCard } from "./ShoppingCard";

export enum ReservationResultType {
    OK, Error, Reserved, SessionExists
}

export interface ReservationProposalResult {
    proposal: ShoppingCard;
    result: ReservationResultType;
}

export interface ReservationProposalRequest {
    coachId: number;
    specialityId: number;
    placeId: string;
    date: Date;
}

export enum AddPackType {
    OK, Error
}

export interface AddPackResult {
    proposal: ShoppingCard | PurchasedPack;
    result: AddPackType;
}
