import axios from 'axios';
import { HOPLA_URL } from '../config/config';
import { UpdateUserResult } from '../models/Client';
import { AddFileResult, CoachFiles, CoachView, FileResultType } from '../models/Coach';
import { Location } from '../models/Location';
import { Speciality } from '../models/Speciality';

export const updateCoach = async (email: string, image: string, location: Location | null, phone: string | null, specialities: Speciality[]) : Promise<UpdateUserResult> => {
    return (await axios.post(HOPLA_URL + '/coach/update',
    {
        image,
        location,
        phone,
        email,
        specialities
    })).data;
}

export const getCoach = async (coachId: number) : Promise<CoachView> => {
    return (await axios.get(HOPLA_URL + '/coach/' + coachId)).data;
}

export const getMonthlyCoach = async () : Promise<CoachView> => {
    return (await axios.get(HOPLA_URL + '/coach/monthlyCoach')).data;
}

export const getCoachFiles = async (coachId: number) : Promise<CoachFiles[]> => {
    return (await axios.get(HOPLA_URL + '/coach/files/' + coachId)).data;
}

export const addCoachFile = async (name: string, content: string, extension: string) : Promise<AddFileResult> => {
    return (await axios.post(HOPLA_URL + '/coach/addFile',
    {
        name, content, extension
    })).data;
}

export const removeCoachFile = async (fileName: string) : Promise<FileResultType> => {
    return (await axios.post(HOPLA_URL + '/coach/removeFile', {name: fileName})).data;
}

export const renameCoachFile = async (fileName: string, newFileName: string) : Promise<FileResultType> => {
    return (await axios.post(HOPLA_URL + '/coach/renameFile', {name: fileName, newName: newFileName})).data;
}

export const getBody = async (fileName: string) : Promise<any> => {
    return (await axios.post(HOPLA_URL + '/coach/getFile', {name: fileName}, {responseType: 'blob'})).data;
}

export const canAddComment = async(coachId: number) : Promise<boolean> => {
    return (await axios.get(HOPLA_URL + '/comment/canAdd/' + coachId)).data;
}

export const addComment = async(coachId: number, note: number, comment: string) : Promise<boolean> => {
    return (await axios.post(HOPLA_URL + '/comment/add', {coachId, comment, note})).data;
}