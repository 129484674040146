import { getCookieConsentValue } from 'react-cookie-consent';
import { getCookie, setCookie, removeCookie } from 'typescript-cookie';
import { COOKIE_DOMAIN } from '../config/config';

export interface TokenEntry {
    isClient: boolean;
    id: number;
    token: string;
}

const key = "entry";

export const areCookiesAccepted = (): boolean => {
    return getCookieConsentValue() === 'true';
}

export const getTokenEntry = () : TokenEntry | undefined => {
    if (getCookieConsentValue()) {
        return JSON.parse(getCookie(key) ?? '{}') as TokenEntry;
    }

    return undefined;
}

export const removeToken = () => {
    removeCookie(key, 
    {
        domain: COOKIE_DOMAIN
    });
}

export const setToken = (id: number, isClient: boolean, token: string) => {
    if (areCookiesAccepted() === false) return;

    setCookie(key, JSON.stringify({ id, isClient, token}), 
    {
        expires: limitDate(),
        domain: COOKIE_DOMAIN
    });
}

const limitDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 10);
    return date;
}