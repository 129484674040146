import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Coach } from '../models/Coach'
import { Client } from '../models/Client'
import { CreateRequest, LoginResult, LoginResultType } from '../models/Login'

export enum LoggedType { None, Client, Coach };

export interface LoginState {
  client: Client | undefined,
  coach: Coach | undefined,
  loggedType: LoggedType,
  token?: string
}

const initialState: LoginState = {
  client: undefined,
  coach: undefined,
  loggedType: LoggedType.None,
  token: undefined
}

export const loginSlice = createSlice({
  name: 'loginSlice',
  initialState,
  reducers: {
    LOGIN_USER: (state: LoginState, action: PayloadAction<{email: string, password: string}>) => state,
    LOGIN_USER_RESULT: (state: LoginState, action: PayloadAction<LoginResult>) => {
      if (action.payload.result === LoginResultType.OK)
      {
        state.loggedType = LoggedType.Client;
        state.client = action.payload.client;
        state.token = action.payload.token;
      }
    },
    DISCONNECT: (state: LoginState) => {
      state.loggedType = LoggedType.None;
      state.client = undefined;
      state.token = undefined;
      state.coach = undefined;
    },
    LOGIN_COACH: (state: LoginState, action: PayloadAction<{email: string, password: string}>) => state,
    LOGIN_COACH_RESULT: (state: LoginState, action: PayloadAction<LoginResult>) => {
      if (action.payload.result === LoginResultType.OK)
      {
        state.loggedType = LoggedType.Coach;
        state.coach = action.payload.coach;
        state.token = action.payload.token;
      }
    },
    SIGN_IN_USER: (state: LoginState, action: PayloadAction<CreateRequest>) => state,
    SIGN_IN_COACH: (state: LoginState, action: PayloadAction<CreateRequest>) => state,
    RELOG_USER: (state: LoginState, action: PayloadAction<{id: number, token: string}>) => state,
    RELOG_USER_RESULT: (state: LoginState, action: PayloadAction<LoginResult>) => {
      if (action.payload.result === LoginResultType.OK)
      {
        state.loggedType = LoggedType.Client;
        state.client = action.payload.client;
        state.token = action.payload.token;
      }
    },
    RELOG_COACH: (state: LoginState, action: PayloadAction<{id: number, token: string}>) => state,
    RELOG_COACH_RESULT: (state: LoginState, action: PayloadAction<LoginResult>) => {
      if (action.payload.result === LoginResultType.OK)
      {
        state.loggedType = LoggedType.Coach;
        state.coach = action.payload.coach;
        state.token = action.payload.token;
      }
    },
    UNLOG_USER: (state: LoginState) => {
      state.client = undefined;
      state.coach = undefined;
      state.loggedType = LoggedType.None;
    },
    SET_TOKEN: (state: LoginState, action: PayloadAction<string>) => state
  }
})

export const { 
  LOGIN_USER,
  LOGIN_USER_RESULT,
  DISCONNECT,
  LOGIN_COACH,
  LOGIN_COACH_RESULT,
  SIGN_IN_USER,
  SIGN_IN_COACH,
  RELOG_USER,
  RELOG_USER_RESULT,
  RELOG_COACH,
  RELOG_COACH_RESULT,
  UNLOG_USER,
  SET_TOKEN
} = loginSlice.actions

export default loginSlice.reducer