import HoplaTheme from "../../theme";
import globalStyleClasses from "../../style";

const classes = {
  ...globalStyleClasses,
  coachs: {
    display: "flex",
    flexDirection: "column",
    position: 'relative',
    mb: 30,
    mr: 'calc(10%)',
    ml: 'calc(10%)',
    border: '2px solid #104f5c',
    "& h2": {
      fontSize: "48px",
      color: HoplaTheme.custom.White,
      textAlign: "center",
    },
    "& hr": {
      opacity: 1,
    },
  },
  coachBloc: {
    pt: 4,
    pb: 2,
    backgroundColor: "white",
    "& .MuiTypography-body1": {
      my: 1,
        fontSize: "22px",
      "& span": {
          },
    },
    "& .MuiTypography-body2": {
        fontSize: "22px",
    },
    "& .MuiTypography-h5": {
      my: 1,
    },
  },
  price: {
    mb: 2,
    fontSize: "48px !important",
    color: HoplaTheme.palette.primary.main,
  },
};
export default classes;
