import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import ReactPlayer from 'react-player'
import HoplaTheme from "../theme";
import { RootState } from "../store";
import { Speciality } from "../models/Speciality";
import { CoursInfos } from "../components/CoursInfos";
import { HorizontalText } from "../components/HorizontalText";
import Fond from '../images/Fond_Page_GymDouce.jpg';
import Pilates from '../images/pilates.jpeg';
import Yoga from '../images/yoga.jpeg';
import PrePostNatal from '../images/pre-post-natal.jpeg';
import GymDouce from '../images/gym-douce.jpeg';
import { useSelector } from "react-redux";

const bubbleStyle = {
  width: '14px',
  height: '14px',
  borderRadius: '50%',
  borderColor: 'white',
  border: '2px solid white',
  marginTop: '16px',
}

export const GymDoucePage: React.FC = () => {
  const url = `C:/Users/Mehdi/Documents/HopLa/HopLa.Web/hopLa/src/images/video_boxe.png`;
  const matchDownXs = useMediaQuery(HoplaTheme.breakpoints.down("md"));

  const specialities = useSelector((s: RootState) => s.coaches.specialities);

  const getSpe = (name: string): Speciality | undefined => {
    const f = specialities?.filter(s => s.name == name);
    if (f.length == 0) return undefined;
    return f[0];
  }

  const dots = [
    'Renforcement musculaire',
    'Amélioration du système cardiovasculaire',
    'Mouvements lents',
    'Respect des articulations',
    'Relaxation'
  ];

  const infos = [
    {
      title: `Gym Douce : Retrouvez l'équilibre et la détente avec Hoplà Coaching`,
      subTitle: `Vous cherchez une activité physique douce et bénéfique pour votre corps et votre esprit ? Hoplà Coaching vous propose des séances de gym douce adaptées à tous les niveaux, que vous soyez débutant ou expérimenté. Notre plateforme vous permet de réserver facilement des séances de coaching en gym douce en Île-de-France. Profitez d'un moment de bien-être et de détente grâce à nos coachs expérimentés qui sauront vous accompagner tout au long de votre parcours.`
    },
    {
      title: 'Les bienfaits de la Gym Douce',
      subTitle: `La gym douce est une pratique qui vise à renforcer vos muscles en douceur, améliorer votre souplesse, votre posture et votre équilibre. C'est une activité idéale pour tous ceux qui souhaitent préserver leur santé tout en évitant les impacts trop intenses sur le corps. Avec la gym douce, vous pourrez vous relaxer, réduire le stress et favoriser une meilleure circulation sanguine.`
    },
    {
      title: 'Découvrez notre offre de séances de Gym Douce',
      subTitle: `Chez Hoplà Coaching, nous vous proposons un large choix de séances de gym douce pour répondre à vos besoins spécifiques. Que vous cherchiez à soulager des tensions musculaires, à retrouver de la souplesse ou à améliorer votre bien-être général, nos coachs sauront vous guider avec des exercices adaptés. Nous offrons également des séances de gym douce pré et post natal pour accompagner les femmes pendant et après leur grossesse.`
    },
    {
      title: 'Rejoignez notre communauté et réservez vos séances dès maintenant',
      subTitle: <Typography variant="h5">
        Ne tardez plus à vous inscrire sur notre plateforme et à réserver vos séances de gym douce. Profitez des nombreux avantages de Hoplà Coaching, notamment :
      <ul>
        <li>Des coachs expérimentés et qualifiés : Nos professionnels sauront vous accompagner dans vos objectifs</li>
        <li>Une grande flexibilité horaire : Choisissez les séances qui correspondent à votre emploi du temps</li>
        <li>Des tarifs compétitifs : Profitez d'un excellent rapport qualité-prix pour vos séances de gym douce</li>
      </ul>
      </Typography>
    }
  ]

  const cours = [
    {
      title: 'Pilates',
      subTitle: 'Tout en profondeur',
      content: 
        <Typography color='black' variant='h5'>
          Travaillez votre posture grâce à une méthode de <Box display='inline' fontWeight='bold'>renforcement des muscles profonds</Box>.
          Votre coach vous aidera à prendre conscience de 
          l’importance de l’harmonie du corps et de l’esprit. Idéal pour <Box display='inline' fontWeight='bold'>diminuer vos douleurs musculaires (dos, cervicales…etc.).</Box>
        </Typography>,
        image: `url(${Pilates})`,
        speciality: getSpe('Pilates'),
        reversed: false
    },
    {
      title: 'Yoga',
      subTitle: 'Zen attitude',
      content: 
        <Typography color='black' variant='h5'>
          Besoin de calme, de détente, mais pas de vacances en perspective, un seul mot : <Box display='inline' fontWeight='bold'>YOGA Renforcez vos muscles</Box>
          avec des <Box display='inline' fontWeight='bold'>exercices physiques et apprenez à vous écouter avec des techniques respiratoires</Box>.
          Votre coach vous accompagnera et vous apprendra à
          vous recharger en énergie positive en prenant soin de votre corps et de votre esprit.
        </Typography>,
        image: `url(${Yoga})`,
        speciality: getSpe('Yoga'),
        reversed: true
    },
    {
      title: 'Gym douce',
      subTitle: 'De la boxe et bien plus !',
      content: 
        <Typography color='black' variant='h5'>
          <Box display='inline' fontWeight='bold'>La gym douce porte une attention particulière aux articulations et aux postures</Box>. Elle est adaptée à vos
          besoins ainsi qu’à vos pathologies. A travers des exercices de <Box display='inline' fontWeight='bold'>renforcement musculaire et cardiovasculaire,
          votre coach vous garantit un boost d’énergie</Box> ! Cette activité physique est <Box display='inline' fontWeight='bold'>adaptée aux séniors, en complément
          d’une rééducation</Box>, ainsi qu’aux enfants.
        </Typography>,
        image: `url(${GymDouce})`,
        speciality: getSpe('Gym douce'),
        reversed: false
    },
    {
      title: 'Fitness pré/post natal',
      subTitle: 'Pour vous et votre +1',
      content: 
        <Typography color='black' variant='h5'>
          <Box display='inline' fontWeight='bold'>Tout le long de votre grossesse et après votre accouchement</Box> il est recommandé de pratiquer une <Box display='inline' fontWeight='bold'>activité 
          sportive mais adaptée à votre état de santé.Le fitness pré et post natal</Box> vous permet de travailler en toute
          sécurité avec un coach sportif formé et spécialisé. <Box display='inline' fontWeight='bold'>Renforcement musculaire, travail postural,
          relaxation amélioreront votre quotidien.</Box>
        </Typography>,
        image: `url(${PrePostNatal})`,
        speciality: getSpe('Gym douce'),
        reversed: true
    }
  ];

  return (
    <Box>
      <Box sx={{
        background: `url(${Fond})`,
        backgroundRepeat: "no-repeat", backgroundSize:'cover',
        mt: 10, pt: 15, pb: 10
      }}>
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', mx: {xs: '10%', sm: '20%'}}}>
        <Box sx={{
          backgroundColor: '#104f5c'}}>
            <Grid container>
              { matchDownXs && <Grid item xs={12}>
                <Box display='flex' flexDirection='column' justifyContent='start' alignItems='start' mt={5} ml={8}>
                  <Typography variant='h2' color='white' fontWeight='bold' letterSpacing={12}>
                    GYM DOUCE
                  </Typography> 
                  </Box>
                </Grid>}
              <Grid item lg={5} md={12} sx={{
                background: url,
                backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
              }}>
                <Box m={8}>
                  <ReactPlayer
                    url='video_pilates.m4v'
                    width='100%'
                    playing
                    height='100%'
                    light={<img src='video_pilates.jpg' style={{width: '100%', height: '100%'}}/>}
                    />
                  </Box>
              </Grid>
              <Grid item lg={7} md={12}>
                <Box display='flex' flexDirection='column' justifyContent='start' alignItems='start' my={5} ml={5} mr={15}>
                  { !matchDownXs && <Typography variant='h2' color='white' fontWeight='bold' letterSpacing={12}>
                  GYM DOUCE
                  </Typography> }
                  <Typography variant='h3' color='white' sx={{
                    mt: {
                      md: 10,
                      xs: 0
                    }, mb: 2
                  }}>
                    BÉNÉFICES
                  </Typography>
                  <hr style={{border: '1px solid white', height: '3px', width: '100%', background: 'white'}}/>
                  <Grid container>
                   {dots.map(s => 
                   <>
                      <Grid item xs={2}>
                        <div style={bubbleStyle}/>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant='h5' fontWeight='bold' color='white'>
                        {s}
                        </Typography>
                      </Grid>
                    </>
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box sx={{background: '#1a1a1a', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Typography variant='h5' my={2} color='white' mx={5}>
          Pilates • Yoga • Gym douce • Fitness pré/post natal
          </Typography>
      </Box>

      {
        cours
             .map(c => 
              <CoursInfos 
                title={c.title} 
                subTitle={c.subTitle} 
                content={c.content} 
                speciality={c.speciality}
                reversed={c.reversed}
                image={c.image} />
              )
      }

      {
        infos.map((m, i) => (
          <HorizontalText
            title={m.title}
            content={m.subTitle}
            reversed={i % 2 == 1}
            />
        ))
      }

      <Box style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <Typography color='black' variant="h5" fontWeight='bold' sx={{width: '80%', pt: 10, pb: 10}} align='center'>
        Optez pour la gym douce avec Hoplà Coaching et retrouvez équilibre, détente et bien-être dans votre vie quotidienne. Notre plateforme est là pour vous aider à prendre soin de votre corps et à trouver la sérénité à travers une pratique douce et respectueuse de votre bien-être.
        </Typography>
      </Box>

      <Box sx={{backgroundColor: '#104f5c'}}>
        <Grid container sx={{pt: 10, pb: 10}}>
          <Grid item xs={12} sm={5}>
            <Box sx={{
              display: 'flex',
              justifyContent: {
                md: 'flex-end',
                xs: 'center'
              }
            }}>

            <Button variant='contained' size='large' 
                style={{
                  textTransform: 'none',
                  margin: '10px',
                  maxHeight: '50px', minWidth: '240px',
                  minHeight: '50px', borderRadius: 20, fontSize: '1.6em'}}>
                Réserver une séance
              </Button>
            </Box>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={12} sm={5}>
            <Box sx={{
              display: 'flex',
              justifyContent: {
                md: 'flex-start',
                xs: 'center'
              }
            }}>

            <Button variant='contained' size='large' 
                style={{
                  textTransform: 'none',
                  margin: '10px',
                  maxHeight: '50px', minWidth: '240px', 
                  minHeight: '50px', borderRadius: 20, fontSize: '1.6em'}}>
               Nous contacter
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
