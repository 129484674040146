import axios from 'axios';
import { HOPLA_URL } from '../config/config';

export const getClientImage = async (clientId: number) : Promise<string> => {
    return (await axios.get(HOPLA_URL + '/image/client/' + clientId)).data;
}

export const getCoachImage = async (clientId: number) : Promise<string> => {
    return (await axios.get(HOPLA_URL + '/image/coach/' + clientId)).data;
}

export const readFileDataAsBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event: any) => {
            resolve(event.target.result.substr(event.target.result.indexOf(',') + 1));
        };

        reader.onerror = (err) => {
            reject(err);
        };

        reader.readAsDataURL(file);
    });
}