import { useSelector } from "react-redux";
import { RootState } from "../store";

import { LoggedType } from "../slices/loginSlice";
import ClientMainPage from "./ClientMainPage";
import CoachMainPage from "./CoachMainPage";

const MainPage: React.FC = () => {
  const state = useSelector((s: RootState) => s);
  
  return (
    <>
     {
        state.login.loggedType !== LoggedType.Coach ? 
        (<ClientMainPage/>)
        :
        (<CoachMainPage/>)
      }
    </>
  );
};

export default MainPage;
