import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Dispo } from '../components/DispoPicker';
import { Location } from '../models/Location';
import { PurchasedPack } from '../models/Pack'
import { ProposedCoach } from '../models/ProposedCoach';
import { ShoppingCard } from '../models/ShoppingCard'

export interface ReservationState {
  card: ShoppingCard[],
  packs: PurchasedPack[],
  sessionPrice: number,
  packPrice: number,
  propositions: ProposedCoach[],
  dispos: Dispo[],
  specialityId: number,
  location: Location | undefined
}

const initialState: ReservationState = {
  card: [],
  packs: [],
  sessionPrice: 85,
  packPrice: 800,
  propositions: [],
  dispos: [],
  specialityId: -1,
  location: undefined
}

export const reservationSlice = createSlice({
  name: 'reservationSlice',
  initialState,
  reducers: {
    ADD_TO_CARD: (state: ReservationState, action: PayloadAction<ShoppingCard>) => {
      state.card.push(action.payload);
    },
    LOAD_CARD: (state: ReservationState) => state,
    LOAD_CARD_RESULT: (state: ReservationState, action: PayloadAction<{shop: ShoppingCard[], packs: PurchasedPack[]}>) =>{
      state.card = action.payload.shop;
      state.packs = action.payload.packs;
    },
    REMOVE_ITEM: (state: ReservationState, action: PayloadAction<ShoppingCard>) => {
      const found = state.card.findIndex(c => c.id == action.payload.id && c.addDate == action.payload.addDate);

      if (found !== -1) {
        const copy = [...state.card];
        copy.splice(found, 1);
        state.card = copy;
      }
    },
    LOAD_PRICES: (state: ReservationState) => state,
    SET_PRICES: (state: ReservationState, action: PayloadAction<{sessionPrice: number, packPrice: number}>) => {
      state.sessionPrice = action.payload.sessionPrice;
      state.packPrice = action.payload.packPrice;
    },
    RESET_RESERVATIONS: (state: ReservationState) => {
      state.propositions = [];
      state.dispos = [];
      state.specialityId = -1;
      state.location = undefined;
    },
    SET_RESERVATIONS: (state: ReservationState, action: PayloadAction<ProposedCoach[]>) => {
      state.propositions = action.payload;
    },
    SET_DISPOS: (state: ReservationState, action: PayloadAction<Dispo[]>) => {
      state.dispos = action.payload;
    },
    SET_SPECIALITY: (state: ReservationState, action: PayloadAction<number>) => {
      state.specialityId = action.payload;
    },
    SET_LOCATION: (state: ReservationState, action: PayloadAction<Location | undefined>) => {
      state.location = action.payload;
    }
  }
})

export const { 
  ADD_TO_CARD,
  LOAD_CARD,
  LOAD_CARD_RESULT,
  REMOVE_ITEM,
  LOAD_PRICES,
  SET_PRICES,
  SET_RESERVATIONS,
  RESET_RESERVATIONS,
  SET_DISPOS,
  SET_SPECIALITY,
  SET_LOCATION
} = reservationSlice.actions

export default reservationSlice.reducer