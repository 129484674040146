import HoplaTheme from "../../../theme";
import globalStyleClasses from "../../../style";

const classes = {
  ...globalStyleClasses,
  comment: {
    position: "relative",
    p: 2,
    "& img": {
      position: "absolute",
      bottom: 0,
      right: 0,
      zIndex: 999,
    },
    "& img:first-of-type": {
      position: "absolute",
      top: 0,
      left: 0,
    },
    "&::before, ::after": {
      backgroundColor: "transparent",
      content: "''",
      display: "block",
      height: "25px",
      position: "absolute",
      width: "25px",
    },
    "&::after": {
      bottom: "-5px",
      right: "-3px",
    },
    "&::before": {
      top: "-5px",
      left: "-3px",
    },
  }
};
export default classes;
