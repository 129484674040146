import axios from "axios";
import { HOPLA_URL } from "../config/config";
import { PaiementResult, PayCommand, ReservationResult, ShoppingPrice } from "../models/Pay";
import { ShoppingCard } from "../models/ShoppingCard";

export const payShop = async (command: PayCommand) : Promise<ReservationResult> => {
    return (await axios.post(HOPLA_URL + '/pay/payShop', command)).data;
}

export const getPayResult = async (referenceId: string) : Promise<PaiementResult> => {
    return (await axios.get(HOPLA_URL + '/pay/getResult/' + referenceId)).data;
}

export const getShopPrice = async (shop: ShoppingCard[], reductionCode: string, withAI: boolean) : Promise<ShoppingPrice> => {
    return (await axios.post(HOPLA_URL + '/pay/getPrice', {shop, reductionCode, withAI})).data;
}