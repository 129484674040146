import React, { FC, useEffect, useRef, useState } from "react";

import Box from "@mui/material/Box";
import {
  Badge,
  Button,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { SpecialitySelect } from "./SpecialitySelect";
import { PlaceAutocomplete } from "./PlaceAutocomplete";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CircularProgress from '@mui/material/CircularProgress';
import CancelRounded from "@mui/icons-material/CancelRounded";
import { CustomDispoPicker, Dispo } from "./shared/customDispoPicker";
import { getSimpleHour, mergeByDay } from "../utils/dateUtils";
import classes from "../style";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { LoggedType } from "../slices/loginSlice";
import { Location } from "../models/Location";
import { getClientPrivate } from "../services/clientService";
import Accueil from "../images/ACCUEIL.jpeg";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import Fond from "../images/FOND_seance.jpg";
import AddressIcon from "../images/svg/icon_address.svg";
import CalendarIcon from "../images/svg/icon_calendar.svg";
import CoursIcon from "../images/svg/icon_cours.svg";
import { useHistory, useLocation } from "react-router";
import HoplaTheme from "../theme";

interface RechercheSeanceProps {
  loading: boolean;
  dispos: Dispo[];
  userChoose: boolean;
  specialityId: number;
  location: Location | undefined;
  handleSubmit: () => void;
  setSpeciality: (e: any) => void;
  setLocation: (e: any) => void;
  setDispos: (e: any) => void;
  setUserChoose: (e: any) => void;
}

const iconStyle = {
  marginRight: '10px',
  height: '25px'
}

const RechercheSeance: FC<RechercheSeanceProps> = ({
  loading,
  dispos,
  userChoose,
  specialityId,
  location,
  handleSubmit,
  setSpeciality,
  setLocation,
  setDispos,
  setUserChoose
}: RechercheSeanceProps) => {
  const state = useSelector((s: RootState) => s);
  const uri = useLocation().pathname;
  const history = useHistory();

  const [screenW, setScreenW] = useState(window.innerWidth);

  const matchDownMd = useMediaQuery(HoplaTheme.breakpoints.down("md"));
  const matchUpLg = screenW > 1460;

  const [uriState, setUriState] = useState('');

  const scrollToReservation = () =>
    document.querySelector('#reservation')?.scrollIntoView({behavior: 'smooth'});

  const [clientLocation, setClientLocation] = useState<Location | undefined>(
    undefined
  );

  const containsDispo = (d: Dispo) => {
    return (
      dispos.filter(
        (dispo) =>
          dispo.index === d.index &&
          dispo.start.toISOString() === d.start.toISOString()
      ).length > 0
    );
  };

  const [triggerUpdate, setUpdate] = useState(0);

  const update = () => {
    setUpdate((triggerUpdate + 1) % 1000);
  };

  const setNewsDispos = (d: Dispo[]) => {
    const news = [...d].filter((k) => !containsDispo(k));
    const previous = [...dispos];

    for (let i in news) {
      previous.push(news[i]);
    }

    setDispos(previous);
  };

  const removeDispo = (dispo: Dispo) => {
    setDispos([
      ...dispos.filter(
        (d) =>
          dispo.start.getFullYear() !== d.start.getFullYear() ||
          dispo.start.getMonth() !== d.start.getMonth() ||
          dispo.start.getDate() !== d.start.getDate() ||
          dispo.start.getTime() > d.start.getTime() ||
          dispo.end.getTime() < d.end.getTime()
      ),
    ]);

    update();
  };

  const merged: { date: string; dispos: Dispo[] }[] = [];
  const m = mergeByDay(dispos);

  for (var key in m) {
    merged.push({ date: key, dispos: m[key] });
  }

  useEffect(() => {
    window.addEventListener('resize', () => {
       setScreenW(window.innerWidth);
    })
  }, []);

  useEffect(() => {
    const getPrivate = async () => {
      const p = await getClientPrivate(state.login.client?.id ?? 0);
      setClientLocation(p?.address);
    };

    if (state.login.loggedType === LoggedType.Client) {
      getPrivate();
    }
  }, [state.login.client?.id]);

  useEffect(() => {
    if (uriState !== uri) {
      setUriState(uri);

      if (uri.indexOf('reservation') >= 0) {
        scrollToReservation();
      }
    }
  }, [uriState]);
   
  const paddingRef = useRef<any>(null);

  let paddingHeight = 0;

  if (screenW < 400) {
    paddingHeight = 500;
  } else if (screenW < 549) {
    paddingHeight = 400;
  } else if (screenW < 900) {
    paddingHeight = 400;
  } else if (screenW < 1000) {
    paddingHeight = 200;
  } else if (screenW < 1460) {
    paddingHeight = 200;
  } 

  return (
    <>
    {
      matchUpLg ?
      <Box sx={{
        flexDirection: 'row'
      }}>
        <img src={Accueil} style={{
          height: '100%', 
          width: '100%',
          backgroundRepeat: "no-repeat", backgroundSize:'cover'}}/>
        <Container 
          sx={{
            display: 'flex', verticalAlign:'center', alignItems: 'center', padding: '0 !important',
            justifyContent:'flex-center', width: '100%', maxWidth: '100% !important',
            position: 'absolute',
            top: 'calc(43%)', zIndex: 999}}>
          <Grid container 
            style={{
              display: 'flex', backgroundColor: '#000B', height: 'auto', alignItems: 'center', 
              padding: '3% 0%', alignContent: 'center', justifyContent:'center', width: '100%'}}>
            <Grid item xs={12} md={6} alignContent='center' my={2} ml={2}>
              <Box sx={{ml: '20%'}}>
                <Typography style={{fontWeight: 'bold', fontSize:'3.4em'}} display='inline' color='white'>
                  Récupérer 42,50€ 
                </Typography>
                <Typography style={{fontSize:'3.3em', padding: '0px 8px'}} display='inline' color='white'>
                  sur ma séance à 
                </Typography>
                <Typography style={{fontSize:'3.4em', padding: '0px 8px'}} display='inline' color='white'>
                  85€ 
                </Typography>
                <Typography style={{fontSize:'3.3em', padding: '0px 8px'}} display='inline' color='white'>
                  en
                </Typography>
                <Typography style={{fontWeight: 'bold', fontSize:'3.4em'}} display='inline' color='white'>
                7 minutes !
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={2} 
              display='flex' 
              flex={matchDownMd ? 'center' : 'start'} 
              justifyContent={matchDownMd ? 'center' : 'start'}
              justifyItems='start' mb={2}>
              <Button variant='contained' size='large' 
                style={{
                  borderRadius: 20, textTransform: 'none', fontSize: '2em'}}
                  onClick={() => history.push('/acces-membre/client')}>
                C'est par ici !
              </Button>
            </Grid>
            <Grid item xs={12} md={3}
              display='flex' 
              flex={matchDownMd ? 'center' : 'start'} 
              justifyContent={matchDownMd ? 'center' : 'start'}
              justifyItems='start'>
              <Box sx={{ border: 3, borderColor: 'white', maxWidth: '100%', width: '80%'}}>
                <Grid container style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <Grid item  xs={12}>
                    <Typography color='white' style={{fontWeight: 'bold', fontSize:'6.5em', justifyItems: 'center'}}>
                      -50%
                    </Typography>
                  </Grid>
                  <Grid item  xs={12} style={{alignItems: 'center', alignContent: 'center'}}>
                    <Typography color='white' mb={2} style={{fontWeight: 'bold', fontSize:'2.2em', justifyItems: 'center'}}>
                      sur votre séance
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Container>
      </Box>
      :
      <Box sx={{
        flexDirection: 'row'
      }}>
        <img src={Accueil} style={{
          height: '100%', 
          width: '100%',
          backgroundRepeat: "no-repeat", backgroundSize:'cover'}}/>
        <Container 
          sx={{
            display: 'flex', verticalAlign:'center', alignItems: 'center', padding: '0 !important',
            justifyContent:'flex-center', width: '100%', maxWidth: '100% !important',
            position: 'absolute', zIndex: 999}}>
          <Grid container 
            style={{
              display: 'flex', height: 'auto', alignItems: 'center', 
              padding: '3% 0%', alignContent: 'center', justifyContent:'center', width: '100%'}}>
            <Grid item xs={12} md={6} alignContent='center' my={2} ml={2}>
              <Box sx={{ml: {
                lg: '20%',
                md: '15%',
                sm: '10%',
                xs: '5%'
              }}}>
                <Typography style={{fontWeight: 'bold', fontSize:'2em'}} display='inline' color='#000B'>
                  Récupérer 42,50€ sur ma séance à 85€ en 7 minutes !
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={2} 
              display='flex' 
              flex={matchDownMd ? 'center' : 'start'} 
              justifyContent={matchDownMd ? 'center' : 'start'}
              justifyItems='start' mb={2}>
              <Button variant='contained' size='large' 
                style={{
                  borderRadius: 20, textTransform: 'none', fontSize: '2em'}}
                  onClick={() => history.push('/acces-membre/client')}>
                C'est par ici !
              </Button>
            </Grid>
            <Grid item xs={12} md={3}
              display='flex' 
              flex={matchDownMd ? 'center' : 'start'} 
              justifyContent={matchDownMd ? 'center' : 'start'}
              justifyItems='start'>
              <Box sx={{ border: 3, borderColor: '#000B', maxWidth: '100%', width: '80%'}}>
                <Grid container style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <Grid item  xs={12}>
                    <Typography color='#000B' style={{fontWeight: 'bold', fontSize:'3.5em', justifyItems: 'center'}}>
                      -50%
                    </Typography>
                  </Grid>
                  <Grid item  xs={12} style={{alignItems: 'center', alignContent: 'center'}}>
                    <Typography color='#000B' mb={2} style={{fontWeight: 'bold', fontSize:'2em', justifyItems: 'center'}}>
                      sur votre séance
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Container>
      </Box>
    }
    <Box ref={paddingRef} sx={{
        backgroundColor: 'white',
        height: paddingHeight
      }}/>
    <Box id='reservation' style={{
      backgroundImage: `url(${Fond})`,
      width: '100%', height: '780px',
      backgroundRepeat: "no-repeat", backgroundSize:'cover', 
      paddingBottom: '50px', 
      fontWeight: 'bold'
      }}>
      <Box style={{display: 'flex', justifyContent: 'center', justifyItems: 'center', alignContent: 'center', alignItems: 'center'}}>
        <Typography style={{padding: '60px'}} variant='h3' fontWeight='bold' color='#ff6100'>
          Je réserve ma séance
        </Typography>
      </Box>
      <Container maxWidth="xl" style={{background: 'red', backgroundColor:'#104f5c', padding: '10px 20px 20px 20px'}}>
        <Grid container spacing={2} sx={{ maxWidth: "100%", m: "auto", backgroundColor: '#104f5c' }}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography color='white' variant='h5' style={{padding: '8px 0px', display: 'flex'}}>
              <img src={CoursIcon} style={iconStyle} />
              Je cherche un cours de
            </Typography>
            <SpecialitySelect onChange={setSpeciality} specialityId={specialityId} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography color='white' variant='h5' style={{padding: '8px 0px', display: 'flex'}}>
            <img src={AddressIcon} style={iconStyle} />
              J'habite au
            </Typography>
            <PlaceAutocomplete
              onChange={setLocation}
              initValue={clientLocation ?? location}
              disabled={state.login.loggedType === LoggedType.Client}
              noOptionsMessage="J'habite au ..."
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography color='white' variant='h5' style={{padding: '8px 0px', display: 'flex'}}>
            <img src={CalendarIcon} style={iconStyle} />
              Je suis disponible le
            </Typography>
            {merged.map((e, _i) => (
              <>
                <Typography color='white' className="dispoDate">{e.date}</Typography>
                <Grid container className="dispoHours">
                  {e.dispos.map((d, j) => (
                    <Grid xs={3}>
                      <Badge
                        badgeContent={
                          <CancelRounded sx={{color: 'white'}} onClick={() => removeDispo(d)} />
                        }
                      >
                        <Button
                          key={j}
                          variant={j === 0 ? "contained" : "outlined"}
                          sx={{
                            borderRadius: "50px",
                            backgroundColor: j === 0 ? 'white' : 'transparent',
                            width: '100%',
                            textTransform: 'none', 
                            fontSize: 18, fontWeight: 'bold', mb: 1,
                            color: j === 0 ? '#104f5c' : 'white', borderColor: j === 0 ? 'transparent' : 'white'}}
                        >
                          {getSimpleHour(d.start)}
                        </Button>
                      </Badge>
                    </Grid>
                  ))}
                </Grid>
              </>
            ))}

            <CustomDispoPicker
              onChange={setNewsDispos}
              needUpdate={triggerUpdate}
              getDispos={() => dispos}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{alignContent: 'center'}}>
            <Button
              variant="contained"
              sx={{...classes.submitBtn, textTransform: 'none'}}
              endIcon={loading ? <CircularProgress /> : <SearchOutlinedIcon />}
              onClick={handleSubmit}
            >
              Rechercher
            </Button>
            {/* <FormGroup>
              <FormControlLabel
                sx={{marginTop: '5px'}}
                checked={userChoose}
                onChange={(e: any) => setUserChoose(e.target.checked)}
                control={<Switch defaultChecked />}
                label={<Typography color='white'>Je souhaite choisir moi-même mon coach</Typography>}
              />
            </FormGroup> */}
          </Grid>
        </Grid>
      </Container>
    </Box>
    </>
  );
};

export default RechercheSeance;
