import { Box, Button, IconButton, Skeleton, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { getClientImage, getCoachImage, readFileDataAsBase64 } from "../services/imageService";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import StarIcon from "@mui/icons-material/Star";
import classes from "../style";
import { canViewClient, setCoachFavorite } from "../services/clientService";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { LoggedType } from "../slices/loginSlice";
import { useHistory } from "react-router";
import UserCircle from '../images/svg/Cercle_photo.svg';

export interface ImageProps {
  onImageChanged?: (image: string) => void;
  editable?: boolean;
  favoris?: boolean;
  small?: boolean;
  responsive?: boolean;
  userId: number;
  isClient: boolean;
  circle?: boolean;
}

export const Image: React.FC<ImageProps> = (props) => {
  const [image, setImage] = useState<string>("");
  const [init, setInit] = useState(false);
  const [fav, setFavoris] = useState(props.favoris ?? false);
  const history = useHistory();
  const fileUpload = useRef<HTMLInputElement | null>(null);

  const loggedType = useSelector((s: RootState) => s.login.loggedType);

  const onImagesChange = async (e: any) => {
    const img = ("data:image/jpeg;base64," +
      (await readFileDataAsBase64(e.target.files[0]))) as string;
      setImage(img);
    if (props.onImageChanged) {
      props.onImageChanged(img);
    }
  };

  const changeFavoris = async() => {
    if (!props.isClient) {
      setFavoris(await setCoachFavorite(props.userId, !fav));
    }
  }

  const clickOnImage = async () => {
    if (loggedType === LoggedType.Coach && props.isClient) {
      if (await canViewClient(props.userId)) {
        history.push('/client?id=' + props.userId);
      }
    } else if (loggedType !== LoggedType.Coach && !props.isClient) {
      history.push('/coach?id=' + props.userId);
    }
  }

  useEffect(() => {
    const load = async () => {
      const placeholderImg = "/default-avatar-profile.jpeg";
      const clientImg = props.isClient ? await getClientImage(props.userId) : await getCoachImage(props.userId);
      if (clientImg) {
        setImage(clientImg);
      } else {
        setImage(placeholderImg);
      }
    };

    if (!init) {
      load().catch(console.error);
      setInit(true);
    }
  }, [init]);

  const onClick = async () => {
    if (props.editable) {
      fileUpload!.current!.click()
    } else {
      await clickOnImage();
    }
  }

  const tooltipTitle = props.editable || props.onImageChanged == null ? '' : 
    `Cliquez sur 'Modifier' pour changer votre image`;

  return (
    <Box sx={[classes.image, props.responsive ? classes.responsiveImg : {}, props.small ? classes.small : {}]}
        onClick={() => onClick()}>
      {props.favoris !== undefined && loggedType === LoggedType.Client && (
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={() => changeFavoris()}
          sx={[classes.favoris]}
          color="primary"
        >
          {fav ? <StarIcon /> : <StarOutlineOutlinedIcon />}
        </IconButton>
      )}
      {image ? (
        <Tooltip title={tooltipTitle}>
          <Button
            variant="contained"
            component="label"
            onClick={() => clickOnImage()}
            sx={{
              width: "100%",
              height: "100%",
              padding: 0,
              borderRadius: "100%",
              backgroundImage: `url(${image})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          >
            {props.editable && (
              <input
                hidden
                id="imagePicker"
                accept="image/*"
                ref={fileUpload}
                type="file"
                onChange={onImagesChange}
                name="Image"
              />
            )}
          </Button>
        </Tooltip>
      ) : (
        <Skeleton variant="circular" sx={{ height: "100%", width: "100%" }} />
      )}
      {props.circle && <img src={UserCircle} 
        style={{
          position: 'absolute',
          left: 'calc(-4%)', top: 'calc(-4%)',
          height: '100%',
          zIndex: 999
        }}/>}
    </Box>
  );
};
