import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { FC, ReactNode } from "react";
import HoplaTheme from "../theme";

export interface HorizontalTextProps {
    title: string;
    content: string | ReactNode;
    reversed?: boolean;
}

const title = {
  color: '#ff6b2b',
  fontWeight: 'bold'
}

const text = {
  color: 'black'
}

const box = {
  justifyContent: 'center',
  alignItems: 'center',
  padding: '30px 0px'
}

const hrContainer = {
  display :'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const hrStyle = {
  width: '90%',
  marginLeft: '10%',
  height: '4px',
  color: 'black',
  backgroundColor: 'black'
}

export const HorizontalText: FC<HorizontalTextProps> = (props) => {
    const matchDownMd = useMediaQuery(HoplaTheme.breakpoints.down("sm"));

    return props.reversed ?? false ? (
        <Box>
        <Grid container>
          <Grid item xs={12} sm={7}>
            <Box sx={{...box, margin: {
                sm: '0% 5% 0% 20%',
                xs: '0% 10%'
                }}}>
                <Typography style={title} variant="h4">
                  {props.title}
                </Typography>
                {
                  typeof props.content === 'string' ?
                  (
                  <Typography style={text} variant="h5">
                    { props.content }
                  </Typography>
                  )
                  : props.content
                }
            </Box>
          </Grid>

          { !matchDownMd && <Grid item xs={5} sx={hrContainer}>
            <hr style={hrStyle}/>
          </Grid> }
          </Grid>
        </Box>
    ) : 
    (<Box>
        <Grid container>
        { !matchDownMd && <Grid item xs={5} sx={hrContainer}>
            <hr style={hrStyle}/>
          </Grid> }
            
          <Grid item xs={12} sm={7}>
          <Box sx={{...box, margin: {
              sm: '0% 20% 0% 5%',
              xs: '0% 10%'
            }}}>
            <Typography style={title} variant="h4">
                {props.title}
                </Typography>
                {
                  typeof props.content === 'string' ?
                  (
                  <Typography style={text} variant="h5">
                    { props.content }
                  </Typography>
                  )
                  : props.content
                }
              </Box>
          </Grid>
        </Grid>
    </Box>
    );
}