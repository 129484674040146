import {
  Box,
  Container,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { PlanningSlotViewer } from "../components/PlanningSlotViewer";
import { PlanningSlot, PlanningState } from "../models/Planning";
import { getWeekSlotsHistory } from "../services/planningService";
import classes from "../style";

export const CoachResume: React.FC<{
  needUpdate: number;
  triggerUpdate: () => void;
}> = (props) => {
  const [slots, setSlots] = useState<PlanningSlot[]>([]);

  const slotUpdated = (p: PlanningSlot) => {
    props.triggerUpdate();
  };

  useEffect(() => {
    const get = async () => {
      try {
        const slots = await getWeekSlotsHistory();
        setSlots(slots);
      } catch (e) {
        console.error(e);
      }
    };

    get();
  }, [props.needUpdate]);

  return (
    <Box sx={classes.profileBloc}>
      <Container maxWidth="xl">
          <Typography variant="h4" color='#f36b29' fontWeight='bold'>En attente de validation</Typography>
        <Box sx={{
            my: 5, p: 5, mb: 10
          }}>
          {slots
            .filter((s) => s.state == PlanningState.Reserved)
            .map((s, i) => (
              <PlanningSlotViewer
                key={i}
                slot={s}
                updated={slotUpdated}
                onClose={() => {}}
              />
            ))}
        </Box>
          <Typography variant="h4" color='#f36b29' fontWeight='bold'>À venir</Typography>
        <Box sx={{
            my: 5, p: 5, mb: 10
          }}>
          {slots
            .filter((s) => s.state === PlanningState.Validated && new Date(s.date).getTime() > new Date().getTime())
            .map((s, i) => (
              <PlanningSlotViewer
                key={i}
                slot={s}
                updated={slotUpdated}
                onClose={() => {}}
              />
            ))}
        </Box>
          <Typography variant="h4" color='#f36b29' fontWeight='bold'>Passées</Typography>
        <Box sx={{
            my: 5, p: 5, mb: 10
          }}>
          {slots
            .filter((s) => (s.state === PlanningState.Validated || s.state === PlanningState.Done) && new Date(s.date).getTime() < new Date().getTime())
            .map((s, i) => (
              <PlanningSlotViewer
                key={i}
                slot={s}
                updated={(e) => {}}
                onClose={() => {}}
              />
            ))}
        </Box>
      </Container>
    </Box>
  );
};

export default CoachResume;
