import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Dictionary } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import KeyIcon from "@mui/icons-material/Key";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Image } from "../components/Image";
import { PlaceAutocomplete } from "../components/PlaceAutocomplete";
import { CoachFiles, CoachView, FileResultType } from "../models/Coach";
import { Location } from "../models/Location";
import { Speciality } from "../models/Speciality";
import {
  addCoachFile,
  addComment,
  canAddComment,
  getBody,
  getCoach,
  getCoachFiles,
  removeCoachFile,
  renameCoachFile,
  updateCoach,
} from "../services/coachService";
import { readFileDataAsBase64 } from "../services/imageService";
import {
  addCoachLocation,
  deleteCoachLocation,
  getCoachLocation,
} from "../services/locationService";
import { RootState } from "../store";
import classes from "../style";
import { showMessage, showModal } from "../services/popupService";
import { PasswordForm } from "../components/PasswordForm";
import { DerniersAvis } from "../components/DerniersAvis";
import { Rate } from "../components/shared/rate";
import { SpecialitySelect } from "../components/SpecialitySelect";
import { LocationChip } from "../components/LocationChip";
import { LoggedType } from "../slices/loginSlice";
import { validateEmail, validatePhone } from "../utils/manyUtils";
import { UpdateUserResult } from "../models/Client";

const customPlaceStyle = {
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 99999,
    minWidth: "100px",
    "&:after": {
      position: "absolute",
      zIndex: 9999,
      content: '""',
      left: "20px",
      top: "0px",
      width: 0,
      height: 0,
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: "16px solid #000",
    },
  }),
  control: (provided: any) => ({
    ...provided,
    backgroundColor: "transparent",
    height: "37px",
    borderRadius: 0,
    borderTop: 0,
    borderRight: 0,
    borderLeft: 0,
    borderColor: "#000",
    boxShadow: "none",
    minHeight: "29px",
    "&:hover": {
      borderColor: "#000",
    },
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    fontSize: "14px",
    padding: 0,
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    backgroundColor: "transparent",
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "#000 !important",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected || state.isFocused ? "#8C6AD4" : "#000",
    background: "none",
    "&:hover": {
        color: "#8C6AD4",
      background: "none",
    },
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    border: "2px solid #000",
    marginTop: "15px",
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: "#000",
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontSize: "18px",
    fontStyle: "italic",
    color: "#000",
    opacity: 0.7,
  }),
};

export const CoachPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [priv, setPriv] = useState(false);
  const [coachId, setCoachId] = useState(0);

  const [coach, setCoach] = useState<CoachView>();

  const history = useHistory();
  const dispatch = useDispatch();

  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [password, setPassword] = useState<string>();
  const [oldPassword, setOldPassword] = useState<string>();
  const [confirm, setConfirm] = useState<string>();
  const [location, setLocation] = useState<Location>();
  const [image, setImage] = useState<string>("");
  const [files, setFiles] = useState<CoachFiles[]>();

  const [specialities, setSpecialites] = useState<Speciality[]>([]);

  const [fileName, setFileName] = useState<string>();
  const [originalFileName, setOriginamFileName] = useState<string>();
  const [file, setFile] = useState<any>();
  const [renames, setRenames] = useState<Dictionary<string>>({});

  const [saving, setSaving] = useState(false);

  const [coachLocations, setCoachLocations] = useState<Location[]>([]);
  const [editable, setEditable] = useState(false);

  const [canClientAddComment, setCanAddComment] = useState(false);
  const [clientComment, setClientComment] = useState("");
  const [clientNote, setClientNote] = useState(0);

  const state = useSelector((s: RootState) => s);

  const setSpes = (e: Speciality[]) => {
    setSpecialites(e);
  };

  const loggedClientId = state.login.client?.id ?? 0;

  if (coachId === 0) {
    const query = new URLSearchParams(window.location.search);
    const queryId = query.get("id") ?? "";
    const cId = parseInt(queryId);

    if (cId === undefined || Number.isNaN(cId) || cId <= 0) {
      history.push("");
    } else {
      setPriv(
        state.login.loggedType == LoggedType.Coach
          ? state.login.coach?.id === cId
          : false
      );
      setCoachId(cId);
    }
  }

  const leave = () => {
    history.push("/");
  };

  const savePrivate = async () => {
    if (saving) return;

    if (!validateEmail(email)) {
      showMessage("Veuillez saisir une adresse email valide.");
      return;
    }

    if (!validatePhone(phone)) {
      showMessage("Veuillez saisir un numéro de téléphone valide.");
      return;
    }

    setSaving(true);

    try {
      const result = await updateCoach(
        email,
        image,
        location ?? null,
        phone ?? null,
        specialities
      );

      if (result === UpdateUserResult.OK) {
        showMessage("Informations sauvegardées !");
      } else {
        showMessage("Une erreur est survenue.");
      }
    } catch (e) {
      showMessage("Une erreur est survenue.");
    }

    setSaving(false);
  };

  const onPickedFiles = async (e: any) => {
    setOriginamFileName(e.target.files[0].name);
    setFileName(e.target.files[0].name);
    const file = (await readFileDataAsBase64(e.target.files[0])) as string;
    setFile(file);
  };

  const openPicker = () => {
    document.getElementById("filePicker")?.click();
  };

  const saveFile = async () => {
    if (file && (fileName?.length ?? 0) > 3) {
      var fileExt = (originalFileName ?? "").split(".").pop() ?? "";

      try {
        const res = await addCoachFile(fileName ?? "", file, fileExt);

        if (res.result == FileResultType.OK) {
          setFiles([...(files ?? []), res.file]);
        } else {
          console.error(res);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const renameFile = async (file: CoachFiles) => {
    if (!renames[file.name]) {
      console.error("Cannot rename " + file.name);
      return;
    }

    try {
      const res = await renameCoachFile(file.name, renames[file.name] ?? "");

      if (res == FileResultType.OK) {
        file.name = renames[file.name] ?? "";
        setFiles([...(files ?? []).filter((k) => k.name != file.name), file]);
        const dic = renames;
        delete dic[file.name];
        setRenames(dic);
      } else {
        console.error(res);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const deleteFile = async (file: CoachFiles) => {
    try {
      const res = await removeCoachFile(file.name);

      if (res == FileResultType.OK) {
        setFiles([...(files ?? []).filter((k) => k.name != file.name)]);
      } else {
        console.error(res);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const openFile = async (file: CoachFiles) => {
    try {
      const r = await getBody(file.name);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(r);
      link.setAttribute("download", file.name + "." + file.extension);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (e) {
      console.error(e);
    }
  };

  const setRenamedFile = (name: string, file: CoachFiles) => {
    const dic = renames;
    dic[file.name] = name;
    setRenames(dic);
  };

  const addLocation = async (location: Location) => {
    try {
      const r = await addCoachLocation(coachId, location);

      if (r) {
        setCoachLocations([...coachLocations, r]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const deleteLocation = async (location: Location) => {
    try {
      const r = await deleteCoachLocation(location.placeId);

      if (r) {
        setCoachLocations([
          ...coachLocations.filter((c) => c.placeId != location.placeId),
        ]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const sendComment = async () => {
    if (saving) return;

    if (clientComment.length === 0) {
      showMessage("Veuillez entrer un commentaire.");
      return;
    }

    setSaving(true);

    try {
      const r = await addComment(coachId, clientNote, clientComment);

      if (r) {
        setCanAddComment(false);
      }
    } catch {
      showMessage("Une erreur est survenue.");
    }

    setSaving(false);
  };

  const infosLabel = priv ? "Mes informations" : "Informations";
  const avisTitle = priv ? "Vos derniers avis" : "Derniers avis";

  useEffect(() => {
    const loadcoach = async () => {
      try {
        const r = await getCoach(coachId);

        if (r != undefined) {
          setCoach(r);
          setPhone(r.coach.phoneNumber);
          setEmail(r.email);
          setSpecialites(r.coach.specialities);
          setLocation(r.address);

          if (!priv) {
            setCanAddComment(await canAddComment(coachId));
          }

          if ((state.login.coach?.id ?? 0) == coachId) {
            const files = await getCoachFiles(coachId);
            setFiles(files);
            setCoachLocations(await getCoachLocation(coachId));
          }
        } else {
          leave();
        }
      } catch (e) {
        // message d'erreur
        leave();
        throw e;
      }
    };

    if (coachId > 0 && !Number.isNaN(coachId)) {
      loadcoach();
    }
  }, [coachId, loggedClientId]);

  return (
    <>
      <Box sx={{...classes.profileBloc, mx: {
        lg: 15,
        xs: 5 
      }}}>
        {coach !== undefined ? (
          <Box sx={{
            paddingTop: {
              sm: '50px',
              xs: '0xp'}, margin: '0px 0px 0px 0px'}}>
            {/* <ClientTuto/> */}
            
            <Typography variant="h3" color='#f36b29' fontWeight='bold' ml={5}>{infosLabel}</Typography>
            <Box sx={{
              backgroundColor: '#a2c2c2', my: 5, p: 5, mb: 10
            }}>
            {priv && coach.coach.state === false ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  marginTop: "20px",
                }}
              >
                <Box
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "red",
                    width: "400px",
                  }}
                >
                  <div style={{ margin: "10px" }}>
                    <Typography color="white">
                      Votre compte est en attente de validation ! Veuillez contacter
                      contact@hoplacoaching.fr pour plus d'informations.
                    </Typography>
                    <p />
                  </div>
                </Box>
              </div>
            ) : (
              ""
            )}
              <Box display="flex" alignItems="center" py={2}>
                <Grid container py={3}>
                  <Grid item xs={12} md={3}>
                    <Image
                      userId={coach.coach.id}
                      isClient={false}
                      onImageChanged={setImage}
                      editable={editable} circle
                    />
                    <Typography variant="h5" fontWeight='bold' sx={{textAlign: 'center !important'}}>
                      {coach.coach.firstname} {coach.coach.lastname}
                      <Rate rate={coach.coach.note} />
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{
                      pr: { md: 2 },
                      textAlign: { xs: "start", md: "start" },
                    }}
                  >
                    <Box my={2}>
                      <Typography variant="h5" fontWeight='bold'>
                        Spécialité
                      </Typography>
                      {editable ? (
                        <>
                          <SpecialitySelect
                            multiple
                            defaultMultiple={specialities}
                            onMultipleChange={(e) => setSpes(e)}
                          />
                          <Box sx={classes.indication}>
                            (*) Les spécialités Boxe anglaise et Boxe pied poing nécessitent un diplôme.
                          </Box>
                        </>
                      ) : (
                        <Typography  variant="h5">
                          {specialities.map((s: any) => s.name).join(", ")}
                        </Typography>
                      )}
                    </Box>
                    <Box my={2}>
                      <Typography  variant="h5" fontWeight='bold'>
                        Nombre de séances effectuées
                      </Typography>
                      <Typography  variant="h5">
                        {coach?.sessionsDone ?? 0}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    sx={{pl: { md: 2 }, textAlign: { xs: "center", md: "start" }}}>
                    {((phone && email) || editable) ? (
                      <>
                        <Box my={2}>
                          <Typography variant="h5" fontWeight='bold'>
                            Téléphone
                          </Typography>
                          {editable ? (
                            <TextField
                              hiddenLabel
                              id="phone"
                              placeholder="+33..."
                              defaultValue={phone}
                              variant="filled"
                              size="small"
                              sx={classes.input}
                              onChange={(e) => setPhone(e.target.value!)}
                            />
                          ) : (
                            <Typography  variant="h5">{phone}</Typography>
                          )}
                        </Box>

                        <Box my={2}>
                          <Typography variant="h5" fontWeight='bold'>
                            Adresse mail
                          </Typography>
                          {editable ? (
                            <TextField
                              hiddenLabel
                              id="email"
                              defaultValue={email}
                              variant="filled"
                              size="small"
                              sx={classes.input}
                              onChange={(e) => setEmail(e.target.value!)}
                            />
                          ) : (
                            <Typography  variant="h5">{email}</Typography>
                          )}
                        </Box>
                      </>
                    ) : (
                      ""
                    )}
                    {priv && (coach.address || editable) && (
                      <>
                        <Typography  variant="h5" fontWeight='bold'>
                          Adresse
                        </Typography>
                        {editable ? (
                          <PlaceAutocomplete
                            onChange={(e) => setLocation(e)}
                            initValue={location}
                            disabled={!editable}
                            customStyle={customPlaceStyle}
                            noOptionsMessage="J'habite au ..."
                          />
                        ) : (
                          <Typography variant="h5">
                            {location?.description}
                          </Typography>
                        )}
                      </>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={2}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {priv && (
                      <Button variant='contained' size='large' onClick={() => {
                        if (editable) {
                          setEditable(false);
                          savePrivate();
                        } else {
                          setEditable(true);
                        }
                        }}
                      sx={{
                        borderRadius: 20, fontSize: '1.3em', my: 3, width: '200px'}}>
                      { editable ? 'Sauvegarder' : 'Modifier'}
                    </Button>
                    )}
                    {priv ? (
                       <Button variant='contained' size='large' onClick={(e) =>
                          showModal(
                            <PasswordForm />,
                            "Changement de mot de passe",
                            true
                          )
                        }
                        style={{
                          borderRadius: 20, fontSize: '1.3em',  width: '200px'}}>
                        Mot de passe
                      </Button>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {priv ? (
              <>
              <Typography variant="h4" color='#f36b29' fontWeight='bold' ml={5}>
              Lieux de travail
              </Typography>
                <Box sx={{
                    backgroundColor: '#a2c2c2', my: 5, p: 5, mb: 10
                  }}>
                  <PlaceAutocomplete
                    onChange={addLocation}
                    cities={true}
                    customStyle={customPlaceStyle}
                    noOptionsMessage="Je travaille à ..."
                  />
                  {coachLocations.map((c, i) => (
                    <LocationChip
                      key={i}
                      location={c}
                      delete={priv ? deleteLocation : undefined}
                      customStyle={classes.chipCustomStyle}
                    />
                  ))}
                </Box>

                {/* <IonRow>
                  <IonCol size="10">
                    <IonInput
                      placeholder="Nom du fichier"
                      value={fileName}
                      onIonChange={(e) => setFileName(e.detail.value!)}
                    />
                  </IonCol>
                  <IonCol size="1">
                    <IonButton onClick={openPicker} fill="clear">
                      <input
                        id="filePicker"
                        type="file"
                        accept="image/*"
                        onChange={onPickedFiles}
                        name="Image"
                        style={{ display: "none" }}
                      />
                      <IonIcon icon={add} />
                    </IonButton>
                  </IonCol>
                  <IonCol size="1">
                    <IonButton onClick={saveFile} fill="clear">
                      <IonIcon icon={save} />
                    </IonButton>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonLabel>{originalFileName}</IonLabel>
                </IonRow> */}

                <Typography variant="h4" color='#f36b29' fontWeight='bold' ml={5}>
                Ajout de fichier
                </Typography>

                <Box sx={{
                  backgroundColor: '#a2c2c2', my: 5, p: 5, mb: 10
                }}>
                  <Grid
                    container
                    spacing={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={9}>
                      <TextField
                        variant="standard"
                        placeholder="Nom du fichier"
                        value={fileName}
                        onChange={(e) => setFileName(e.target.value!)}
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <IconButton onClick={openPicker}>
                        <input
                          id="filePicker"
                          type="file"
                          accept="image/*"
                          onChange={onPickedFiles}
                          name="Image"
                          style={{ display: "none" }}
                        />
                        <UploadFileIcon />
                      </IconButton>
                      <IconButton onClick={saveFile}>
                        <SaveOutlinedIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>

                <Typography variant="h4" color='#f36b29' fontWeight='bold' ml={5}>
                  Vos pièces justificatives
                  <Tooltip
                      title="Liste des pièces à fournir : Carte professionnelle, responsabilité civile professionnelle, RIB, diplôme si activité spécifique (ex: boxe)"
                      sx={{ ml: 1 }}
                    >
                      <IconButton>
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                </Typography>

                <Box sx={{
                    backgroundColor: '#a2c2c2', my: 5, p: 5, mb: 10
                  }}>
                  {files?.length == 0 ? (
                    <Typography>Aucun fichier</Typography>
                  ) : (
                    files?.map((f, k) => (
                      <Grid
                        container
                        spacing={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item xs={9}>
                          <TextField
                            id="standard-basic"
                            placeholder="Nom du fichier"
                            variant="standard"
                            value={f.name}
                            onChange={(e) => setRenamedFile(e.target.value!, f)}
                          />
                        </Grid>
                        <Grid item xs="auto">
                          <IconButton onClick={() => openFile(f)}>
                            <DownloadIcon />
                          </IconButton>
                          {/* <IconButton onClick={() => renameFile(f)}>
                            <SaveOutlinedIcon />
                          </IconButton> */}
                          {
                            coach.coach.state === false ?
                            (
                              <IconButton onClick={() => deleteFile(f)}>
                                <DeleteIcon />
                              </IconButton>
                            )
                            : ''
                          }
                        </Grid>
                      </Grid>
                    ))
                  )}
                </Box>
              </>
            ) : (
              ""
            )}
            {
              (coach.comments?.length ?? 0) > 0 ?
                (
                <Box>
                  <Typography variant="h4" color='#f36b29' fontWeight='bold'>
                    {avisTitle}
                  </Typography>
                  <DerniersAvis comments={coach.comments} />
                </Box>
                ) : ''
            }

            {canClientAddComment ? (
              <Box className="profileClientBloc" sx={classes.textarea}>
                <Typography variant="h4" color='#f36b29' fontWeight='bold'>
                  Vous souhaitez laisser un commentaire suite à votre dernière
                  séance ?
                </Typography>

                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={3}
                  value={clientComment}
                  onChange={(e) => setClientComment(e.target.value!)}
                  placeholder="Laisser ici votre commentaire"
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography variant='h6' color='black' fontWeight='bold' mr={3}>
                    Notez votre coach
                  </Typography> 
                  <Rate
                    rate={clientNote}
                    onChanged={(e) => setClientNote(e === clientNote ? 0 : e)}
                  />
                  <IconButton onClick={() => sendComment()}>
                    <ArrowUpwardOutlinedIcon />
                  </IconButton>
                </div>
              </Box>
            ) : (
              ""
            )}
          </Box>
        ) : (
          <LoadingButton loading></LoadingButton>
        )}
      </Box>
    </>
  );
};
