export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isPasswordValid = (password: string) => {
  return String(password)
    .match(/^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,32}$/);
}

export const validatePhone = (phone: string) => {
  return String(phone)
    .toLowerCase()
    .match(
      /^(\+33[0-9]{9})|(0[0-9]{9})$/im
    ) && ((phone.length === 10 && phone[0] === '0') || (phone.length === 12 && phone[0] === '+'));
};

export const getReductionValue = (value: number) : number => {
  return parseFloat((value).toPrecision(2)) * 100;
}