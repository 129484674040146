import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlaceAutocomplete } from "../components/PlaceAutocomplete";
import {
  LoggedType,
  LOGIN_COACH,
  LOGIN_USER,
  SIGN_IN_COACH,
  SIGN_IN_USER,
} from "../slices/loginSlice";
import { Location } from "../models/Location";
import {
  isPasswordValid,
  validateEmail,
  validatePhone,
} from "../utils/manyUtils";
import { RootState } from "../store";
import { useHistory, useLocation } from "react-router";
import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import classes from "../style";
import Input from "@mui/material/Input";
import { badPasswordPopup, showMessage } from "../services/popupService";

const ariaLabel = { "aria-label": "description" };

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const customPlaceStyle = {
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 99999,
    minWidth: "100px",
    "&:after": {
      position: "absolute",
      zIndex: 9999,
      content: '""',
      left: "20px",
      top: "0px",
      width: 0,
      height: 0,
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: "16px solid #fff",
    },
  }),
  control: (provided: any) => ({
    ...provided,
    borderRadius: 0,
    borderTop: 0,
    borderRight: 0,
    borderLeft: 0,
    backgroundColor: "transparent",
    borderColor: "#FFF",
    boxShadow: "none",
    height: "32px",
    minHeight: "32px",
    "&:hover": {
      borderColor: "#FFF",
    },
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: 0,
  }),
  input: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    backgroundColor: "transparent",
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "#FFF !important",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected || state.isFocused ? "#8C6AD4" : "#000",
    background: "none",
    "&:hover": {
        color: "#8C6AD4",
      background: "none",
    },
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    border: "2px solid #FFF",
    marginTop: "15px",
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: "#FFF",
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontSize: "18px",
    fontStyle: "italic",
    color: "#FFF",
    opacity: 0.7,
  }),
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    "& .MuiTab-root.Mui-selected": {
      color: 'white', borderRight: '0px solid important!'
    }
  };
}

export const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const store = useSelector((s: RootState) => s);
  const history = useHistory();
  const url = useLocation().pathname;

  const redirectToShop = () => {
    const query = new URLSearchParams(window.location.search);
    const r = query.get("redirect");
    return r === "shop";
  };

  const toShop = redirectToShop();
  const [value, setValue] = React.useState(toShop ? 1 : 0);

  const [isClient, setIsClient] = useState(true);
  const [email, setMail] = useState("");
  const [confirmEmail, setConfirmMail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState<Location>();
  const [phone, setPhone] = useState("");
  const [siret, setSiret] = useState("");

  if (store.login.loggedType !== LoggedType.None) {
    if (redirectToShop()) {
      history.push("/?redirect=shop");
    } else if (store.login.loggedType === LoggedType.Client) {
      history.push("/client?id=" + store.login.client?.id);
    } else {
      history.push('/');
    }
  }

  useEffect(() => {
    if (url.indexOf('coach') >= 0 && store.login.loggedType === LoggedType.None) {
      setClientMode(false);
      setValue(1);
    }

    if (url.indexOf('client') >= 0 && store.login.loggedType === LoggedType.None) {
      setClientMode(true);
      setValue(1);
    }
  }, []);

  const setClientMode = (isClient: boolean) => {
    resetValues();
    setIsClient(isClient);
  };

  const resetValues = () => {
    setMail("");
    setFirstName("");
    setPassword("");
    setConfirmation("");
    setAddress(undefined);
    setConfirmMail("");
    setLastName("");
    setPhone("");
    setSiret("");
  };


  const createAccount = () => {
    if (email.length == 0 || !validateEmail(email)) {
      showMessage("Veuillez entrer une adresse email valide.");
      return;
    }

    if (email != confirmEmail) {
      showMessage("Les adresses emails ne correspondent pas.");
      return;
    }

    if (isClient) {
      if (!isPasswordValid(password)) {
        badPasswordPopup();
        return;
      }

      if (password != confirmation) {
        showMessage("Les mots de passe ne correspondent pas.");
        return;
      }
    }

    if (firstName == "") {
      showMessage("Veuillez entrer un prénom.");
      return;
    }

    if (lastName == "") {
      showMessage("Veuillez entrer un nom.");
      return;
    }

    if (phone == "" || !validatePhone(phone)) {
      showMessage("Veuillez entrer un numéro de téléphone valide.");
      return;
    }

    if (address == undefined) {
      showMessage("Veuillez saisir votre adresse.");
      return;
    }

    if (!isClient && siret == "") {
      showMessage("Veuillez saisir votre SIRET.");
      return;
    }

    const param = {
      email,
      password,
      firstName,
      lastName,
      placeId: address.placeId,
      placeDescription: address.description,
      phone,
      siret: isClient ? "" : siret,
      shopCards: store.reservation.card,
    };

    if (isClient) dispatch(SIGN_IN_USER(param));
    else dispatch(SIGN_IN_COACH(param));
  };

  const login = () => {
    if (email.length == 0 || !validateEmail(email)) {
      showMessage("Veuillez saisir votre adresse mail.");
      return;
    }

    if (password == "") {
      showMessage("Veuillez saisir votre mot de passe.");
      return;
    }

    if (isClient) dispatch(LOGIN_USER({ email, password }));
    else dispatch(LOGIN_COACH({ email, password }));
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    resetValues();
    setValue(newValue);
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "calc(100vh - 96px)",
        display: "flex",
        backgroundImage: "url('login-bg.jpg')",
        backgroundSize: "cover",
        py: 2,
      }}
    >
      <Box sx={classes.login}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            py: 2,
          }}
        >
          <Tabs indicatorColor="primary"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {toShop ? "" : <Tab label="Connexion" {...a11yProps(0)} value={0}/>}
            <Tab label="Inscription" {...a11yProps(1)} value={1} disabled={toShop}/>
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} >
          <Typography variant="h6">Ravis de vous revoir !</Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!isClient}
                  onChange={(e: any) => setClientMode(!e.target.checked)}
                />
              }
              label="Je suis un coach"
            />
          </FormGroup>
          <FormGroup>
            <Input
              id="mail"
              value={email}
              placeholder="Email"
              onChange={(e: any) => setMail(e.target.value!)}
              inputProps={ariaLabel}
              onSubmit={() => login()}
            />
          </FormGroup>
          <FormGroup>
            <Input
              id="mdp"
              value={password}
              onChange={(e: any) => setPassword(e.target.value!)}
              type="password"
              placeholder="Mot de passe"
              inputProps={ariaLabel}
              onKeyDown={(e) => {if (e.key == 'Enter') { login();}}}
            />
          </FormGroup>
          <Button
            variant="text"
            onClick={() => history.push("/forgot-password")}
          >
            Mot de passe oublié ?
          </Button>
          <Button
            variant="contained"
            sx={classes.submitBtn}
            onClick={() => login()}
          >
            Se connecter
          </Button>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {toShop ? (
            ""
          ) : (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!isClient}
                    onChange={(e: any) => setClientMode(!e.target.checked)}
                  />
                }
                label="Je suis un coach"
              />
              {!isClient && (
                <Typography className="info">
                  *Votre profil de coach ne sera validé qu’une fois les pièces
                  justificatives fournies.
                </Typography>
              )}
            </FormGroup>
          )}
          {isClient || toShop ? (
            <>
              <FormGroup>
                <Input
                  id="nom"
                  value={lastName}
                  onChange={(e: any) => setLastName(e.target.value!)}
                  placeholder="Nom"
                  inputProps={ariaLabel}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  id="prenom"
                  value={firstName}
                  onChange={(e: any) => setFirstName(e.target.value!)}
                  placeholder="Prénom"
                  inputProps={ariaLabel}
                />
              </FormGroup>
              <FormGroup sx={{ my: 2 }}>
                <PlaceAutocomplete
                  onChange={(e) => setAddress(e)}
                  placeholder="Adresse"
                  customStyle={customPlaceStyle}
                  noOptionsMessage="J'habite au ..."
                />
              </FormGroup>
              <FormGroup>
                <Input
                  id="mail"
                  value={email}
                  autoComplete="off"
                  onChange={(e: any) => setMail(e.target.value!)}
                  placeholder="Email"
                  inputProps={ariaLabel}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  id="mail2"
                  value={confirmEmail}
                  autoComplete="off"
                  onChange={(e: any) => setConfirmMail(e.target.value!)}
                  placeholder="Confirmation email"
                  inputProps={ariaLabel}
                />
              </FormGroup>

              <FormGroup>
                <Input
                  id="mdp"
                  value={password}
                  type="password"
                  autoComplete="off"
                  onChange={(e: any) => setPassword(e.target.value!)}
                  placeholder="Mot de passe"
                  inputProps={ariaLabel}
                />
                <span className="passwordIndication">
                  Votre mot de passe doit contenir minimum 8 caractères, avec une majuscule, une minuscule et un chiffre.
                </span>
              </FormGroup>
              <FormGroup>
                <Input
                  id="confirmation-mdp"
                  value={confirmation}
                  type="password"
                  autoComplete="off"
                  onChange={(e: any) => setConfirmation(e.target.value!)}
                  placeholder="Confirmation mot de passe"
                  inputProps={ariaLabel}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  id="telephone"
                  value={phone}
                  type="text"
                  autoComplete="off"
                  onChange={(e: any) => setPhone(e.target.value!)}
                  placeholder="Téléphone"
                  inputProps={ariaLabel}
                />
              </FormGroup>
              <Button
                variant="contained"
                sx={classes.submitBtn}
                onClick={() => createAccount()}
              >
                Créer un compte
              </Button>
            </>
          ) : (
            <>
              <FormGroup>
                <Input
                  id="nom"
                  value={lastName}
                  onChange={(e: any) => setLastName(e.target.value!)}
                  placeholder="Nom"
                  inputProps={ariaLabel}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  id="prenom"
                  value={firstName}
                  onChange={(e: any) => setFirstName(e.target.value!)}
                  placeholder="Prénom"
                  inputProps={ariaLabel}
                />
              </FormGroup>
              <FormGroup sx={{ my: 2 }}>
                <PlaceAutocomplete
                  onChange={(e) => setAddress(e)}
                  placeholder="Adresse"
                  customStyle={customPlaceStyle}
                  noOptionsMessage="J'habite au ..."
                />
              </FormGroup>
              <FormGroup>
                <Input
                  id="mail"
                  value={email}
                  autoComplete="off"
                  onChange={(e: any) => setMail(e.target.value!)}
                  placeholder="Email"
                  inputProps={ariaLabel}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  id="mail2"
                  value={confirmEmail}
                  autoComplete="off"
                  type="text"
                  onChange={(e: any) => setConfirmMail(e.target.value!)}
                  placeholder="Confirmation email"
                  inputProps={ariaLabel}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  id="telephone"
                  autoComplete="off"
                  value={phone}
                  type="text"
                  onChange={(e: any) => setPhone(e.target.value!)}
                  placeholder="Téléphone"
                />
              </FormGroup>
              <FormGroup>
                <Input
                  id="siret"
                  autoComplete="off"
                  value={siret}
                  onChange={(e: any) => setSiret(e.target.value!)}
                  placeholder="Siret"
                  inputProps={ariaLabel}
                  onKeyDown={(e) => {if (e.key == 'Enter') { createAccount();}}}
                />
              </FormGroup>
              <Button
                variant="contained"
                sx={classes.submitBtn}
                onClick={() => createAccount()}
              >
                Créer un compte
              </Button>
            </>
          )}
        </TabPanel>
      </Box>
    </Container>
  );
};
