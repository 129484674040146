import { Location } from "./Location";
import { Comment } from "./ProposedCoach";
import { Speciality } from "./Speciality";

export interface Coach {
    id: number;
    firstname: string;
    lastname: string;
    state: boolean;
    specialities: Speciality[]
    note: number;
    phoneNumber: string;
}

export interface CoachView {
    coach: Coach;
    email: string;
    address: Location;
    workPlaces: Location[];
    comments: Comment[];
    sessionsDone: number;
    isFavorite: boolean;
}

export interface CoachFiles {
    coachId: number;
    name: string;
    lastDate: Date;
    content: string;
    extension: string;
}

export interface AddFileResult {
    result: FileResultType;
    file: CoachFiles;
}

export enum FileResultType {
    OK, Error, NameAlreadyExit, BadFile, BadName
}