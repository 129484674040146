import { Box, Button } from "@mui/material";
import classes from "./style";
import { Comment } from "../comment";

export interface CommentListProps {
  comments: any[];
}

export const CommentList: React.FC<CommentListProps> = ({
  comments,
}: CommentListProps) => {
  return (
    <Box sx={classes.commentList}>
      <Box>
        {comments.map((comment: any) => (
          <Box sx={{ my: 1 }}>
            <Comment text={comment.content} author={comment.clientName} />
          </Box>
        ))}
      </Box>
      {/* <Button variant="text">Voir plus d’avis</Button> */}
    </Box>
  );
};
