import { useState } from "react";
import { useHistory } from "react-router";
import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
} from "@mui/material";
import classes from "../style";
import Input from "@mui/material/Input";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { showMessage } from "../services/popupService";
import { validateEmail } from "../utils/manyUtils";
import { forgotPassword } from "../services/loginService";

const ariaLabel = { "aria-label": "description" };

export const ForgotPasswordPage: React.FC = () => {
  const history = useHistory();

  const [email, setMail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [isClient, setIsClient] = useState(true);

  const sendForgot = async() => {
    if (email === "" || !validateEmail(email)) {
      showMessage('Veuillez entrer une adresse mail valide.');
      return;
    }

    if (firstname === "") {
      showMessage('Veuillez entrer votre prénom.');
      return;
    }

    try {
      await forgotPassword(email, firstname, isClient);
      showMessage('Si cette adresse mail existe, vous recevrez votre mot de passe temporaire.');
    } catch {
      showMessage('Une erreur est survenue, veuillez réessayer.');
    }
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "calc(100vh - 96px)",
        display: "flex",
        backgroundImage: "url('login-bg.jpg')",
        backgroundSize: "cover",
        p: 2,
      }}
    >
      <Box sx={{ ...classes.login, padding: "64px 32px !important" }}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <IconButton
            sx={{ color: "white", mr: 2 }}
            aria-label="close drawer"
            onClick={() => history.push("/acces-membre")}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ textAlign: "justify" }}>
            Réinitialisation du mot de passe
          </Typography>
        </Box>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={!isClient}
                onChange={(e: any) => setIsClient(!e.target.checked)}
              />
            }
            label="Je suis un coach"
          />
        </FormGroup>
        <FormGroup>
          <Input
            id="mail"
            placeholder="Votre adresse mail"
            value={email}
            onChange={(e: any) => setMail(e.target.value!)}
            inputProps={ariaLabel}
          />
        </FormGroup>

        <FormGroup>
          <Input
            id="name"
            placeholder="Votre prénom"
            value={firstname}
            onChange={(e: any) => setFirstname(e.target.value!)}
            inputProps={ariaLabel}
          />
        </FormGroup>
        <Button
          variant="contained"
          sx={classes.submitBtn}
          onClick={() => sendForgot()}
        >
          Réinitialiser le mot de passe
        </Button>
      </Box>
    </Container>
  );
};
