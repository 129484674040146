import { all, call, put, select, takeLeading } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { LOGIN_COACH, LOGIN_COACH_RESULT, LOGIN_USER, LOGIN_USER_RESULT, SIGN_IN_USER, SIGN_IN_COACH, DISCONNECT, UNLOG_USER, RELOG_USER, RELOG_COACH, LoginState, LoggedType, SET_TOKEN } from '../slices/loginSlice';
import { createCoach, createUser, loginCoach, loginUser, relogCoach, relogUser } from '../services/loginService';
import { CreateClientResult, CreateClientType, CreateCoachResult, CreateCoachType, CreateRequest, LoginResult, LoginResultType } from '../models/Login';
import { LOAD_CARD, LOAD_PRICES, RESET_RESERVATIONS, SET_RESERVATIONS } from '../slices/reservationSlice';
import { removeToken, setToken } from '../services/cookieService';
import { RootState } from '../store';
import { LOAD_SPECIALITIES } from '../slices/coachSlice';
import { badPasswordPopup, showMessage } from '../services/popupService';

export default function* loginSaga() {
    yield all([
        takeLeading(LOGIN_USER, loginUserSaga),
        takeLeading(LOGIN_COACH, loginCoachSaga),
        takeLeading(SIGN_IN_USER, createUserSaga),
        takeLeading(SIGN_IN_COACH, createCoachSaga),
        takeLeading(RELOG_USER, relogUserSaga),
        takeLeading(RELOG_COACH, relogCoachSaga),
        takeLeading(DISCONNECT, disconnectSaga),
        takeLeading(UNLOG_USER, disconnectSaga),
        takeLeading(SET_TOKEN, setTokenSaga),
        takeLeading(LOGIN_USER_RESULT, handleError),
        takeLeading(LOGIN_COACH_RESULT, handleError)
    ]);
}

function handleError (action: PayloadAction<LoginResult>) {
    const result = action.payload.result;

    if (result !== LoginResultType.OK) {
        let message = '';

        switch (result) {
            case LoginResultType.BadLocation:
                message = 'L\'adresse postale entrée est incorrecte.'; break;
            case LoginResultType.Error:
                message = 'Une erreur est survenue. Veuillez contacter un administrateur.'; break;
            case LoginResultType.Expired:
                message = 'Votre session a expiré.'; break;
            case LoginResultType.MissingCredentials:
                message = 'Veuillez saisir votre email et mot de passe.'; break;
            case LoginResultType.Wrong:
                message = 'Les informations d\'identification entrés ne correspondent à aucun utilisateur.'; break;
            default: return;
        }

        showMessage(message);
    }
}

function handleClientCreationError (result: CreateClientType) {
    if (result !== CreateClientType.OK) {
        let message = '';

        switch (result) {
            case CreateClientType.BadLocation:
                message = 'L\'adresse postale entrée est incorrecte.'; break;
            case CreateClientType.Error:
                message = 'Une erreur est survenue. Veuillez contacter un administrateur.'; break;
            case CreateClientType.EmailInvalid:
                message = 'Veuillez entrer une adresse email valide..'; break;
            case CreateClientType.EmailUsed:
                message = 'Cette adresse email est déjà utilisée.'; break;
            case CreateClientType.NameMissing:
                message = 'Veuillez saisir un nom valide.'; break;
            case CreateClientType.PasswordInvalid:
                badPasswordPopup(); return;
            default: return;
        }

        showMessage(message);
    }
}

function handleCoachCreationError (result: CreateCoachType) {
    if (result !== CreateCoachType.OK) {
        let message = '';

        switch (result) {
            case CreateCoachType.BadLocation:
                message = 'L\'adresse postale entrée est incorrecte.'; break;
            case CreateCoachType.Error:
                message = 'Une erreur est survenue. Veuillez contacter un administrateur.'; break;
            case CreateCoachType.EmailInvalid:
                message = 'Veuillez entrer une adresse email valide..'; break;
            case CreateCoachType.EmailUsed:
                message = 'Cette adresse email est déjà utilisée.'; break;
            case CreateCoachType.NameMissing:
                message = 'Veuillez saisir un nom valide.'; break;
            case CreateCoachType.PasswordInvalid:
                badPasswordPopup(); return;
            case CreateCoachType.PhoneInvalid:
                message = 'Veuillez saisir un numéro de téléphone'; break;
            case CreateCoachType.PhoneUsed:
                message = 'Ce numéro de téléphone a déjà été utilisé.'; break;
            case CreateCoachType.SiretInvalid:
                message = 'Veuillez entrer un siret valide.'; break;
            case CreateCoachType.SiretUsed:
                message = 'Ce siret a déjà été utilisé.'; break;
            default: return;
        }

        showMessage(message);
    }
}

function* loginUserSaga(action: PayloadAction<{email: string, password: string}>) {
    try {
        const user: LoginResult = yield call(loginUser, action.payload.email, action.payload.password);

        if (user.result != LoginResultType.OK) {
            yield put(LOGIN_USER_RESULT({result: user.result, client: undefined, coach: undefined, token: ''}));
            return;
        }

        const id = user.client?.id ?? 0;

        connectClient(id, user.token);

        yield put(LOGIN_USER_RESULT(user));
    } catch (e) {
        yield put(LOGIN_USER_RESULT({result: LoginResultType.Error, client: undefined, coach: undefined, token: ''}));
    }
    yield put(LOAD_CARD());
    yield put(RESET_RESERVATIONS());
}

function* loginCoachSaga(action: PayloadAction<{email: string, password: string}>) {
    try {
        const user: LoginResult = yield call(loginCoach, action.payload.email, action.payload.password);

        if (user.result != LoginResultType.OK) {
            yield put(LOGIN_COACH_RESULT({result: user.result, client: undefined, coach: undefined, token: ''}));
            return;
        }

        connectCoach(user.coach?.id ?? 0, user.token);

        yield put(LOGIN_COACH_RESULT(user));
    } catch (e) {
        yield put(LOGIN_COACH_RESULT({result: LoginResultType.Error, client: undefined, coach: undefined, token: ''}));
    }

    yield put(LOAD_CARD());
    yield put(RESET_RESERVATIONS());
}

function* createUserSaga(action: PayloadAction<CreateRequest>) {
    try {
        const user: CreateClientResult = yield call(createUser, action.payload);

        if (user.result != CreateClientType.OK || user.client == undefined) {
            handleClientCreationError(user.result);
            return;
        }

        yield put(LOGIN_USER({email: action.payload.email, password: action.payload.password}))
    } catch (e) {
       handleClientCreationError(CreateClientType.Error);
    }
    yield put(RESET_RESERVATIONS());
}

function* createCoachSaga(action: PayloadAction<CreateRequest>) {
    try {
        const state: LoginState = yield select((s: RootState) => s.login);
        if (state.loggedType != LoggedType.None) return;
        
        const user: CreateCoachResult = yield call(createCoach, action.payload);

        if (user.result != CreateCoachType.OK || user.coach == undefined) {
            handleCoachCreationError(user.result);
            return;
        }

        //yield put(LOGIN_COACH({email: action.payload.email, password: action.payload.password}))

        showMessage('Un email de confirmation vous a été envoyé avec votre mot de passe temporaire. N\'oubliez pas de le changer.');
    } catch (e) {
        handleCoachCreationError(CreateCoachType.Error);
    }

    yield put(LOAD_CARD());
    yield put(RESET_RESERVATIONS());
}

function* relogUserSaga(action: PayloadAction<{id: number, token: string}>) {
    try {
        const state: LoginState = yield select((s: RootState) => s.login);
        if (state.loggedType != LoggedType.None) return;

        const user: LoginResult = yield call(relogUser, action.payload.id, action.payload.token);

        if (user.result != LoginResultType.OK) {
            yield put(LOGIN_USER_RESULT({result: LoginResultType.RelogFailed, client: undefined, coach: undefined, token: ''}));
            yield put(LOAD_PRICES());
            yield put(LOAD_SPECIALITIES());
            yield put(LOAD_CARD());
            removeToken();
            return;
        }

        const id = user.client?.id ?? 0;

        connectClient(id, user.token);

        yield put(LOGIN_USER_RESULT(user));
    } catch (e) {
        yield put(LOGIN_USER_RESULT({result: LoginResultType.RelogFailed, client: undefined, coach: undefined, token: ''}));
    }

    yield put(LOAD_PRICES());
    yield put(LOAD_SPECIALITIES());
    yield put(LOAD_CARD());
    yield put(RESET_RESERVATIONS());
}

function* relogCoachSaga(action: PayloadAction<{id: number, token: string}>) {
    try {
        const state: LoginState = yield select((s: RootState) => s.login);
        if (state.loggedType != LoggedType.None) return;

        const user: LoginResult = yield call(relogCoach, action.payload.id, action.payload.token);

        if (user.result != LoginResultType.OK) {
            yield put(LOGIN_COACH_RESULT({result: LoginResultType.RelogFailed, client: undefined, coach: undefined, token: ''}));
            yield put(LOAD_PRICES());
            yield put(LOAD_SPECIALITIES());
            yield put(LOAD_CARD());
            removeToken();
            return;
        }

        connectCoach(user.coach?.id ?? 0, user.token);

        yield put(LOGIN_COACH_RESULT(user));
    } catch (e) {
        yield put(LOGIN_COACH_RESULT({result: LoginResultType.RelogFailed, client: undefined, coach: undefined, token: ''}));
    }

    yield put(LOAD_PRICES());
    yield put(LOAD_SPECIALITIES());
    yield put(LOAD_CARD());
    yield put(RESET_RESERVATIONS());
}

function* setTokenSaga(action: PayloadAction<string>) {
    const state: LoginState = yield select((s: RootState) => s.login);

    if (state.loggedType === LoggedType.Client) {
        connectClient(state.client?.id ?? 0, action.payload);
    } else if (state.loggedType === LoggedType.Coach) {
        connectCoach(state.coach?.id ?? 0, action.payload);
    }
}

function connectClient (id: number, token: string)
{
    removeHeaders();
    axios.defaults.headers.common['AppId'] = id;
    axios.defaults.headers.common['AppRights'] = token;

    setToken(id, true, token);
}

function connectCoach (id: number, token: string)
{
    removeHeaders();
    axios.defaults.headers.common['ApplicationId'] = id;
    axios.defaults.headers.common['AppRights'] = token;

    setToken(id, false, token);
}

function removeHeaders()
{
    delete axios.defaults.headers.common['AppId'];
    delete axios.defaults.headers.common['ApplicationId'];
    delete axios.defaults.headers.common['AppRights'];
}

function disconnectSaga()
{
    removeHeaders();
    removeToken();
}