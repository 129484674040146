import axios from 'axios';
import { Dispo } from '../components/DispoPicker';
import { HOPLA_URL } from '../config/config';
import { ProposedCoach } from '../models/ProposedCoach';
import { AddPackResult, ReservationProposalRequest, ReservationProposalResult } from '../models/ReservationProposal';

export const pickCoach = async (userChoose: boolean, placeId: string, placeDescription: string, specialityId: number, dispos: Dispo[], exclude: Date[])
    : Promise<ProposedCoach[]> => {
    return (await axios.post(HOPLA_URL + '/coach/pickCoach',
    {
        userChoose,
        placeId,
        placeDescription,
        specialityId,
        dispos: dispos.map(d => d.start),
        exclude
    })).data;
}

export const getPrices = async() => {
    return (await axios.get(HOPLA_URL + '/card/prices')).data;
}

export const validateReservation = async (proposal: ReservationProposalRequest): Promise<ReservationProposalResult> => {
    return (await axios.post(HOPLA_URL + '/reservation/validate', proposal)).data;
}

export const addPacktoShop = async (): Promise<AddPackResult> => {
    return (await axios.get(HOPLA_URL + '/reservation/addPack')).data;
}