import React, { FC, useEffect, useState } from "react";

import classes from "../style";
import { Grid, Typography, Box, Container } from "@mui/material";
import { Image } from "./Image";
import { Rate } from "./shared/rate";
import { getMonthlyCoach } from "../services/coachService";
import { CoachView } from "../models/Coach";
import { CommentList } from "./shared/commentList";

const CoachDuMois: FC<{}> = () => {
  const [coach, setCoach] = useState<CoachView | undefined>(undefined);

  useEffect(() => {
    const get = async () => {
      setCoach(await getMonthlyCoach());
    };

    get();
  }, []);

  return (
    <>
      {coach ? (
        <Box sx={classes.coachDuMoisContainer}>
          <Box sx={classes.coachDuMois}>
            <Container maxWidth="xl" sx={{ pt: 8, pb: 8 }}>
              <Grid
                container
                spacing={1}
                alignItems="center"
              >
                <Grid
                  item
                  sm={4}
                  lg={5} xs={12}
                  display="flex"
                  flexDirection='column'
                  alignItems='center'
                  justifyContent="center"
                >
                  <Image
                    userId={coach.coach.id}
                    isClient={false}
                    editable={false}
                    favoris={coach.isFavorite}
                    responsive circle
                  />
                  <Typography variant="h5" fontWeight='bold' sx={{mt: 2}}>
                    {coach.coach.firstname} {coach.coach.lastname}
                  </Typography>
                </Grid>
                <Grid item sm={7} lg={6}>
                  <Box display='flex' flexDirection='row'>
                    <Typography variant="h4" sx={{mr: 3}}>
                      Le coach du mois
                    </Typography>
                    <Rate rate={coach.coach.note} style={{ justifyContent: "flex-end" }} />
                  </Box>
                  <Typography variant="body1">
                    Spécialité:{" "}
                    {coach.coach.specialities.map((s) => s.name).join(", ")}
                  </Typography>
                  <hr />
                  <CommentList comments={coach.comments} />
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      ) : (
        ""
      )}
    </>
  );
};

export default CoachDuMois;
