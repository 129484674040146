import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import RenforcementMusuclaire from '../images/renforcement-musculaire.jpeg';
import CardioTrianing from '../images/cardio-training.jpeg';
import RemiseEnForme from '../images/remise-en-forme.jpeg';
import './VideoCss.css';
import ReactPlayer from 'react-player'
import HoplaTheme from "../theme";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Speciality } from "../models/Speciality";
import { CoursInfos } from "../components/CoursInfos";
import { HorizontalText } from "../components/HorizontalText";
import Fond from '../images/Fond_Page_Fitness.jpg';

const bubbleStyle = {
  width: '14px',
  height: '14px',
  borderRadius: '50%',
  borderColor: 'white',
  border: '2px solid white',
  marginTop: '16px',
}

export const FitnessPage: React.FC = () => {
  const url = `C:/Users/Mehdi/Documents/HopLa/HopLa.Web/hopLa/src/images/video_boxe.png`;
  const matchDownXs = useMediaQuery(HoplaTheme.breakpoints.down("md"));

  const specialities = useSelector((s: RootState) => s.coaches.specialities);

  const getSpe = (name: string): Speciality | undefined => {
    const f = specialities?.filter(s => s.name == name);
    if (f.length == 0) return undefined;
    return f[0];
  }

  const dots = [
    'Perte de poids',
    'Prise de masse',
    'Renforcement musculaire',
    'Amélioration du système cardiovasculaire',
  ];

  const infos = [
    {
      title: 'Coaching fitness personnalisé à Paris avec Hoplà',
      subTitle: `Vous recherchez une expérience de remise en forme personnalisée à Paris ? Hoplà est la plateforme idéale pour réserver vos séances de coaching en fitness, renforcement musculaire, cardio training et remise en forme en Île-de-France. Notre équipe de coachs qualifiés vous accompagne dans l'atteinte de vos objectifs.`
    },
    {
      title: 'Votre forme physique entre de bonnes mains',
      subTitle: `Avec Hoplà, vous bénéficiez d'un encadrement professionnel pour optimiser votre entraînement. Nos coachs expérimentés mettent en place des programmes adaptés à votre niveau et vos besoins spécifiques. Que vous souhaitiez perdre du poids, sculpter votre silhouette ou améliorer votre condition physique, nous sommes là pour vous guider.`
    },
    {
      title: 'Une approche holistique de la remise en forme',
      subTitle: `Nous croyons en une approche holistique de la remise en forme. En plus des séances de fitness traditionnelles, nous proposons une variété d'activités complémentaires telles que le yoga, la méditation et la nutrition équilibrée. Cette approche globale vous permet d'améliorer non seulement votre corps, mais aussi votre bien-être mental et émotionnel.`
    },
    {
      title: 'Des séances adaptées à votre emploi du temps',
      subTitle: `Nous comprenons que votre emploi du temps peut être chargé. C'est pourquoi Hoplà vous offre une flexibilité totale dans la réservation de vos séances de coaching en fitness. Que vous préfériez un entraînement matinal avant le travail, une séance pendant la pause déjeuner ou une session en soirée, nous avons des créneaux adaptés à votre emploi du temps.`
    },
    {
      title: 'Réservez dès maintenant et commencez votre parcours de remise en forme',
      subTitle: <Typography variant="h5">
        Ne perdez plus de temps, réservez dès maintenant vos séances de coaching en fitness avec Hoplà. Transformez votre corps, gagnez en énergie et retrouvez votre confiance en vous. Notre équipe est impatiente de vous accompagner dans votre parcours de remise en forme.
      <ul>
        <li>Réservez des séances de coaching en fitness, renforcement musculaire, cardio training et remise en forme</li>
        <li>Bénéficiez d'un encadrement professionnel et de programmes personnalisés</li>
        <li>Choisissez des créneaux horaires flexibles selon votre emploi du temps</li>
        <li>Commencez dès maintenant votre parcours de remise en forme avec Hoplà</li>
      </ul>
      </Typography>
    }
  ]

  const cours = [
    {
      title: 'Renforcement musculaire',
      subTitle: 'Squat, pompes et abdos : que du Bonheur',
      content: 
        <Typography color='black' variant='h5'>
          Hop là ! C’est le moment de se <Box display='inline' fontWeight='bold'>remettre en forme</Box>, de 
          <Box display='inline' fontWeight='bold'>stopper ces douleurs de dos</Box>, de <Box display='inline' fontWeight='bold'>perdre ces quelques kilos en trop</Box> 
          et de sculpter ses pecs ou ses fesses ! Votre séance sera optimisée pour atteindre votre objectif. Elle sera adaptée à 
          votre rythme de vie et à votre niveau pour un travail en toute sécurité.
        </Typography>,
        image: `url(${RenforcementMusuclaire})`,
        speciality: getSpe('Renforcement musculaire'),
        reversed: false
    },
    {
      title: 'Cardio training',
      subTitle: '3, 2, 1 partez !',
      content: 
        <Typography color='black' variant='h5'>
          Une envie de <Box display='inline' fontWeight='bold'>footing</Box> ? Ou juste de monter les marches sans être essoufflé ? Que ce soit pour des sports spécifiques comme le foot, la danse ou 
          l’athlétisme… le cardio training est indispensable. Le cardio training ce n’est pas que courir ! 
          <Box display='inline' fontWeight='bold'>Le HIIT (hight intensity interval training)</Box>,
          <Box display='inline' fontWeight='bold'>le Tabata training</Box> et bien d’autres méthodes seront utilisées par votre coach.
        </Typography>,
        image: `url(${CardioTrianing})`,
        speciality: getSpe('Cardio training'),
        reversed: true
    },
    {
      title: 'Remise en forme',
      subTitle: 'Hop là ! On s’y met',
      content: 
        <Typography color='black' variant='h5'>
          La remise en forme est la fusion entre le <Box display='inline' fontWeight='bold'>renforcement musculaire</Box>
          et le <Box display='inline' fontWeight='bold'>cardio trainingIdéale pour les débutants</Box>. Objectif <Box display='inline' fontWeight='bold'>se sentir en forme !</Box>
        </Typography>,
        image: `url(${RemiseEnForme})`,
        speciality: getSpe('Cardio boxing'),
        reversed: false
    }
  ];

  return (
    <Box>
      <Box sx={{
        background: `url(${Fond})`,
        backgroundRepeat: "no-repeat", backgroundSize:'cover',
        mt: 10, pt: 15, pb: 10
      }}>
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', mx: {xs: '10%', sm: '20%'}}}>
        <Box sx={{
          backgroundColor: '#104f5c'}}>
            <Grid container>
              { matchDownXs && <Grid item xs={12}>
                <Box display='flex' flexDirection='column' justifyContent='start' alignItems='start' mt={5} ml={8}>
                  <Typography variant='h2' color='white' fontWeight='bold' letterSpacing={12}>
                  FITNESS
                  </Typography> 
                  </Box>
                </Grid>}
              <Grid item lg={5} md={12} sx={{
                background: url,
                backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
              }}>
                <Box m={8}>
                  <ReactPlayer
                    url='video_cardiotraining.m4v'
                    width='100%'
                    playing
                    height='100%'
                    light={<img src='video_cardiotraining.jpg' style={{width: '100%', height: '100%'}}/>}
                    />
                  </Box>
              </Grid>
              <Grid item lg={7} md={12}>
                <Box display='flex' flexDirection='column' justifyContent='start' alignItems='start' my={5} ml={5} mr={15}>
                  { !matchDownXs && <Typography variant='h2' color='white' fontWeight='bold' letterSpacing={12}>
                    FITNESS
                  </Typography> }
                  <Typography variant='h3' color='white' sx={{
                    mt: {
                      md: 10,
                      xs: 0
                    }, mb: 2
                  }}>
                    BÉNÉFICES
                  </Typography>
                  <hr style={{border: '1px solid white', height: '3px', width: '100%', background: 'white'}}/>
                  <Grid container>
                   {dots.map(s => 
                   <>
                      <Grid item xs={2}>
                        <div style={bubbleStyle}/>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant='h5' fontWeight='bold' color='white'>
                        {s}
                        </Typography>
                      </Grid>
                    </>
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box sx={{background: '#1a1a1a', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Typography variant='h5' my={2} color='white' mx={5}>
          Renforcement musculaire • Cardio training • Remise en forme
          </Typography>
      </Box>

      {
        cours
             .map(c => 
              <CoursInfos 
                title={c.title} 
                subTitle={c.subTitle} 
                content={c.content} 
                speciality={c.speciality}
                reversed={c.reversed}
                image={c.image} />
              )
      }

      {
        infos.map((m, i) => (
          <HorizontalText
            title={m.title}
            content={m.subTitle}
            reversed={i % 2 == 1}
            />
        ))
      }

      <Box style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <Typography color='black' variant="h5" fontWeight='bold' sx={{width: '80%', pt: 10, pb: 10}} align='center'>
        Rejoignez-nous dès aujourd'hui et profitez d'un coaching en boxe personnalisé avec Hoplà. Améliorez
      votre technique, renforcez votre corps et repoussez vos limites. Réservez vos séances dès maintenant
      et faites un pas vers une pratique de boxe
        </Typography>
      </Box>

      <Box sx={{backgroundColor: '#104f5c'}}>
        <Grid container sx={{pt: 10, pb: 10}}>
          <Grid item xs={12} sm={5}>
            <Box sx={{
              display: 'flex',
              justifyContent: {
                md: 'flex-end',
                xs: 'center'
              }
            }}>

            <Button variant='contained' size='large' 
                style={{
                  textTransform: 'none',
                  margin: '10px',
                  maxHeight: '50px', minWidth: '240px',
                  minHeight: '50px', borderRadius: 20, fontSize: '1.6em'}}>
                Réserver une séance
              </Button>
            </Box>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={12} sm={5}>
            <Box sx={{
              display: 'flex',
              justifyContent: {
                md: 'flex-start',
                xs: 'center'
              }
            }}>

            <Button variant='contained' size='large' 
                style={{
                  textTransform: 'none',
                  margin: '10px',
                  maxHeight: '50px', minWidth: '240px', 
                  minHeight: '50px', borderRadius: 20, fontSize: '1.6em'}}>
               Nous contacter
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
