import { Location } from "./Location";

export interface Client {
    id: number;
    firstname: string;
    lastname: string;
    phoneNumber: string;
}

export interface ClientPrivate {
    client: Client;
    email: string;
    address: Location;
    canAI: boolean;
}

export enum UpdateUserResult {
    OK, BadImage, BadLocation, Error
}