import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { LoggedType } from "../../slices/loginSlice";
import { Box, Button, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import HoplaTheme from "../../theme";
import { useHistory } from "react-router";

const title = {
  color: '#ff6b2b',
  fontWeight: 'bold'
}

const text = {
  color: 'black'
}

const box = {
  justifyContent: 'center',
  alignItems: 'center',
  padding: '30px 0px'
}

const hrContainer = {
  display :'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const hrStyle = {
  width: '90%',
  marginLeft: '10%',
  height: '4px',
  color: 'black',
  backgroundColor: 'black'
}

const hrLStyle = {
  width: '90%',
  marginRight: '10%',
  height: '4px',
  color: 'black',
  backgroundColor: 'black'
}

const MainClientFooter: React.FC = () => {
    const state = useSelector((s: RootState) => s).login.loggedType !== LoggedType.Coach;
    const matchDownMd = useMediaQuery(HoplaTheme.breakpoints.down("sm"));
    const history = useHistory();

    return state ? (
      <Box>
        <Grid container>
          <Grid item xs={12} sm={7}>
          <Box sx={{...box, margin: {
              sm: '0% 5% 0% 20%',
              xs: '0% 10%'
            }}}>
                <Typography style={title} variant="h4">
                  Hop Là Coaching : vos séances de coaching fitness en Île-de-France
                </Typography>
                <Typography style={text} variant="h5">
                Vous recherchez un service de coaching complet et personnalisé en fitness et renforcement musculaire ?
                Hoplà Coaching est la plateforme idéale pour réserver vos séances de fitness, cardio training, boxe et bien
                plus encore. Profitez d'un accompagnement professionnel et d'un large choix d'activités pour atteindre vos
                objectifs de remise en forme. Réservez dès maintenant vos séances et découvrez une nouvelle façon de
                prendre soin de votre corps et de votre bien-être.
                </Typography>
            </Box>
          </Grid>

          { !matchDownMd && <Grid item xs={5} sx={hrContainer}>
            <hr style={hrStyle}/>
          </Grid> }
              
          { !matchDownMd && <Grid item xs={5} sx={hrContainer}>
            <hr style={hrStyle}/>
          </Grid> }
            
          <Grid item xs={12} sm={7}>
          <Box sx={{...box, margin: {
              sm: '0% 20% 0% 5%',
              xs: '0% 10%'
            }}}>
            <Typography style={title} variant="h4">
                Des séances adaptées à vos besoins
                </Typography>
                <Typography style={text} variant="h5">
                Nos coachs expérimentés vous proposent des séances de fitness personnalisées selon vos objectifs et
votre niveau. Que vous souhaitiez perdre du poids, gagner en muscle ou améliorer votre condition physique, nous avons des programmes adaptés à tous. Vous pourrez également profiter de séances de boxe,
cardio boxing, pilates, yoga et fitness pré et post natal pour répondre à vos besoins spécifiques.
                </Typography>
              </Box>
          </Grid>

          <Grid item xs={12} sm={7}>
          <Box sx={{...box, margin: {
              sm: '0% 5% 0% 20%',
              xs: '0% 10%'
            }}}>
            <Typography style={title} variant="h4">
                Profitez des nombreux avantages
                </Typography>
                <Typography style={text} variant="h5">
                  <ul>
                <li>Une équipe de coachs expérimentés : bénéficiez d'un encadrement professionnel pour des résultats optimaux</li>
                <li>Une varieté d'activités : explorez différentes disciplines pour diversifier vos entraînements</li>
                <li>Des séances flexibles : choisissez les horaires qui vous conviennent le mieux</li>
              </ul>
                </Typography>
            </Box>
          </Grid>

          { !matchDownMd && <Grid item xs={5} sx={hrContainer}>
            <hr style={hrStyle}/>
          </Grid> }

        </Grid>
        <Box style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Typography color='black' variant="h5" fontWeight='bold' sx={{width: '60%', pt: 10, pb: 10}} align='center'>
          Transformez votre corps et votre vie avec Hop Là Coaching. Réservez vos séances dès maintenant
et découvrez une nouvelle approche du fitness et du bien-être en Île-de-France.
          </Typography>
        </Box>

        
        <Box sx={{backgroundColor: '#104f5c'}}>
          <Grid container sx={{pt: 10, pb: 10}}>
          <Grid item xs={2} />
            <Grid item xs={12} sm={3}>
              <Box sx={{
                display: 'flex',
                justifyContent: {
                  md: 'flex-end',
                  xs: 'center'
                }
              }}>

              <Button variant='contained' size='large' 
                  style={{
                    textTransform: 'none',
                    margin: '10px',
                    maxHeight: '60px', minWidth: '250px',
                    minHeight: '60px', borderRadius: 20, fontSize: '1.6em'}}
                    onClick={() => window.open('mailto:contact@hoplacoaching.fr')}>
                  Nous contacter
                </Button>
              </Box>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={12} sm={3}>
              <Box sx={{
                display: 'flex',
                justifyContent: {
                  md: 'flex-start',
                  xs: 'center'
                }
              }}>

              <Button variant='contained' size='large' 
                  style={{
                    textTransform: 'none',
                    margin: '10px',
                    maxHeight: '60px', minWidth: '250px', 
                    minHeight: '60px', borderRadius: 20, fontSize: '1.6em'}}
                    onClick={() => history.push('/acces-membre/coach')}>
                 Devenir coach Hop Là
                </Button>
              </Box>
            </Grid>
            <Grid item xs={2} />
          </Grid>
        </Box>
      </Box>
    ) : <div/>;
}

export default MainClientFooter;