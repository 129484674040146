import axios from 'axios';
import { HOPLA_URL } from '../config/config';
import { CreateClientResult, CreateRequest, LoginResult } from '../models/Login';

export const loginUser = async (email: string, password: string) : Promise<LoginResult> => {
    return (await axios.post(HOPLA_URL + '/login/loginUser', {email, password})).data;
}

export const loginCoach = async (email: string, password: string) : Promise<LoginResult> => {
    return (await axios.post(HOPLA_URL + '/login/loginCoach', {email, password})).data;
}

export const createUser = async (request: CreateRequest) : Promise<CreateClientResult> => {
    return (await axios.post(HOPLA_URL + '/login/createUser', request)).data;
}

export const createCoach = async (request: CreateRequest) : Promise<CreateClientResult> => {
    return (await axios.post(HOPLA_URL + '/login/createCoach', request)).data;
}

export const relogUser = async (id: number, token: string) : Promise<LoginResult> => {
    return (await axios.post(HOPLA_URL + '/login/relogUser', {id, token})).data;
}

export const relogCoach = async (id: number, token: string) : Promise<LoginResult> => {
    return (await axios.post(HOPLA_URL + '/login/relogCoach', {id, token})).data;
}

export const changePassword = async (old: string, newPass: string, confirm: string) : Promise<LoginResult> => {
    return (await axios.post(HOPLA_URL + '/login/changePassword',
    {
        newPassword: newPass,
        oldPassword: old,
        confirmation: confirm
    })).data;
}

export const forgotPassword = async(email: string, firstname: string, isClient: boolean) => {
    return (await axios.post(HOPLA_URL + '/login/forgotPassword', {email, firstname, isClient})).data;
}