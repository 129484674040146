import { IonLabel } from "@ionic/react";
import { Typography } from "@mui/material";
import { useState } from "react";
import { PlanningSlot, PlanningState } from "../models/Planning";
import { updatePlanningSlot } from "../services/planningService";

export interface PlanningItemProps {
    slot?: PlanningSlot;
    date: Date;
    onChange : (date: Date, added: PlanningSlot[]) => void;
    isTop: boolean;
    className: string;
    onClick: (slot: PlanningSlot | undefined, anchor: any) => void;
}

export const getColor = (s: PlanningSlot | undefined) => {
    switch (s?.state ?? PlanningState.Null) {
        case PlanningState.Reserving:
        case PlanningState.Reserved: return '#FF6104';
        case PlanningState.Done:
        case PlanningState.Validated: return '#8C6AD4';
        case PlanningState.Empty: return '#124F5C';
        // default: return 'white';
    }
}

export const PlanningItem : React.FC<PlanningItemProps> = ({slot, date, onChange, isTop, className, onClick}: PlanningItemProps) => {
    const enabled = date.getTime() - new Date().getTime() > 0;
    const color = enabled ? getColor(slot) : 'gray';
    const [busy, setBusy] = useState(false);

    const handleClick = async (element: any) => {
        if (!enabled) return;
        if (slot != undefined && (slot.state != PlanningState.Empty && slot.state != PlanningState.Null)) {
            onClick(slot, element);
            return;
        };

        if (busy) return;

        setBusy(true);

        try {
            const result = await updatePlanningSlot(date, (slot?.state ?? PlanningState.Null) == PlanningState.Null ? PlanningState.Empty : PlanningState.Null);

            if (result.success) {
                onChange(date, result.slots);
            }
        } catch {

        }

        setBusy(false);
    }

    return (
        <div style={{backgroundColor: color, height: '100%', width: '100%'}} onClick={e => handleClick(e.currentTarget)} className={className}>
            {/* {
                slot === undefined || !slot.speciality ? '' : (
                    isTop ?
                    (
                        <div style={{display: 'flex', justifyContent:'center'}}>
                            <Typography >{slot.clientName} ({slot.location.placeId})</Typography><br/>
                        </div>
                    ) : 
                    (
                        <div style={{display: 'flex', justifyContent:'center'}}>
                            <Typography>{slot.speciality.name}</Typography>  
                        </div>
                    )
                )
            } */}
        </div>
    );
}