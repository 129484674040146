import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { FC, ReactNode } from "react";
import { confirmAlert } from "react-confirm-alert";
import classes from "../style";
import "../css/popupService.css";
import CloseIcon from "@mui/icons-material/Close";
import { Provider } from "react-redux";
import store from "../store";

export const showMessage = (message: string) =>
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="custom-ui">
          <Dialog
            open={true}
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            maxWidth="xs"
            sx={classes.popupForm}
            scroll="body"
          >
            <div className="wrapper">
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={classes.closeBtn}
              >
                <CloseIcon />
              </IconButton>
              <Box sx={{ p: 3 }}>{message}</Box>
            </div>
          </Dialog>
        </div>
      );
    },
  });

export const badPasswordPopup = () => 
  showModal(
    (
      <Box>
        <Typography>Votre mot de passe doit avoir le format suivant:</Typography>
        <h4>- 8 caractères minimum</h4>
        <h4>- Au moins une majuscule</h4>
        <h4>- Au moins une minuscule</h4>
      </Box>
    ), 'Mauvais mot de passe');

export const needConfirmation = (
  title: string,
  validate: () => void,
  refuse: () => void,
  yes?: string,
  no?: string
) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="custom-ui">
          <Dialog
            open={true}
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            disableEscapeKeyDown
            maxWidth="xs"
            sx={classes.popupForm}
            scroll="body"
          >
            <div className="wrapper">
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={classes.closeBtn}
              >
                <CloseIcon />
              </IconButton>
              <DialogTitle
                id="customized-dialog-title"
                sx={{
                  "&.MuiTypography-h6": {
                    textAlign: "center",
                                    fontSize: "16px",
                  },
                }}
              >
                {title}
              </DialogTitle>
              <DialogActions sx={{ justifyContent: "center" }}>
                <Button
                  autoFocus
                  onClick={() => {
                    refuse();
                    onClose();
                  }}
                  variant="outlined"
                  color="primary"
                >
                  {no ?? "NON"}
                </Button>
                <Button
                  autoFocus
                  onClick={() => {
                    validate();
                    onClose();
                  }}
                  variant="contained"
                  color="primary"
                >
                  {yes ?? "OUI"}
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </div>
      );
    },
  });
};

export const showModal = (
  component: ReactNode | FC,
  title: string,
  withBorder?: boolean
) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="custom-ui">
          <Dialog
            open={true}
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            maxWidth="xs"
            sx={[classes.popupForm]}
            scroll="body"
          >
            <div className="wrapper">
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={classes.closeBtn}
              >
                <CloseIcon />
              </IconButton>
              <DialogTitle
                id="customized-dialog-title"
                sx={{
                  "&.MuiTypography-h6": {
                    textAlign: "center",
                                    fontSize: "20px",
                  },
                }}
              >
                {title}
              </DialogTitle>
              <Box className="content">
                <Provider store={store}>{component}</Provider>
              </Box>
            </div>
          </Dialog>
        </div>
      );
    },
  });
};
