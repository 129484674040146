import { useEffect, useState } from "react";
import { PlanningItem } from "../components/PlanningItem";
import { PlanningSlot, PlanningState } from "../models/Planning";
import {
  addDays,
  areDateEqual,
  firstDayOfWeek,
  getAllPossibleDisposDate,
  getSimpleDate,
  isSameDay,
} from "../utils/dateUtils";
import "../components/PlanningItem.css";
import { getWeekSlots, updateMultiplePlanningSlots } from "../services/planningService";import {
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { PlanningSlotViewer } from "../components/PlanningSlotViewer";
import classes from "../style";

enum CheckType {
  None = 0, Empty, Null
}

const defaultSlot = {
  coachId: 0,
  clientId: 0,
  date: new Date(2000, 1, 1),
  state: PlanningState.Null,
  speciality: {id: 0, name: '', enabled: true},
  location: {placeId: '', description: ''},
  clientName: ''
} as PlanningSlot;


export const CoachPlanning: React.FC<{
  needUpdate: number;
  triggerUpdate: () => void;
}> = (props) => {
  const [workDay, setWorkDay] = useState<Date>(firstDayOfWeek(new Date()));
  const toWorkDay = addDays(workDay, 6);

  const [anchor, setAnchor] = useState<any>(undefined);
  const [slots, setSlots] = useState<PlanningSlot[]>([]);

  const [openedSlot, setOpenedSlot] = useState<PlanningSlot | undefined>(
    undefined
  );

  const canSetWeek = (next: boolean): boolean => {
    let target = addDays(workDay, 7 * (next ? 1 : -1));
    target = new Date(
      target.getFullYear(),
      target.getMonth(),
      target.getDate(),
      0,
      0,
      0
    );

    let now = firstDayOfWeek(new Date());
    now = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);

    const dist =
      (new Date(target).getTime() - new Date(now).getTime()) /
      (1000 * 60 * 60 * 24);
    return dist >= 0 && dist <= 15;
  };

  const setWeek = (next: boolean) => {
    if (!canSetWeek(next)) return;

    let target = addDays(workDay, 7 * (next ? 1 : -1));
    target = new Date(
      target.getFullYear(),
      target.getMonth(),
      target.getDate(),
      0,
      0,
      0
    );
    setWorkDay(target);
  };

  const getSlot = (date: Date, i: number) => {
    const day = addDays(date, i);
    const filter = slots
      .filter((d) => isOverlapping(d.date, day))
      .sort((a, b) => b.state - a.state);

    return filter.length > 0 ? filter[0] : undefined;
  };

  const onPlanningChange = (
    date: Date,
    added: PlanningSlot[]
  ) => {
    if (added.length > 1) {
      setSlots([...slots, ...added].filter((value, index, self) => self.indexOf(self.find(t => t.date === value.date) ?? defaultSlot) === index));
    } else if (added.length === 1 && slots.filter((slot) => new Date(slot.date) == new Date(added[0].date)).length > 0) {
      setSlots(
        slots.map((slot) => {
          if (slot.date == added[0].date) {
            slot.state = added[0].state;
          }

          return slot;
        })
      );
    } else if (added.length === 1) {
      setSlots([...slots, added[0]]);
    } else {
      setSlots([
        ...slots.filter(
          (slot) => !areDateEqual(new Date(slot.date), new Date(date))
        ),
      ]);
    }
  };

  const isOverlapping = (slot: Date, date: Date) => {
    const dist =
      (new Date(slot).getTime() - new Date(date).getTime()) / (1000 * 60);
    return Math.abs(dist) <= 30 && dist <= 0;
  };

  const isTop = (slot: Date, date: Date) => {
    const found = slots
      .filter((s) => isOverlapping(s.date, date))
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    if (found.length < 1) {
      return false;
    }

    return new Date(slot).getMinutes() == new Date(date).getMinutes();
  };

  const getPosition = (date: Date) => {
    const found = slots.filter((s) => {
      const dist =
        (new Date(s.date).getTime() - new Date(date).getTime()) / (1000 * 60);
      return dist < 30 && dist >= -60;
    });

    if (found.length == 0) return 0;
    if (found.length >= 2) return 2;

    return isOverlapping(found[0].date, date)
      ? isTop(found[0].date, date)
        ? 1
        : 3
      : 0;
  };

  const getClass = (date: Date) => {
    const pos = getPosition(date);
    return "cell-class-" + pos;
  };

  const setWorkingSlot = (s: PlanningSlot | undefined, a: any) => {
    if (
      s !== undefined &&
      s.state !== PlanningState.Empty &&
      s.state !== PlanningState.Null
    ) {
      setOpenedSlot(s);
      setAnchor(a);
    }
  };

  const getCheckType = (i: number) :CheckType => {
    const day = addDays(workDay, i);

    if (day.getTime() - new Date().getTime() <= 0 || day.getDate() === new Date().getDate()) {
      return CheckType.None;
    }

    const slotsOnDay = slots.filter(s => isSameDay(new Date(s.date), day));

    if (slotsOnDay.find(s => s.state !== PlanningState.Empty && s.state !== PlanningState.Null)) {
      return CheckType.None;
    } else if (slotsOnDay.every(s => s.state === PlanningState.Empty) && slotsOnDay.length > 13) {
      return CheckType.Empty;
    }

    return CheckType.Null;
  }


  const setAllDaySlot = async (day: number) => {
    const type = getCheckType(day);
    const date = addDays(workDay, day);

    const state = type === CheckType.Empty ? PlanningState.Null : PlanningState.Empty;
    
    try {
      const r = await updateMultiplePlanningSlots(date, state);

      if (r.success) {
        if (state === PlanningState.Null) {
          setSlots([...slots.filter(s => !isSameDay(new Date(s.date), date))]);
        } else {
          setSlots([...slots, ...r.slots].filter((value, index, self) => self.indexOf(self.find(t => t.date === value.date) ?? defaultSlot) === index));
        }
      }
    } catch {

    }
  }

  const calculateDay = (i: number) => {
    const day = addDays(workDay, i);
    return day.getDate();
  }

  useEffect(() => {
    const get = async () => {
      try {
        const slots = await getWeekSlots(workDay);
        setSlots(
          slots.filter(
            (s) =>
              (s.state !== PlanningState.Empty &&
                s.state !== PlanningState.Null) ||
              new Date(s.date).getTime() >= new Date().getTime()
          )
        );
      } catch (e) {
        console.error(e);
      }
    };

    get();
  }, [props.needUpdate, workDay]);

  return (
    <Container maxWidth="xl" sx={classes.coachPlanning}>
      <Typography sx={classes.label}>Mon emploi du temps</Typography>
      <Typography variant="body1" my={2}>
      Coche les cases correspondant à tes créneaux disponibles. N’oublies pas de prendre en compte les temps de trajet.
      La case au-dessus de la journée te permet de sélectionner l’intégralité de la journée.
      Les séances annulées seront directement remises en disponible.
      </Typography>
      <Grid container spacing={2} sx={classes.planningLegend}>
        <Grid item sm={3}>
          <div
            style={{
              ...classes.planningLegendItem,
              backgroundColor: "#8C6AD4",
            }}
          ></div>
          <Typography>= Séance validée</Typography>
        </Grid>
        <Grid item sm={3}>
          <div
            style={{
              ...classes.planningLegendItem,
              backgroundColor: "#124F5C",
            }}
          ></div>
          <Typography>= Séance disponible</Typography>
        </Grid>
        <Grid item sm={3}>
          <div
            style={{
              ...classes.planningLegendItem,
              backgroundColor: "#FF6104",
            }}
          ></div>
          <Typography>= Séance à valider</Typography>
        </Grid>
        <Grid item sm={3}>
          <div
            style={{
              ...classes.planningLegendItem,
              backgroundColor: "gray",
            }}
          ></div>
          <Typography>= Séance indisponible</Typography>
        </Grid>
      </Grid>
      <Box display="flex" alignItems="center" justifyContent="center">
        {canSetWeek(false) ? (
          <Button onClick={() => setWeek(false)}>
            <Typography>{"<"}</Typography>
          </Button>
        ) : (
          ""
        )}
        <Typography
          sx={{
            ...classes.label,
            my: 1.5,
            fontSize: "16px",
            textTransform: "uppercase",
          }}
        >
          Du {getSimpleDate(workDay)} au {getSimpleDate(toWorkDay)}
        </Typography>
        {canSetWeek(true) ? (
          <Button onClick={() => setWeek(true)}>
            <Typography>{">"}</Typography>
          </Button>
        ) : (
          ""
        )}
      </Box>
      <TableContainer sx={classes.table}>
        <Table
          aria-label="simple table"
          aria-labelledby="tableTitle" 
        >
          <colgroup>
            <col width="5%" />
            <col width="13.5%" />
            <col width="13.5%" />
            <col width="13.5%" />
            <col width="13.5%" />
            <col width="13.5%" />
            <col width="13.5%" />
            <col width="13.5%" />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell className="sticky" />
              {["LUN", "MAR", "MER", "JEU", "VEN", "SAM", "DIM"].map((d, i) => (
                <TableCell>
                <div style={{ display: "flex", alignItems: "center", flexDirection: 'column' }}>
                  <Typography>{d + ' ' + calculateDay(i)}</Typography>
                  <Checkbox
                    disabled={getCheckType(i) === CheckType.None}
                    checked={getCheckType(i) === CheckType.Empty}
                    onClick={() => setAllDaySlot(i)} />
                </div>
              </TableCell>

              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {getAllPossibleDisposDate(workDay).map((day) => (
              <TableRow style={{ height: 30, borderSpacing: 0, margin: 0, padding: 0 }}>
                <TableCell className="sticky" >
                  <Typography>
                    {day.getHours()}h{day.getMinutes() !== 0 ? "30" : ""}
                  </Typography>
                </TableCell>
                {[0, 1, 2, 3, 4, 5, 6].map((d) => {
                  const slot = getSlot(day, d);
                  const slotDay = addDays(day, d);

                  return (
                    <TableCell
                      style={{ height: "40px", padding: 0 }}
                      onClick={(e) => setWorkingSlot(slot, e.currentTarget)}
                    >
                      <PlanningItem
                        onChange={onPlanningChange}
                        isTop={
                          slot != undefined ? isTop(slot.date, slotDay) : false
                        }
                        slot={slot}
                        date={slotDay}
                        className={getClass(addDays(day, d))}
                        onClick={(s, a) => setWorkingSlot(s, a)}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Popover
        anchorEl={anchor}
        open={anchor !== undefined && openedSlot !== undefined}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => setAnchor(undefined)}
      >
        {openedSlot !== undefined ? (
          <PlanningSlotViewer
            onClose={() => setAnchor(undefined)}
            slot={openedSlot}
            updated={(e) => props.triggerUpdate()}
          />
        ) : (
          ""
        )}
      </Popover>
    </Container>
  );
};

export default CoachPlanning;
