import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Speciality } from '../models/Speciality'

export interface CoachState {
  specialities: Speciality[]
}

const initialState: CoachState = {
  specialities: []
}

export const coachSlice = createSlice({
  name: 'loginSlice',
  initialState,
  reducers: {
    LOAD_SPECIALITIES: (state: CoachState) => state,
    LOAD_SPECIALITIES_RESULT: (state: CoachState, action: PayloadAction<Speciality[]>) => {
      state.specialities = action.payload;
    }
  }
})

export const { 
  LOAD_SPECIALITIES,
  LOAD_SPECIALITIES_RESULT
} = coachSlice.actions

export default coachSlice.reducer