import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import BonPlan from "../../images/Fond_bonPlan.jpg";
import Macaron from '../../images/svg/Macaron.svg';
import { useHistory } from "react-router";
import HoplaTheme from "../../theme";

export const DefiscalisationBanner = () => {
    const history = useHistory();
    const matchDownMd = useMediaQuery(HoplaTheme.breakpoints.down("md"));

    return (
        <Box sx={{
            backgroundImage: `url(${BonPlan})`, 
            height: 'auto', 
            flexDirection: 'column',
            width: '100%',
            backgroundRepeat: "no-repeat", backgroundSize:'cover',
            display: 'flex',
            alignItems:'center', justifyContent: 'center'
          }}>
            <Box sx={{
                backgroundColor: '#104f5c', margin: '100px 0px', padding: '80px 10px',
                width: {
                    sm: '70%',
                    xs: '90%'
                }, cursor: 'pointer'}}
                onClick={() => history.push('/defiscalisation-immédiate')}>
                
                <Typography variant="h4" color='white' align='center'>
                    Que diriez-vous si on vous disait que vous pouvez
                </Typography>
                <Typography variant="h3" color='white' fontWeight='bold' align='center'>
                    DÉFISCALISER vos séances de sport
                </Typography>
                <Typography variant="h3" color='white' fontWeight='bold' align='center'>
                    et SANS faire d’avance ? *
                </Typography>
            </Box>
            <Box sx={{
                width:'65%', height: 'auto', paddingBottom: '360px'
            }}>
                <Box sx={{margin:'20px 0px'}}>
                    <Typography variant='h3' color='#ff6100' fontWeight='bold'>Le bon plan Hop Là Coaching</Typography>
                </Box>
                <Grid container>
                    <Grid item xs={12} md={8}>
                    <Box sx={{margin:{
                        md: '20px 0px',
                        xs: '10px 0px'
                    }}}>
                        <Typography variant="h5" color='black' component='span' display='inline' sx={{margin: '20px 0px'}}>
                        *Oui c’est possible dans le cadre de la défiscalisation des 
                            <Box display='inline' fontWeight='bold'> « Services à la personne » </Box>.<br/>
                        Et OUI, des séances de sports avec un coach sportif entrent dans cette catégorie !
                        </Typography>
                    </Box>
                    <Box sx={{margin:'40px 0px'}}>
                        <Typography variant="h5" color='#ff6100'>
                        Tout le monde ne le sait pas, et pourtant, tout le monde y a droit !
                        </Typography>
                    </Box>
                    <Box sx={{margin:'40px 0px'}}>
                        <Typography variant="h5" color='black'>
                        Vos séances peuvent donc être desfiscalisés, et ce 
                            <Box display='inline' fontWeight='bold'> jusqu’à 12 000€ par an </Box>!<br/>
                        Soit environ 
                            <Box display='inline' fontWeight='bold'> 141 séances </Box>
                         avec Hop Là Coaching !<br/>
                        Et ce n’est pas tout : 
                            <Box display='inline' fontWeight='bold'> vous ne faites même pas l’avance !</Box>
                        </Typography>
                    </Box>
                    <Box sx={{margin:'40px 0px'}}>
                        <Typography variant="h5" color='black' fontStyle='italic'>
                        Vous avez vu, ici, derrière les « * », se cachent de bonnes nouvelles :)
                        </Typography>
                    </Box>
                    </Grid>
                    <Grid item xs={10} md={2}>
                        <img src={Macaron} style={{marginLeft: '35%'}}/>
                    </Grid>
                </Grid>
                <Box sx={{
                    display: 'flex',
                    justifyContent: !matchDownMd ? 'flex-start' : 'center',
                    alignItems: !matchDownMd ? 'flex-start' : 'center'
                }}>
                    <Button variant='contained' size='large' 
                        style={{
                            textTransform: 'none',
                            maxWidth: '240px', maxHeight: '60px', minWidth: '240px',
                            minHeight: '60px', borderRadius: 20, fontSize: '1.3em',
                            marginTop: '20px'}}
                            onClick={() => history.push('/defiscalisation-immédiate')}>
                        En savoir plus !
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}