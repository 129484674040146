import { Checkbox, FormControl, ListItemText, MenuItem } from "@mui/material";
import Select from "@mui/material/Select";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import StateManagedSelect from "react-select/dist/declarations/src/stateManager";
import { Speciality } from "../models/Speciality";
import { RootState } from "../store";
import classes from "../style";

export interface SpecialityProps {
  onChange?: (speciality: number) => void;
  specialityId?: number;
  onMultipleChange?: (specialities: Speciality[]) => void;
  defaultMultiple?: Speciality[];
  multiple?: boolean;
}

export const SpecialitySelect: React.FC<SpecialityProps> = ({
  onChange,
  specialityId,
  onMultipleChange,
  defaultMultiple,
  multiple,
}: SpecialityProps) => {
  const specialities = useSelector(
    (state: RootState) => state.coaches.specialities
  );

  const [speciality, setSpeciality] = useState<Speciality | undefined>();

  const [selectedSpecialities, setSelectedSpecialities] = React.useState<
    number[]
  >(defaultMultiple ? defaultMultiple.map(d => d.id) : []);

  const pickSpeciality = (speciality: number) => {
    setSpeciality(specialities.find(s => s.id === speciality));

    if (onChange) {
      onChange(speciality);
    }
  };

  const setSeltedSpes = (s: number[]) => {
    setSelectedSpecialities(s);
    if (onMultipleChange) {
      onMultipleChange(specialities.filter(k => s.indexOf(k.id) >= 0));
    }
  }

  return (
    <FormControl variant="standard" sx={classes.select}>
      {multiple ? (
        <Select
          labelId="type-select"
          id="type-select"
          displayEmpty
          multiple
          value={selectedSpecialities}
          defaultValue={selectedSpecialities}
          onChange={(e: any) => setSeltedSpes(e.target.value)}
          renderValue={(selected) =>
            selectedSpecialities.length
              ? selectedSpecialities
                  .map((e) => specialities.find((elt) => elt.id === e)?.name)
                  .join(", ")
              : "Rechercher une spécialité ..."
          }
          MenuProps={{
            PaperProps: {
              sx: classes.selectMenuProps,
            },
          }}
        >
          {specialities.map((s) => (
            <MenuItem key={s.id} value={s.id}>
              <Checkbox
                sx={{ padding: "0 !important" }}
                checked={selectedSpecialities.includes(s.id)}
              />
              <ListItemText primary={s.name} />
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select
          labelId="type-select"
          id="type-select"
          defaultValue={specialityId ?? -1}
          value={specialityId ?? -1}
          onChange={(e: any) => pickSpeciality(e.target.value)}
          label="Cours"
          MenuProps={{
            PaperProps: {
              sx: classes.selectMenuProps,
            },
          }}
          sx={{
            color: "black",
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(228, 219, 233, 0.25)',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(228, 219, 233, 0.25)',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(228, 219, 233, 0.25)',
            },
            '.MuiSvgIcon-root ': {
              fill: "black !important",
            }
          }}
        >
          <MenuItem value={-1}>
            <em>Rechercher un cours ...</em>
          </MenuItem>
          {specialities?.map((s) => (
            <MenuItem key={s.id} value={s.id}>
              {s.name}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};
