import { Coach } from "./Coach";
import { Client } from "./Client";

export interface LoginResult
{
    client: Client | undefined;
    coach: Coach | undefined;
    token: string;
    result: LoginResultType;
}

export enum CreateClientType {
    OK, EmailInvalid, PasswordInvalid, NameMissing, EmailUsed, BadLocation, Error
}

export enum CreateCoachType {
    OK, EmailInvalid, PasswordInvalid, NameMissing, SiretInvalid, PhoneInvalid, PhoneUsed, SiretUsed, EmailUsed, BadLocation, Error
}

export enum LoginResultType
{
    OK, MissingCredentials, Wrong, Expired, BadLocation, Error, RelogFailed
}

export interface CreateRequest {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    placeId: string;
    placeDescription: string;
    phone: string;
    siret: string;
    shopCards: any[];
}

export interface CreateClientResult {
    client: Client;
    result: CreateClientType;
}

export interface CreateCoachResult {
    coach: Coach;
    result: CreateCoachType;
}