import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Location } from '../models/Location'

export interface LocationState {
  autocompleteLocations: Location[]
}

const initialState: LocationState = {
  autocompleteLocations: []
}

export const locationSlice = createSlice({
  name: 'locationSlice',
  initialState,
  reducers: {
    CALL_LOCATION_AUTOCOMPLETE: (state: LocationState, action: PayloadAction<{pattern: string, cities: boolean}>) => state,
    CALL_LOCATION_AUTOCOMPLETE_RESULT: (state: LocationState, action: PayloadAction<Location[]>) => {
      state.autocompleteLocations = action.payload;
    }
  }
})

export const { 
    CALL_LOCATION_AUTOCOMPLETE,
    CALL_LOCATION_AUTOCOMPLETE_RESULT
} = locationSlice.actions

export default locationSlice.reducer