import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import {
  ShoppingCard,
  ShoppingCardPacks,
  ShoppingCardSession,
} from "../../models/ShoppingCard";
import { removeCard } from "../../services/cardService";
import { LOAD_CARD, REMOVE_ITEM } from "../../slices/reservationSlice";
import { dateToString, getSimpleHour } from "../../utils/dateUtils";
import { RootState } from "../../store";
import { LoggedType } from "../../slices/loginSlice";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useEffect, useState } from "react";
import { RecapSeance } from "../RecapSeance";
import { Coach } from "../../models/Coach";
import { getCoach } from "../../services/coachService";

export interface ShopProps {
  item: ShoppingCard;
  opening: (id: number) => void;
  open: boolean;
}

export const ShopItemCard: React.FC<ShopProps> = (props) => {
  const store = useSelector((s: RootState) => s);
  const dispatch = useDispatch();

  const pack = props.item as ShoppingCardPacks;
  const session = props.item as ShoppingCardSession;

  const [coach, setCoach] = useState<Coach | undefined>(undefined);

  const remove = async (id: number, date: Date, session: boolean) => {
    try {
      if (store.login.loggedType == LoggedType.Client) {
        const r = await removeCard(id, session);

        if (r) {
          dispatch(LOAD_CARD());
        }
      } else {
        dispatch(REMOVE_ITEM({ packId: id, id, addDate: date }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const get = async() => {
      try {
        const c = await getCoach(session.coachId);
        setCoach(c.coach);
      } catch (e) {
        console.error(e);
      }
    };

    if ((session?.coachId ?? 0) != 0) {
      get();
    }
  }, []);

  return (
    <>
      {"packId" in props.item ? (
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell component="th" scope="row" />
          <TableCell component="th" scope="row">
            Pack de 10
          </TableCell>
          <TableCell component="th" scope="row">
            {store.reservation.packPrice}€
          </TableCell>
          <TableCell component="th" scope="row" align="right">
            <IconButton
              onClick={() => remove(pack.id, pack.addDate, false)}
              aria-label="delete"
            >
              <CloseIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      ) : (
        <React.Fragment>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => props.opening(props.item.id)}
              >
                {props.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell component="th" scope="row">
              Une séance de {session.specialityName} le{" "}
              {dateToString(new Date(session.sessionDate)) + ' ' + getSimpleHour(new Date(session.sessionDate))}
            </TableCell>
            <TableCell component="th" scope="row">
              <Box display="flex" justifyContent="space-between">
                <Typography>{`${store.reservation.sessionPrice}€`}</Typography>
              </Box>
            </TableCell>
            <TableCell component="th" scope="row" align="right">
              <IconButton
                onClick={() => remove(session.id, session.addDate, true)}
                aria-label="delete"
              >
                <CloseIcon />
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow className="collapse">
            <TableCell
              style={{ paddingBottom: 0, paddingTop: 0, border: 0 }}
              colSpan={6}
            >
              <Collapse in={props.open && coach !== undefined} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  {
                    coach !== undefined ?
                  
                    (<RecapSeance
                      canConfirm={false}
                      proposition={{
                        coach,
                        isFavorite: false,
                        date: session.sessionDate,
                        comments: [], lessons: 0
                      }}
                      onClose={() => {}}
                      speciality={session.specialityId}
                      location={{
                        placeId: '',
                        description: session.locationDescription
                      }}
                      price={store.reservation.sessionPrice}
                    />)
                    : ''
                  }
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      )}
    </>
  );
};
