import axios from 'axios';
import { HOPLA_URL } from '../config/config';
import { Location } from '../models/Location';

export const getAutocompleteLocation = async (pattern: string, cities: boolean) : Promise<Location[]> => {
    return (await axios.post(HOPLA_URL + '/location/locations', { pattern, cities })).data;
}

export const getCoachLocation = async (coachId: number) : Promise<Location[]> => {
    return (await axios.get(HOPLA_URL + '/coach/locations/' + coachId)).data;
}

export const addCoachLocation = async (coachId: number, location: Location) : Promise<Location> => {
    return (await axios.post(HOPLA_URL + '/coach/addLocation', location)).data;
}

export const deleteCoachLocation = async (placeId: string) : Promise<boolean> => {
    return (await axios.get(HOPLA_URL + '/coach/deleteLocation/' + placeId)).data;
}