import { useEffect, useState } from "react";
import {
  addDays,
  addHour,
  computeDayDispo,
  getSimpleDate,
  getSimpleHour,
  MAX_HOUR,
} from "../../../utils/dateUtils";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import React from "react";
import { Box, Button, Popover, TextField, Typography } from "@mui/material";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import AddIcon from "@mui/icons-material/Add";
import classes from "./style";
import "dayjs/locale/fr";

export interface Dispo {
  start: Date;
  end: Date;
  index: number;
}

export interface DispoProps {
  onChange: (d: Dispo[]) => void;
  needUpdate: number;
  getDispos: () => Dispo[];
}

export interface DispoPopoverProps extends DispoProps {
  setPopoverOpen: (b: boolean) => void;
}

export const CustomDispoPicker: React.FC<DispoProps> = (props: DispoProps) => {
  const [popoverOpen, setPopoverOpened] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [dispos, setDispos] = useState<Dispo[]>([]);

  const buttonRef: any = React.useRef();

  const [value, setValue] = React.useState<Date | null>(null);

  const handleChange = (newValue: Date | null) => {
    if (newValue) {
      setValue(new Date(newValue));
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopoverOpened(false);
    setValue(null);
  };

  const addDispo = (dispo: Dispo) => {
    if (containsDispo(dispo)) {
      setDispos([
        ...dispos.filter(
          (d) =>
            dispo.start.getFullYear() != d.start.getFullYear() ||
            dispo.start.getMonth() != d.start.getMonth() ||
            dispo.start.getDate() != d.start.getDate() ||
            dispo.start.getTime() > d.start.getTime() ||
            dispo.end.getTime() < d.end.getTime()
        ),
      ]);
    } else {
      setDispos([...dispos, dispo]);
    }
  };

  const containsDispo = (d: Dispo) => {
    return (
      dispos.filter(
        (dispo) =>
          dispo.index == d.index &&
          dispo.start.toISOString() == d.start.toISOString()
      ).length > 0
    );
  };

  const validateDispo = () => {
    props.onChange(dispos);
    setPopoverOpened(false);
  };

  const isFirst = (d: Dispo) => {
    if (dispos.length == 0) return false;

    return dispos[0].index == d.index;
  };

  let minDate = addHour(new Date(), 1);

  if (minDate.getHours() > MAX_HOUR - 1) {
    minDate = addDays(minDate, 1);
  }

  useEffect(() => {
    if (value) {
      setOpenCalendar(false);
      setAnchorEl(buttonRef.current);
      setPopoverOpened(true);
    }
  }, [value]);

  useEffect(() => {
    setDispos(props.getDispos());
  }, [props.needUpdate]);

  return (
    <>
      <Button
        ref={buttonRef}
        variant="text"
        sx={classes.dispoPickerAddBtn}
        endIcon={<AddIcon />}
        onClick={() => setOpenCalendar(!openCalendar)}
      >
        Ajouter une date
      </Button>
      <LocalizationProvider dateAdapter={AdapterDayjs} locale="fr">
        <DesktopDatePicker
          open={openCalendar}
          PaperProps={{
            sx: classes.datePicker,
          }}
          disablePast
          minDate={minDate}
          value={value}
          onChange={handleChange}
          InputAdornmentProps={{ style: { display: "none" } }}
          renderInput={(params) => (
            <TextField
              variant="standard"
              sx={classes.datePickerTextfield}
              {...params}
            />
          )}
          dayOfWeekFormatter={(day) => `${day}`}
          // componentsProps={{
          //   actionBar: {
          //     actions: ['accept'],
          //   },
          // }}
        />
      </LocalizationProvider>
      {value && (
        <Popover
          id="simple-popover"
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={classes.dispoPickerPopover}
        >
          <Box>
            <Typography>{getSimpleDate(value)}</Typography>
            <Box>
              {computeDayDispo(value)?.map((d: Dispo, i: number) => (
                <Button
                  key={i}
                  onClick={() => addDispo(d)}
                  sx={{
                    border: '1px solid',
                    borderColor: containsDispo(d) ? '#104f5c' : '#0000',
                    backgroundColor: containsDispo(d) && isFirst(d) ? '#104f5c !important' : 'white !important',
                    color: containsDispo(d) && isFirst(d) ? 'white !important' : '#104f5c !important',
                    width: "50px", height: "40px"
                  }}
                >
                  {getSimpleHour(d.start)}
                </Button>
              ))}
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Button
                onClick={() => {
                  setValue(null);
                  setOpenCalendar(true);
                }}
                variant="text"
                sx={classes.backBtn}
              >
                <ChevronLeftOutlinedIcon />
                Retour
              </Button>
              <Button
                onClick={validateDispo}
                variant="contained"
                sx={classes.submitBtn}
              >
                Valider
              </Button>
            </Box>
          </Box>
        </Popover>
      )}
    </>
  );
};

/* export const DispoPopover: React.FC<DispoPopoverProps> = (
  props: DispoPopoverProps
) => {
  const [onCalendar, setOnCalendar] = useState(true);
  const [day, setDay] = useState<Date>(firstDayOfWeek(new Date()));
  const [dispos, setDispos] = useState<Dispo[]>([]);

  const setWorkingDay = (d: Date) => {
    setDay(d);
    setOnCalendar(false);
  };

  const addDispo = (dispo: Dispo) => {
    if (containsDispo(dispo)) {
      setDispos([
        ...dispos.filter(
          (d) =>
            dispo.start.getFullYear() != d.start.getFullYear() ||
            dispo.start.getMonth() != d.start.getMonth() ||
            dispo.start.getDate() != d.start.getDate() ||
            dispo.start.getTime() > d.start.getTime() ||
            dispo.end.getTime() < d.end.getTime()
        ),
      ]);
    } else {
      setDispos([...dispos, dispo]);
    }
  };

  const containsDispo = (d: Dispo) => {
    return (
      dispos.filter(
        (dispo) =>
          dispo.index == d.index &&
          dispo.start.toISOString() == d.start.toISOString()
      ).length > 0
    );
  };

  const isDateValid = (date: string) => {
    var d = new Date(date);
    return true;
  };

  const onSelected = (a: any) => {
    setWorkingDay(new Date(a));
  };

  const validateDispo = () => {
    props.onChange(dispos);
    props.setPopoverOpen(false);
  };

  const isFirst = (d: Dispo) => {
    if (dispos.length == 0) return false;

    return dispos[0].index == d.index;
  };

  return (
    <IonContent>
      {onCalendar ? (
        <IonDatetime
          style={{ "max-width": "500px", width: "500px" }}
          isDateEnabled={isDateValid}
          presentation="date"
          onIonChange={(e) => onSelected(e.detail.value)}
        ></IonDatetime>
      ) : (
        <>
          <IonContent>
            <IonLabel>{getSimpleDate(day)}</IonLabel>
            <p />
            {computeDayDispo(day)?.map((d: Dispo, i: number) => (
              <IonButton
                shape="round"
                key={i}
                onClick={() => addDispo(d)}
                color={
                  containsDispo(d)
                    ? isFirst(d)
                      ? "secondary"
                      : "primary"
                    : "tertiary"
                }
                style={{ width: "50px", height: "50px" }}
              >
                <IonLabel>{getSimpleHour(d.start)}</IonLabel>
              </IonButton>
            ))}
            <p />
            <IonButton onClick={validateDispo}>OK</IonButton>
          </IonContent>
        </>
      )}
    </IonContent>
  );
};
 */
