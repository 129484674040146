import { Dispo } from "../components/DispoPicker";

export const MIN_HOUR = 6;
export const MAX_HOUR = 22;
export const MIN_DIFF = 30;

export const dateToString = (date: Date) => {
    return date.toLocaleDateString();
}

export const getAllPossibleDisposDate = (day: Date) => {
    const dates: Date[] = [];

    for (let i = MIN_HOUR; i < MAX_HOUR; i++)
    {
        dates.push(new Date(day.getFullYear(), day.getMonth(), day.getDate(), i, 0));
        dates.push(new Date(day.getFullYear(), day.getMonth(), day.getDate(), i, MIN_DIFF));
    }

    return dates;
}

export const dispoToString = (dispo: Dispo) => {
    const year = dispo.start.getFullYear();

    const start = dispo.start.getHours() + ":" + dispo.start.getMinutes().toString().padEnd(2, '0');
    const end = dispo.end.getHours() + ":" + dispo.end.getMinutes().toString().padEnd(2, '0');

    return getSimpleDate(dispo.start) + " " + getMonth(dispo.start) + " " + year + " - " + start + " / " + end;
}

export const addHour = (date: Date, hours: number) => {
    return new Date(new Date(date).setHours(date.getHours() + hours))
}

export const addDays = (date: Date, days: number) => {
    return new Date(new Date(date).setDate(date.getDate() + days))
}

export const firstDayOfWeek = (d: Date) => {
    var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6:1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

export const getWeekDay = (start: Date): Date[] => {
    return [0, 1, 2, 3, 4, 5, 6].map(s => {
        let r = new Date(start);
        r.setDate(r.getDate() + s);
        return r;
    });
}

export const computeDayDispo = (day: Date): Dispo[] => {
    const dates: Date[] = [];

    const min = isSameDay(day, new Date()) ? new Date().getHours() + 1 : MIN_HOUR;

    for (let i = min; i < MAX_HOUR - 1; i++)
    {
        dates.push(new Date(day.getFullYear(), day.getMonth(), day.getDate(), i, 0));
        dates.push(new Date(day.getFullYear(), day.getMonth(), day.getDate(), i, MIN_DIFF));
    }

    dates.push(new Date(day.getFullYear(), day.getMonth(), day.getDate(), MAX_HOUR - 1, 0));

    return dates.map((d, i) => {
        return {
            start: d,
            end: addHour(d, 1),
            index: i
        }
    });
}

export const mergeByDay = (dispos: Dispo[]): {[date: string] : Dispo[]} => {
    if (dispos.length === 0) return {};

    var dics : {[date: string] : Dispo[]} = {};
    for (let i in dispos) {
        const key = getSimpleDate(dispos[i].start);
        
        if (dics[key] === undefined) {
            dics[key] = [];
        }

        dics[key].push(dispos[i]);
    }

    return dics;
};

export const getSimpleDate = (d: Date) => {
    let x = d.getDay() - 1;
    if (x < 0) x += 7;

    return ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'][x] + ' ' + d.getDate() + ' ' + getMonth(d) + ' ' + d.getFullYear();
}

export const getSimpleHour = (d: Date) => {
    return d.getHours() + 'h' + d.getMinutes().toString().padEnd(2, '0');
}

export const getMonth = (d:Date) => {
    return ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'][d.getMonth()];
}

export const mergeDispos = (dispos: Dispo[]) => {
    if (dispos.length === 0) return dispos;

    const disp = [...dispos.sort((a, b) => a.start.getFullYear() - b.start.getFullYear())
                           .sort((a, b) => a.start.getMonth() - b.start.getFullYear())
                           .sort((a, b) => a.start.getDate() - b.start.getFullYear())
                           .sort((a, b) => a.start.getHours() - b.start.getFullYear())
                           .sort((a, b) => a.start.getMinutes() - b.start.getFullYear())
                           .sort((a, b) => a.index - b.index)];

    const result : Dispo[] = [];

    let current: Dispo = {...disp[0]};

    for (let i = 1; i < disp.length; i++)
    {
        let next = disp[i];

        if (current.start.getDate() === next.start.getDate()
            && current.start.getMonth() === next.start.getMonth()
            && current.start.getFullYear() === next.start.getFullYear()
            && current.index === next.index - 1)
        {
            current = {start: current.start, end: next.end, index: next.index};
        }
        else {
            result.push(current);
            current = next;
        }
    }

    result.push(current);
    return result;
}

export const areDateEqual = (a: Date, b: Date) => {
    return a.getFullYear() === b.getFullYear()
        && a.getMonth() === b.getMonth()
        && a.getDate() === b.getDate()
        && a.getHours() === b.getHours()
        && a.getMinutes() === b.getMinutes();
}

export const isSameDay = (a: Date, b:Date) => {
    return a.getFullYear() === b.getFullYear()
    && a.getMonth() === b.getMonth()
    && a.getDate() === b.getDate();
}