import { Container } from "@mui/material";
import React, { FC, ReactNode } from "react";
import { useLocation } from "react-router";
import Footer from "./footer";
import Header from "./header";
import classes from "./style";
import MainClientFooter from "./mainClientFooter";

interface LayoutProps {
  children: ReactNode;
  footer?: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children, footer }: LayoutProps) => {
  const location = useLocation().pathname;
  const pageWithWaveBackground =
    //location === "/" ||
    location.startsWith("/coach") ||
    location.startsWith("/client");

  return (
    <Container disableGutters maxWidth={false}>
     <Header />
      <Container
        disableGutters
        maxWidth={false}
        sx={classes.mainContainer}
        className={pageWithWaveBackground ? "pageWithWaveBackground" : ""}
      >
        {children}
      </Container>
      { location === "" || location === "/" ? (<MainClientFooter />) : ''}
      { location !== "/acces-membre" ? <Footer /> : ""}
    </Container>
  );
};

export default Layout;
