import { Box, Container, Typography } from "@mui/material";
import classes from "../style";

export const MentionsLegales: React.FC = () => {
  return (
    <Box sx={classes.defiscalisation}>
      <Container maxWidth="xl">
        <Typography variant="h2">
          Mentions légales
        </Typography>
        <Box className="introduction">
          <Typography></Typography>
          <Typography variant="body1">
           
            Le site www.hoplacoaching.fr est édité par la société E15, société par actions simplifiée
            unipersonnelle de droit français au capital social de 500 euros, immatriculée au RCS de
            Bobigny sous le numéro 918 422 197 - Numéro de TVA intracommunautaire :<p/>

            FR33918422197 - et dont le siège social se situe 128 avenue du docteur David Rosenfeld
            93230 Romainville<p/>
            Contact :  contact@hoplacoaching.fr ; 09 74 59 40 80<p/>
            Le site est hébergé par la société OVH SAS au capital de 10 174 560 € RCS Lille<p/>
            Métropole 424 761 419 00045<p/>
            Code APE 2620Z<p/>
            N° TVA : FR 22 424 761 419<p/>
            Siège social : 2 rue Kellermann - 59100 Roubaix - France<p/>
            OVH SAS est une filiale de la société OVH Groupe SA, société immatriculée au RCS de
            Lille sous le numéro 537 407 926 sise 2, rue Kellermann, 59100 Roubaix.<p/>

            La société E15, société par actions simplifiée unipersonnelle de droit français au capital social
            de 500 euros, immatriculée au RCS de Bobigny sous le numéro 918 422 197 - Numéro de
            TVA intracommunautaire : FR33918422197 - et dont le siège social se situe 128 avenue du
            docteur David Rosenfeld 93230 Romainville, opère à titre exclusif des activités de services à
            la personne en conformité avec sa déclaration NOVA auprès de la DGE (DDETS 75) ainsi
            qu’à la règlementation applicable aux services à la personne, notamment l’arrêté du 17 mars
            2015 relatif à l'information préalable du consommateur sur les prestations de services à la
            personne et les articles D. 7231-1 et suivants du Code du travail.

          </Typography>
        </Box>
      </Container>
    </Box>
  );
};
