import { Box, Container, Typography } from "@mui/material";
import classes from "../style";

export const CgvPage: React.FC = () => {
  return (
    <Box sx={classes.defiscalisation}>
      <Container maxWidth="xl">
        <Typography variant="h2">
          CONDITIONS GÉNÉRALES DE VENTE Hop là!
        </Typography>
        <Box className="introduction">
          <Typography variant="body1"/>
          <Typography variant="body1">
          <h1>Définitions</h1>
« Client » désigne une personne physique consommateur et majeur à qui Hoplà! fournit une prestation de services d’encadrement et
d’accompagnement dans le cadre d’activités sportives, physiques et de bien-être.<p/>
« Client Internet » désigne un Client qui a conclu un Contrat avec Hoplà! sur le Site.<p/>
« Contrat » désigne le contrat entre le Client et Hoplà! qui est régi par le bulletin d’inscription (sur support écrit ou sur le Site), les conditions
générales de vente aux particuliers de Hoplà! (telles que mises à jour ou modifiées) et la fiche d’informations et de décharge de responsabilité
annexée au bulletin d’inscription. Les stipulations des présentes conditions générales prévaudront en cas de contradiction avec tout autre
document contractuel de Hoplà! ou du Client.<p/>
« Intervenants » désigne les professionnels (coachs sportifs), salarié ou non-salariés, désignés par Hoplà! pour réaliser les prestations de services
dans le cadre d’un Contrat.<p/>
« Hoplà! » désigne la société par action simplifiée E15, dont le siège social est 128 avenue du docteur David Rosenfeld 93230 Romainville,
immatriculée au Registre du Commerce et des Sociétés de Bobigny sous le n° 918 422 197.<p/>

« Site » désigne le site Internet exploité par Hoplà! dont l’adresse est  www.hoplacoaching.fr  ou à toute autre adresse qui pourrait lui être substituée.<p/>

<h1>Objet du Contrat</h1>
Le Contrat détaille les droits et obligations respectifs de Hoplà! et de ses Clients dans le cadre de la vente et la réalisation, par Hoplà!, de prestations
de services d’encadrement et d’accompagnement dans le cadre d’activités sportives, physiques et de bien-être.

<h1>Conditions générales de vente aux particuliers</h1>

Ces conditions générales de vente détaillent les droits et obligations de Hoplà! et de ses Clients dans le cadre des prestations de services fournies
par Hoplà!. Ces conditions générales de vente s’appliquent à tous les contrats conclus avec les Clients (y compris sur le Site).<p/>
Ces conditions générales de vente s’adressent uniquement aux particuliers et les professionnels doivent contacter directement Hoplà! afin de
conclure, le cas échéant, un contrat adapté.<p/>
Toute prestation accomplie par Hoplà! implique l’adhésion sans réserve du Client aux présentes conditions générales de vente.<p/>
Le Client reconnait avoir pris connaissance des présentes conditions générales de vente et déclare expressément les accepter intégralement et sans
réserve dès lors qu’il signe le bulletin d’inscription ou s’inscrit sur le Site.<p/>
Toute condition contraire aux conditions générales de vente sera, à défaut d’acceptation expresse, inopposable à Hoplà! quel que soit le moment
où elle aura pu être portée à sa connaissance.<p/>
Hoplà! met régulièrement à jour les conditions générales de vente afin de les faire correspondre au mieux aux attentes des Clients ainsi qu’à la
législation en vigueur. Les conditions générales de vente qui s’appliqueront au Contrat du Client seront celles accessibles sur le Site. Ces dernières

seront considérées comme ayant été approuvées par le Client, sans indication contraire communiquée par le Client dans un délai de deux (2)
semaines.<p/>
Le Client dispose de la faculté de reproduire les conditions générales de vente accessibles sur le Site.<p/>

<h1>Prestations</h1>
Hoplà! propose à ses Client des séances d’encadrement et d’accompagnement dans le cadre d’activités sportives, physiques et de bien-être à
domicile et en entreprise. Les prestations de services de Hoplà! n’ont aucune vocation médicale et ne se substituent en aucun cas à un traitement
médical.<p/>
Toute séance comprend l’intervention d’un Intervenant professionnel expérimenté et qualifié dans sa spécialité au domicile, au bureau ou sur tout
autre lieu du rendez-vous en cas de séance à l’extérieur.<p/>
Les Intervenants assurent l’animation des séances conformément aux règles de l’art et y apportent tout le soin, le professionnalisme, la disponibilité
et la diligence nécessaire.<p/>
Sauf indication contraire, une séance correspond à une heure (1) d’activité effective et la préparation de l’Intervenant et du Client, ainsi que
l’installation et le rangement du matériel ne sont pas compris dans cette heure.<p/>

<h1>Organisation et Planning</h1>
L’Intervenant désigné par Hoplà! est le point de contact privilégié du Client pour le planning et l’organisation de ses séances.
La réservation des séances s’effectue directement auprès de l’Intervenant.<p/>
Le lieu des séances doit être préalablement approuvé par l’Intervenant.<p/>
Dans le cas d’une séance collective, les participants doivent impérativement avoir des objectifs communs.<p/>
<h1>Retard, absence annulation et suspension</h1>

Les Clients sont tenus de respecter les rendez-vous et les horaires préalablement définis. Tout retard du Client sera automatiquement décompté
du temps de la séance.<p/>
En cas de retard de l’Intervenant, celui-ci doit rattraper le temps perdu en fin de séance ou sur la séance suivante. En cas d’absence non prévue de
l’Intervenant, celui se doit de rattraper la séance.<p/>
<b>Règlement annulation séance unitaire</b><p/>
Annulation plus de 24 heures avant le rendez-vous prévus : aucun frais d’annulation<p/>
Annulation moins de 24 heures avant le rendez-vous prévus : Le montant de la prestation sera facturée<p/><p/>
<b>Règlement annulation « pack séances »</b><p/>

Annulation plus de 24 heures avant le rendez-vous prévus : la séance ne sera pas décompté et l’unité recreditée<p/>
Annulation moins de 24 heures avant le rendez-vous prévus : La séance sera décompté et l’unité crédité<p/><p/>
Une séance peut être annulée par Hoplà! jusqu’à six (6) heures avant le début de cette dernière. Hoplà! se réserve toutefois le droit d’annuler une
séance en extérieur jusqu’à une (1) heure avant le début de la séance en cas de conditions météorologiques ne permettant pas sa tenue. En cas
d’annulation, les Clients concernés recevront un appel (ou, en l’absence de réponse, un SMS) et la séance sera reportée à une date ultérieure.
Hoplà! ne pourra être tenu responsable pour le retard de l’exécution de la prestation en cas de force majeure telle que les catastrophes d’origine
atmosphérique, un accident survenu sur le trajet de l’Intervenant ou tout autre cas de force majeure. Dans cette hypothèse, le Client sera alors
prévenu le plus rapidement possible et la séance sera reportée à une date ultérieure.

<h1>Condition physique</h1>

Le Client est tenu d’avoir une condition physique qui lui permet la pratique de l’activité physique et sportive qu’il aura sélectionnée.
Le Client devra avoir passé une visite médicale préalable à toute activité auprès d’un médecin et produire un certificat médical d’aptitude à l’activité
physique et sportive de moins de trois (3) mois et remettre une version signée de fiche d’information et décharge de responsabilité qui figure en
annexe du bulletin d’inscription avant la première séance.<p/>
Le certificat médical devra être renouvelé chaque année et présenté à l’Intervenant.<p/>
Dans le cadre des séances dispensées auprès des femmes enceintes, une autorisation médicale pourra être demandée par Hoplà!.<p/>
<h1>Règlement intérieur</h1>
Le Client doit porter une tenue de sport correcte et propre pour la pratique des activités sportives (sauf instructions contraire de l’Intervenant).
Le Client doit prendre toute précaution nécessaire pour sa santé, sa sécurité et son hygiène et à respecter les consignes de l’Intervenant en ce sens.<p/>

Sans préjudice de ce qui suit, Hoplà! et l’Intervenant pourront, sans préavis ni indemnité d’aucune sorte, exclure voire mettre fin au Contrat de tout
Client dont l’attitude ou le comportement serait contraire aux bonnes mœurs, ou notoirement gênant, inacceptable ou dangereux pour lui-même
ou pour les autres.<p/>
<h1>Durée et maintien du Contrat</h1>

Le Contrat prend fin à la première des deux dates entre (i) la date à laquelle toutes les séances ont été effectuées ou (ii) la date à laquelle le délai
pour effectuer les séances a expiré alors même que toutes les séances n’ont pas été effectuées en raison d’un acte ou omission du Client.
Le délai pour effectuer les séances est de 12 mois à compter de la date de réservation (date de création de la facture), sauf geste commercial par
Hoplà! accordé en cas de force majeure (cause médicale ou accident).<p/>
En cas de réalisation partielle du Contrat, l’intégralité du prix reste dû à Hoplà! à moins que cette réalisation partielle résulte d’un acte ou omission
de Hoplà!.<p/>
Aucune cause de suspension ou résiliation du Contrat par le Client n’est admise, sauf en cas de force majeure, raisons médicales ou accident
empêchant la pratique du sport définitive. Le Client se doit d’apporter les preuves du cas de suspension du Contrat, et, dans ce cas seulement,
Hoplà! s’engage à lui rembourser les frais qu’il aurait engagé pour des séances qu’il ne sera pas en mesure de suivre. Les séances consommées
seront donc facturées au prix unitaire de 85€ TTC.<p/>
Le Contrat et les séances ne sont pas cessibles par le Client sans accord préalable de Hoplà!.<p/>

<h1>Tarifs et modalités de paiement</h1>
Le prix fixé par Hoplà! est garanti pendant la durée du Contrat. Hoplà! se réserve la possibilité d’actualiser le prix de la prise en charge lors de la
souscription d’un nouveau contrat.<p/>
Les prix sont affichés en euros (€) toutes taxes comprises.<p/>
Ils comprennent les séances, les déplacements nécessaires aux Intervenants pour se rendre sur le lieu d’intervention. Les prix tiennent compte de
la réflexion menée pour la préparation des séances techniques et du suivi de la personne.<p/>
Le nombre de séances et le total du prix seront fonction du nombre de séances sportives nécessaires pour atteindre les objectifs du Client et
pourraient donc évoluer en fonction des besoins du client variables en cours de contrat, auquel cas la conclusion d’un nouveau contrat entre Hoplà!
et le Client pourrait être nécessaire.<p/>
Le Client s’engage à payer Hoplà! aux dates et conformément aux modalités de paiement indiquées sur le bulletin d’inscription. Si le Contrat est
conclu en présence de Hoplà! et du Client dans un lieu qui n’est pas celui où Hoplà! exerce son activité en permanence ou de manière habituelle, le
Client doit attendre un délai de sept (7) jours à compter de la conclusion avant de procéder au paiement.<p/>
En cas de retard dans le paiement des factures, une pénalité égale à 1,5 fois le taux de l’intérêt légal pourra être appliquée par Hoplà! du seul fait du
non-respect de la date d’échéance par le Client. Le montant de ces pénalités ne peut être inférieur à 7,50 € TTC.<p/>
<h1>Assurance et responsabilités</h1>

Chaque Client doit souscrire une police d’assurance responsabilité civile personnelle, le couvrant de tous les dommages qu’il pourrait causer à des
tiers, de son propre fait, durant les prestations. Hoplà! conseille par ailleurs à chaque Client de souscrire une assurance de dommage corporel liée à
l’activité physique et sportive le couvrant pour sa propre intégrité physique.<p/>
Hoplà! a souscrit une assurance pour couvrir les dommages engageant sa responsabilité professionnelle. Les Intervenants qui ne sont pas salariés
de Hoplà! sont des professionnels indépendants dont Hoplà! n’est pas responsable et qui doivent également souscrire et maintenir une assurance
professionnelle.<p/>
La responsabilité de Hoplà! ne pourra être recherchée qu’en cas de démonstration d’un lien de causalité entre une faute lourde commise par Hoplà!
dans l’exécution du Contrat et le dommage subi par le Client. La responsabilité de Hoplà! ne pourra notamment pas être recherchée (i) en cas
d’accident résultant de l’inobservation par le Client des consignes de l’Intervenant ; (ii) en cas d’accident intervenant hors des séances ou conseils
prodigués par les Intervenants ; (iii) en cas d’actes ou omissions des Intervenants qui ne sont pas des salariés de Hoplà! ; (iv) si le Client ne respecte
pas les engagements qu’il prend et garanties qu’il donne au titre Contrat ; et/ou (v) en cas de force majeure. Les dommages indirects (tels que, sans
que cette liste ne soit limitative, une perte de revenus, une perte de chance, un manque à gagner, tout préjudice financier ou tout préjudice
immatériel) ne pourront pas faire l’objet d’une indemnisation de la part de Hoplà!.<p/>

Hoplà! n’est pas responsable en cas demeure seul responsable des engagements qu’il prend et des garanties qu’il donne au titre du Contrat.<p/>

Le Client sera responsable en cas de dégradation occasionnée ou en cas de dommage matériel ou corporel résultant d’un acte volontaire ou
involontaire survenant de son fait.<p/>
Le Client s’engage par ailleurs à garantir et prendre en charge l’ensemble des condamnations et des frais de justice qui pourraient être encourus ou
mis à la charge de Hoplà! du fait des fautes, manquements ou autres actes survenant du fait du Client.<p/>
<h1>Vente à distance (Internet)</h1>

Cette section des conditions générales de vente a pour objet de définir les conditions et modalités de vente des services offerts par Hoplà! sur le
Site uniquement et ne s’applique pas aux Contrats conclu par tout autre moyen.<p/>
Le Site s’adresse uniquement aux particuliers et les professionnels doivent contacter directement Hoplà!. Les offres en ligne présentées sur le Site
sont réservées aux Clients résidant en France métropolitaine.<p/>
Le Client Internet reconnaît que la procédure de commande sur le site constitue un Contrat entre le Client Internet et Hoplà!.
Le Client Internet doit lire attentivement les conditions générales de vente et les accepter avant de valider chaque commande.
Avant de passer commande, le Client Internet doit bien étudier la description et les caractéristiques des services qu’il souhaite acquérir. Les
photographies et illustrations ne constituent pas un document contractuel.<p/>

L’acceptation de l’offre par le Client Internet est validée, conformément au procédé du double clic, par la confirmation de sa commande.
En cliquant sur le premier bouton « Procédez à la commande » pour passer la commande puis sur le second bouton « valider » pour confirmer
ladite commande, le Client Internet reconnaît par ce biais avoir lu, compris et accepté les conditions générales de vente sans limitation ni condition.
Les conditions générales de vente sont référencées en bas de chaque page au moyen d’un lien et doivent être consultées avant de cliquer sur le
second bouton « Valider » pour soumettre la commande. Hoplà! demande également au Client Internet de lire et accepter les conditions générales
de vente à chaque nouvelle commande.<p/>
Les prix affichés sur le Site sont applicables pour tout achat effectué sur le Site.<p/>
Les prix sont affichés en euros (€) toutes taxes comprises.<p/>
Une fois les services sélectionnés et placés dans son panier virtuel, le Client Internet clique sur le panier virtuel et vérifie que le contenu de sa
commande est correct avant de cliquer sur « [Valider la commande] » pour procéder au paiement.<p/>
Le Client Internet devra alors fournir, sur la même page, toutes les informations nécessaires à la facturation et à l’exécution des services en
saisissant ses coordonnées bancaires avant de cliquer sur le premier bouton « [Commander] » pour finaliser son achat. Les achats sur le Site
doivent comprendre toutes les informations nécessaires au bon traitement de la commande.<p/>
Hoplà! autorise le paiement par carte bancaire ou carte de crédit, comme les cartes Visa, Mastercard, American Express, Paypal.<p/>

Le Site fait l’objet d’un système de sécurisation. Hoplà! a confié son système de paiement à MONETICO (Le Crédit industriel et commercial
prestataire spécialisé dans la sécurisation des paiements en ligne. Le Crédit industriel et commercial garantit la totale confidentialité des
informations bancaires du Client Internet.<p/>
Au moment de l’affichage du récapitulatif d’achat, le prix total sera affiché.<p/>
Avant de valider la commande, le Client a la possibilité de vérifier le détail de sa Commande et son prix total et de revenir aux pages précédentes
pour corriger d’éventuelles erreurs ou éventuellement modifier sa commande conformément à l’article 1369-5 du Code civil. La confirmation de la
commande entraîne acceptation des conditions générales de vente et formation du contrat. Lors de son achat sur le Site, le Client reconnaît
explicitement son obligation de paiement.<p/>
Le paiement sera encaissé par Hoplà! dès la conclusion de la commande
Le Client Internet recevra un courrier électronique de confirmation de son achat. L’achat n’est définitivement confirmé qu’au moment de la
validation du paiement et n’engage Hoplà! qu’à réception du courrier électronique confirmant que la commande a bien été validée (confirmation
envoyée uniquement pour les achats sur le Site). Le Client Internet doit en conséquence consulter sa messagerie électronique. Tout service acheté
faisant partie de la même commande qui ne figurerait pas dans le courrier électronique de confirmation ne fait pas partie du Contrat.<p/>

À défaut d’indication ou d’accord préalable quant à la date d’exécution de la prestation, Hoplà! exécute ou fait exécuter la prestation sans retard
injustifié et au plus tard trente (30) jours après la conclusion du contrat.<p/>
Le Client Internet dispose de quatorze (14) jours calendaires à compter du jour de la conclusion du Contrat sur le Site pour se rétracter. Le Client
Internet devra informer Hoplà! de sa volonté claire et non-équivoque de se rétracter par tout moyen. Le Client Internet pourra utiliser le formulaire
de rétractation mis à sa disposition par Hoplà! et devra veiller à en conserver une preuve en cas de contestation.<p/>
Dans l’hypothèse d’une rétractation, Hoplà! remboursera le Client Internet de la totalité des sommes versées sans retard injustifié et au plus tard
dans les quatorze (14) jours suivant la date à laquelle il est informé de la décision du Client Internet de se rétracter. Le remboursement s’effectuera
sur le même moyen de paiement utilisé lors de l’achat. Aucun frais ne sera payé à la suite de ce remboursement.<p/>
Le Client Internet aura renoncé à son droit de rétraction en cas d’exécution de la prestation dans le délai de rétractation de quatorze (14) jours
calendaires.<p/>
L’archivage des communications, des bons de commande et des factures est effectué sur un support fiable et durable de manière à constituer une
copie fidèle et durable. Ces communications, bons de commande et factures peuvent être produits à titre de preuve du Contrat.<p/>
<h1>Données personnelles</h1>

Dans le cadre d’une inscription, le Client fournit des données personnelles qui sont obligatoires et nécessaires pour traiter l’inscription et exécuter
la prestation et qui peuvent être utilisées à des fins de communication commerciale de la part de Hoplà!. Les données personnelles seront
conservées par Hoplà! au maximum deux (2) années à compter de la fin Contrat. Les destinataires des données sont Hoplà!, les professionnels
(coachs sportifs) désignés par Hoplà! pour réaliser les prestations de services dans le cadre d’un Contrat.<p/>

Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée en 2004, le Client bénéficie d’un droit d’accès et de rectification aux
informations qui le concernent.<p/>
Chaque Client pourra exercer son droit d’accès, d’opposition et de rectification en adressant une lettre simple à Hoplà!, 128 avenue du docteur
David Rosenfeld 93230 Romainville ou un courrier électronique à l’adresse hopla.coaching@gmail.com
Le Client peut, pour des motifs légitimes, s’opposer au traitement des données le concernant. Le Client pourra également s’opposer à l’utilisation de
ses données personnelles à des fins de communication commerciale par courrier électronique en utilisant le lien ad hoc qui figure sur chaque
message électronique envoyé.<p/>
L’opposition d’un Client à l’utilisation de ses données personnelles dans le cadre de communications commerciales a pour conséquence de le priver
de l’information concernant les offres commerciales de Hoplà!, de sociétés affiliées ou de partenaires.<p/>
<h1>Images</h1>
Le Client se doit d’indiquer s’il accepte que son image soit utilisée par Hoplà!, et, le cas échéant, signer l’exemplaire d’autorisation de droit à l’image.
Propriété intellectuelle<p/>
Tous les éléments visuels et sonores de Hoplà! et du Site, y compris la technologie sous-jacente utilisée, sont protégés par le droit d’auteur, le droit
des marques, le droit des brevets et plus généralement par tous droits de propriété intellectuelle. Ces éléments sont la propriété exclusive de
Hoplà! ou de ses concédants. Aucun des éléments du Site ou de tout ou partie de ceux-ci ne pourra être modifié, reproduit, copié, dupliqué, vendu,
revendu, transmis, publié, communiqué, distribué, diffusé, représenté, stocké, utilisé, loué ou exploité de toute autre manière, à titre gratuit ou
onéreux, quel que soient les moyens et/ou les supports utilisés, qu’ils soient connus ou inconnus à ce jour, sans l’autorisation préalable exprès et
écrite de Hoplà!.<p/>
<h1>Service clientèle</h1>

La satisfaction de ses clients est une préoccupation constante de Hoplà!.<p/>
Hoplà! essaie de répondre aux attentes des ses Clients le plus rapidement possible et de leur répondre dès que possible après réception d’une
demande, suggestion ou réclamation.<p/>
Pour toute demande, suggestion ou réclamation, le Client est invité à contacter le service client : Hoplà! – 128 avenue du docteur David Rosenfeld
93230 Romainville ou un courrier électronique à l’adresse hopla.coaching@gmail.com<p/>
<h1>Droit applicable et juridiction compétente</h1>

Tout litige relatif à l’interprétation et à l’exécution du Contrat est soumis au droit français. Les différends qui viendraient à se produire à propos de
la validité, de l’interprétation, de l’exécution ou de l’inexécution, de l’interruption ou de la résiliation du Contrat, seront soumis à la médiation
conformément au règlement de médiation du CMAP – Centre de médiation et d’arbitrage de Paris – près le Chambre de commerce et d’industrie de
Paris Ile-de-France, dont les Parties ont eu connaissance et auquel elles déclarent adhérer. Dans l’hypothèse d’un échec de la médiation, les
différends seront portés devant les juridictions françaises compétentes.<p/>
<h1>Divers</h1>


Toute modification de la législation ou de la réglementation en vigueur, ou toute décision d’un tribunal compétent invalidant une ou plusieurs
clauses des conditions générales de vente ne saurait affecter la validité de l’intégralité des conditions générales de vente, dont les autres
stipulations garderont toutes leur force et leur portée. Une telle modification ou décision n’autorise en aucun cas les Clients à méconnaître les
présentes conditions générales de vente.<p/>
Le fait pour Hoplà! de ne pas se prévaloir, à un moment donné, de l’une quelconque des clauses du Contrat, ne peut être interprété comme une
renonciation à s’en prévaloir ultérieurement.<p/>
Les conditions générales de vente sont consultables sur le Site et peuvent être modifiées à tout moment à la discrétion de Hoplà! sans autre
formalité que leur mise en ligne, seule la dernière version sera applicable.

          </Typography>
        </Box>
      </Container>
    </Box>
  );
};
