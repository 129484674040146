import { all, call, put, takeEvery, takeLeading } from 'redux-saga/effects'
import { getSpecialities } from '../services/specialityService';
import { LOAD_SPECIALITIES, LOAD_SPECIALITIES_RESULT } from '../slices/coachSlice';

export default function* coachSaga() {
    yield all([
        takeEvery(LOAD_SPECIALITIES, loadSpecialitiesSaga)
    ]);
}

function* loadSpecialitiesSaga() {
    try {
        yield put(LOAD_SPECIALITIES_RESULT(yield call(getSpecialities)));
    } catch (e) {
        
    }
}