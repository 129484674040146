import axios from 'axios';
import { HOPLA_URL } from '../config/config';
import { Client, ClientPrivate, UpdateUserResult } from '../models/Client';
import { Location } from '../models/Location';

export const setCoachFavorite = async(coachId: number, favorite: boolean) => {
    const api = favorite ? 'setFavorite' : 'removeFavorite';
    return (await axios.get(HOPLA_URL + '/client/' + api + '/' + coachId)).data;
}

export const getClient = async (clientId: number) : Promise<Client> => {
    return (await axios.get(HOPLA_URL + '/client/' + clientId)).data;
}

export const getClientPrivate = async (clientId: number) : Promise<ClientPrivate | undefined> => {
    return (await axios.get(HOPLA_URL + '/client/private/' + clientId)).data;
}

export const canViewClient = async (clientId: number) :Promise<boolean> => {
    return (await axios.get(HOPLA_URL + '/client/canView/' + clientId)).data;
}

export const checkClientAI = async () :Promise<boolean> => {
    return (await axios.get(HOPLA_URL + '/client/checkAI')).data;
}

export const updateClient = async (email: string, image: string, location: Location | null, phone: string | null) : Promise<UpdateUserResult> => {
    return (await axios.post(HOPLA_URL + '/client/update',
    {
        image,
        location,
        phone,
        email,
        specialities: []
    })).data;
}