import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { LoggedType } from "../../slices/loginSlice";
import { Box, Button, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import HoplaTheme from "../../theme";
import { useHistory } from "react-router";

const title = {
  color: '#ff6b2b',
  fontWeight: 'bold'
}

const text = {
  color: 'black'
}

const box = {
  justifyContent: 'center',
  alignItems: 'center',
  padding: '30px 0px'
}

const hrContainer = {
  display :'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const hrStyle = {
  width: '90%',
  marginLeft: '10%',
  height: '4px',
  color: 'black',
  backgroundColor: 'black'
}

const hrLStyle = {
  width: '90%',
  marginRight: '10%',
  height: '4px',
  color: 'black',
  backgroundColor: 'black'
}

const DefiscalisationFooter: React.FC = () => {
    const matchDownMd = useMediaQuery(HoplaTheme.breakpoints.down("sm"));
    const history = useHistory();
    
    return (
      <Box>
        <Grid container>
          <Grid item xs={12} sm={7}>
          <Box sx={{...box, margin: {
              sm: '0% 5% 0% 20%',
              xs: '0% 10%'
            }}}>
                <Typography style={title} variant="h4">
                La Défiscalisation : Réduisez vos dépenses avec Hopla Coaching
                </Typography>
                <Typography style={text} variant="h5">
                Grâce au partenariat entre Hopla Coaching et le service Avance immédiate de l'Urssaf, vous pouvez
maintenant bénéficier d'une défiscalisation avantageuse pour vos séances de sport. Notre objectif est de
vous offrir une réduction significative sur le coût de vos séances, afin de rendre l'activité physique encore
plus accessible. Profitez de cette opportunité pour prendre soin de votre santé sans vous soucier de votre
budget.
                </Typography>
            </Box>
          </Grid>

          { !matchDownMd && <Grid item xs={5} sx={hrContainer}>
            <hr style={hrStyle}/>
          </Grid> }
              
          { !matchDownMd && <Grid item xs={5} sx={hrContainer}>
            <hr style={hrStyle}/>
          </Grid> }
            
          <Grid item xs={12} sm={7}>
          <Box sx={{...box, margin: {
              sm: '0% 20% 0% 5%',
              xs: '0% 10%'
            }}}>
            <Typography style={title} variant="h4">
            Comment fonctionne la défiscalisation avec Hopla Coaching ?
                </Typography>
                <Typography style={text} variant="h5">
                Avec notre programme de défiscalisation, vous ne paierez que la moitié du tarif normal de nos séances.
Par exemple, si le prix d'une séance est de 50 €, vous ne payerez que 25 € grâce au crédit d'impôt dont
vous bénéficierez. Cela représente une économie considérable pour vous, tout en vous permettant de
profiter de nos services de coaching sportif de haute qualité.
                </Typography>
              </Box>
          </Grid>

          <Grid item xs={12} sm={7}>
          <Box sx={{...box, margin: {
              sm: '0% 5% 0% 20%',
              xs: '0% 10%'
            }}}>
            <Typography style={title} variant="h4">
            Le service Avance immédiate de l'Urssaf : un avantage exclusif
                </Typography>
                <Typography style={text} variant="h5">
                L'Avance immédiate, mis en place par l'Urssaf en collaboration avec la Direction générale des Finances
publiques, vous permet de bénéficier du montant de votre crédit d'impôt directement lors de vos paiements à
Hopla Coaching. Fini l'attente du remboursement l'année suivante, vous pouvez maintenant profiter de la
réduction de prix immédiatement.
                </Typography>
            </Box>
          </Grid>

          { !matchDownMd && <Grid item xs={5} sx={hrContainer}>
            <hr style={hrStyle}/>
          </Grid> }

          { !matchDownMd && <Grid item xs={5} sx={hrContainer}>
            <hr style={hrStyle}/>
          </Grid> }
            
          <Grid item xs={12} sm={7}>
          <Box sx={{...box, margin: {
              sm: '0% 20% 0% 5%',
              xs: '0% 10%'
            }}}>
            <Typography style={title} variant="h4">
            Les avantages de notre défiscalisation pour vous
                </Typography>
                <Typography style={text} variant="h5">
                Avec notre programme de défiscalisation, vous pouvez :
                <ul>
                  <li>Économiser sur le coût de vos séances de sport</li>
                  <li>Accéder à des services de coaching de qualité à un prix abordable</li>
                  <li>Améliorer votre santé et votre bien-être sans contraintes budgétaires</li>
                </ul>
                </Typography>
              </Box>
          </Grid>

        </Grid>

        <Box style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Typography color='black' variant="h5" fontWeight='bold' sx={{width: '70%', pt: 10, pb: 10}} align='center'>
          Rejoignez dès maintenant Hopla Coaching et profitez de notre offre de défiscalisation exceptionnelle.
Nous sommes déterminés à vous offrir des séances de sport de haute qualité à des tarifs avantageux,
pour que vous puissiez atteindre vos objectifs de remise en forme en toute tranquillité.
          </Typography>
        </Box>
       
        <Box sx={{backgroundColor: '#104f5c'}}>
          <Grid container sx={{pt: 10, pb: 10}}>
            <Grid item xs={12} sm={5}>
              <Box sx={{
                display: 'flex',
                justifyContent: {
                  md: 'flex-end',
                  xs: 'center'
                }
              }}>

              <Button variant='contained' size='large' 
                  style={{
                    textTransform: 'none',
                    margin: '10px',
                    maxHeight: '50px', minWidth: '240px',
                    minHeight: '50px', borderRadius: 20, fontSize: '1.6em',
                    cursor: 'pointer'}}
                    onClick={() => window.open('mailto:contact@hoplacoaching.fr')}>
                  Nous contacter
                </Button>
              </Box>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={12} sm={5}>
              <Box sx={{
                display: 'flex',
                justifyContent: {
                  md: 'flex-start',
                  xs: 'center'
                }
              }}>

              <Button variant='contained' size='large' 
                  style={{
                    textTransform: 'none',
                    margin: '10px',
                    maxHeight: '50px', minWidth: '240px', 
                    minHeight: '50px', borderRadius: 20, fontSize: '1.6em',
                    cursor: 'pointer'}}
                    onClick={() => history.push('/acces-membre/coach')}>
                 Devenir coach Hop Là
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
}

export default DefiscalisationFooter;