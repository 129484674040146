import HoplaTheme from "../../../theme";
import globalStyleClasses from "../../../style";

const classes = {
  ...globalStyleClasses,
  datePicker: {
    borderRadius: "5px",
    border: "2px solid #104f5c !important",
    marginTop: "15px",
    "& .MuiPickersCalendarHeader-label": {
        color: '#104f5c',
      textTransform: "capitalize",
    },
    "& .MuiPickersDay-root:not(.Mui-disabled), .PrivatePickersYear-yearButton:not(.Mui-disabled)":
      {
            color: '#104f5c',
        borderColor: '#104f5c',
        fontSize: "14px",
        "&.Mui-selected": {
          color: HoplaTheme.custom.White,
        },
      },
    "& .MuiPickersDay-root.Mui-disabled, .PrivatePickersYear-yearButton.Mui-disabled":
      {
            fontSize: "14px",
        color: "#104f5c",
      },
    "& .MuiTypography-caption": {
        fontSize: "14px",
      color: "#104f5c",
    },
  },
  dispoPickerAddBtn: {
    fontStyle: "italic",
    textTransform: "unset",
    color: 'white'
  },
  datePickerTextfield: { width: "1px", visibility: "hidden" },
  dispoPickerPopover: {
    "& .MuiBox-root:first-child": {
      borderRadius: "5px",
      border: "2px solid #104f5c !important",
      p: 3,
      width: "500px",
      [HoplaTheme.breakpoints.down("sm")]: {
        width: "90%",
      },
      "& .MuiTypography-root": {
        textAlign: "center",
            color: '#104f5c',
      },
    },
    "& .MuiButton-text": {
      borderRadius: "10px",
      py: 0,
      m: 0.2,
    },
    "& .MuiButton-textWarning": {
        fontSize: "12px",
      color: '#104f5c',
      "&:hover": {
        color: '#104f5c',
        backgroundColor: HoplaTheme.custom.White,
      },
    },
    "& .MuiButton-textSecondary": {
        fontSize: "12px",
      color: HoplaTheme.custom.White,
      backgroundColor: '#104f5c',
      "&:hover": {
        color: HoplaTheme.custom.White,
        backgroundColor: '#104f5c',
      },
    },
    "& .MuiButton-textPrimary": {
        fontSize: "12px",
      color: '#104f5c',
      backgroundColor: HoplaTheme.custom.White,
      border: `1px solid '#104f5c'`,
    },
  },
};
export default classes;
