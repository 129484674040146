import { Box, Button, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import classes from "../style";

import Img1 from '../images/defisc_1.jpg';
import Img2 from '../images/defisc_2.jpg';
import Img3 from '../images/defisc_3.jpg';
import { useEffect, useState } from "react";
import { Reduction } from "../models/Reduction";
import { getPromotion } from "../services/cardService";
import { ReductionBanner } from "../components/reductionBanner/ReductionBanner";
import HoplaTheme from "../theme";

import BonPlan from "../images/Fond_bonPlan.jpg";
import Macaron from '../images/svg/Macaron.svg';
import DefiscalisationFooter from "../components/layout/DefiscalisationFooter";

export const DefiscalisationPage: React.FC = () => {
  const [currentPromotion, setPromotion] = useState<Reduction>();
  const matchDownMd = useMediaQuery(HoplaTheme.breakpoints.down("md"));

  useEffect(() => {
    const get = async () => {
      try {
        const r = await getPromotion();

        if (r) {
          setPromotion(r);
        }
      } catch (e) {
        
      }
    };

    get();
  }, []);

  return (
    <Box sx={classes.defiscalisation}>
      <Box sx={{
            backgroundImage: `url(${BonPlan})`, 
            height: 'auto', 
            flexDirection: 'column',
            width: '100%',
            backgroundRepeat: "no-repeat", backgroundSize:'cover',
            display: 'flex',
            alignItems:'center', justifyContent: 'center'
          }}>
            <Box sx={{
                backgroundColor: '#104f5c', margin: '0', padding: '80px 10px',
                width: '100%'
            }}>
                <Typography variant="h4" color='white' align='center'>
                    Que diriez-vous si on vous disait que vous pouvez
                </Typography>
                <Typography variant="h3" color='white' fontWeight='bold' align='center'>
                    DÉFISCALISER vos séances de sport
                </Typography>
                <Typography variant="h3" color='white' fontWeight='bold' align='center'>
                    et SANS faire d’avance ? *
                </Typography>
            </Box>
            <Box sx={{
                width:'70%', height: 'auto', paddingBottom: '360px'
            }}>
                <Box sx={{margin:'70px 0px 30px 0px'}}>
                    <Typography variant='h3' color='#ff6100' fontWeight='bold'>Le service Avance immédiate de l'Urssaf :
                      </Typography>
                    <Typography variant='h3' color='#ff6100' fontWeight='bold'>un avantage exclusif</Typography>
                </Box>
                <Grid container mt={5}>
                    <Grid item xs={12} md={9}>
                    <Box sx={{margin:{
                        md: '20px 0px',
                        xs: '10px 0px'
                    }}}>
                        <Typography variant="h5" color='black' component='span' display='inline' sx={{margin: '20px 0px'}}>
                        Grâce au partenariat entre Hopla Coaching et le service Avance immédiate de l'Urssaf, vous
bénéficiez d'une
                            <Box display='inline' fontWeight='bold'> défiscalisation avantageuse </Box>
                            pour vos séances de sport.
                        </Typography>

                        <br/><br/><br/><br/>
                        <Typography variant="h5" color='black' component='span' display='inline' sx={{margin: '20px 0px'}}>
                        L'Avance immédiate, mise en place par l'Urssaf en collaboration avec la Direction générale
des Finances publiques, vous permet de
                            <Box display='inline' fontWeight='bold'> bénéficier du montant de votre crédit d'impôt
directement lors de vos paiements à Hopla Coaching. </Box>
                        </Typography>

                        <br/><br/><br/><br/>
                        <Typography variant="h5" color='black' component='span' display='inline' sx={{margin: '20px 0px'}}>
                        Fini l'attente du remboursement l'année suivante, vous pouvez maintenant profiter de la
réduction de prix 
                            <Box display='inline' fontWeight='bold'> immédiatement.</Box>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={10} md={3}>
                        <img src={Macaron} width='50%' style={{marginLeft: '35%'}}/>
                    </Grid>
                </Grid>
                <Box sx={{
                    display: 'flex', 
                    justifyContent: !matchDownMd ? 'flex-start' : 'center',
                    alignItems: !matchDownMd ? 'flex-start' : 'center'
                }}>
                    <Button variant='contained' size='large' 
                        style={{
                            textTransform: 'none',
                            maxWidth: '240px', maxHeight: '60px', minWidth: '240px',
                            minHeight: '60px', borderRadius: 20, fontSize: '1.3em',
                            marginTop: '20px'}}
                            onClick={() => window.open('https://www.servicesalapersonne.gouv.fr/beneficier-des-sap/services-la-personne-avantages-fiscaux-et-sociaux-pour-particuliers', '_blank')}>
                        En savoir plus !
                    </Button>
                </Box>
            </Box>
        </Box>


      <Box style={{backgroundColor: '#afc9c9', padding: '80px 0px'}} 
          display='flex'
          justifyContent='center'
          alignItems='center'>
          <Box sx={{
            width: {
              lg: '60%',
              sm: '70%',
              xs: '80%'
            }, backgroundColor: '#104f5c', padding: 5
          }}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'>
                      <Typography variant='h6' color='white' align='center' mb={3}>
                        Plein tarif
                      </Typography>
                    </Box>
                </Grid>

                <Grid item xs={2}>
                  <hr style={{border: '1px solid white', backgroundColor: 'white', height: '100%', width: '1px'}} />
                </Grid>

                <Grid item xs={5} paddingTop='0px'>
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'>
                      <Typography variant='h6' color='white' align='center' fontWeight='bold' mb={3}>
                        Tarif après Avance immédiate / Défiscalisation
                      </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} sx={{height: '1px'}}>
                  <Box
                      display='flex'
                      justifyContent='center'
                      alignItems='center'>
                        <hr style={{backgroundColor: 'white', height: '1px', width: '85%', border: '1px solid white'}} />
                    </Box>
                </Grid>

              <Grid item xs={5} paddingBottom='0px'>
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'>
                      <Typography variant='h5' color='white' align='center' mt={3}>
                        85€
                      </Typography>
                    </Box>
                </Grid>

                <Grid item xs={2}>
                  <hr style={{border: '1px solid white', backgroundColor: 'white', height: '100%', width: '1px'}} />
                </Grid>

                <Grid item xs={5}>
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'>
                      <Typography variant='h5' color='white' align='center' fontWeight='bold' mt={3}>
                      42,50€
                      </Typography>
                    </Box>
                </Grid>
              </Grid>
          </Box>
      </Box>

      {currentPromotion ? (
        // <Banniere
        //   title={currentPromotion.text}
        //   content1={`${reduc} de réduction sur votre 1ère séance avec le code : ${currentPromotion.code}`}
        // />
        <ReductionBanner 
          title={currentPromotion.text}
          subTitle={`avec le code « ${currentPromotion.code} »`} 
          />
      ) : (
        ""
      )}

      <DefiscalisationFooter />
    </Box>
  );
};
