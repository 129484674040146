import { FC } from "react";

import Box from "@mui/material/Box";
import classes from "../style";
import { useMediaQuery, Container, Typography, Grid } from "@mui/material";
import HoplaTheme from "../theme";
import Boxe from "../images/boxe-pied-poing.jpeg";
import Fitness from "../images/SQUAT.jpeg";
import GymDouce from "../images/GYM_DOUCE.jpeg";
import { useHistory } from "react-router";
import Fond from "../images/FOND_seance.jpg";


const underStyle = {
  fontSize: "20px",
  color: HoplaTheme.custom.White,
};

const Cours: FC<{}> = () => {
  const matchDownMd = useMediaQuery(HoplaTheme.breakpoints.down("sm"));
  const history = useHistory();

  const specialities = [
    {
      id: 1,
      name: "FITNESS",
      enabled: true,
      image: Fitness,
      page: "/sport-et-fitness",
      sub: ["Renforcement musculaire", "Cardio training", "Remise en forme"],
    },
    {
      id: 2,
      name: "BOXE",
      enabled: true,
      image: Boxe,
      page: "/boxe-entrainement",
      sub: ["Boxe anglaise", "Boxe pied poing", "Cardio boxing"],
    },
    {
      id: 3,
      name: "GYM DOUCE",
      enabled: true,
      image: GymDouce,
      page: "/gym-douce-bien-etre",
      sub: ["Pilates", "Yoga", "Gym douce", "Fitness pré/post natal"],
    },
  ];

  const goTo = (page: string) => history.push(page);

  return (
    <>
    {/* <Box sx={{
      backgroundImage: `url(${Boxe2})`, 
      height: 'auto', 
      flexDirection: 'row', paddingTop: '250px', paddingBottom: '180px',
      width: '100%',
      backgroundRepeat: "no-repeat", backgroundSize:'cover'
    }}>
      
    </Box> */}
    
    <Box sx={{
        backgroundColor: 'white',
        height: {
          lg: '0px',
          md: '20px',
          sm: '80px',
          xs: '200px'
        }
      }}/>
      <Box sx={{...classes.cours, background: 'white', minHeight: '1px', height: '1px',
        width: '100%'}}>
        <Container maxWidth="xl" style={{position: 'relative', top: '-320px', padding: '0px !important', margin: '0px !important'}}>
        <Box style={{display: 'flex', justifyContent: 'center', justifyItems: 'center', alignContent: 'center', alignItems: 'center'}}>
          <Typography style={{padding: '60px'}} variant='h3' fontWeight='bold' color='#ff6100'>
            Nos cours
          </Typography>
        </Box>
            <Grid container spacing={0} className="coursImgList">
              <Grid item sx={{ width: { xs: "100%", sm: "30%", padding: '0' } }}>
                <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <div
                  className="coursImg"
                  style={{
                    backgroundImage: `url(${specialities[0].image})`,
                  }}
                >
                  <Box onClick={() => goTo(specialities[0].page)} style={{cursor: 'pointer'}}>
                    {specialities[0].sub.map((m, j) => (
                      <Typography key={j} style={underStyle}>
                        {m}
                      </Typography>
                    ))}
                  </Box>
                </div>
                { !matchDownMd && <Box style={{display: 'flex', justifyContent: 'center', justifyItems: 'center', alignContent: 'center'}}>
                  <Typography variant='h4' color='white' mt={4}>FITNESS</Typography>
                </Box> }
                </div>
              </Grid>
              <Grid item sx={{ width: { xs: "100%", sm: "40%", padding: '0' } }}>
                <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <div
                  className="coursImg"
                  style={{
                    backgroundImage: `url(${specialities[1].image})`,
                  }}
                >
                  <Box onClick={() => goTo(specialities[1].page)} style={{cursor: 'pointer'}}>
                    {specialities[1].sub.map((m, j) => (
                      <Typography key={j} style={underStyle}>
                        {m}
                      </Typography>
                    ))}
                  </Box>
                </div>
                { !matchDownMd && <Box style={{display: 'flex', justifyContent: 'center', justifyItems: 'center', alignContent: 'center'}}>
                  <Typography variant='h4' color='white' mt={4}>BOXE</Typography>
                </Box> }
                </div>
              </Grid>
              <Grid item sx={{ width: { xs: "100%", sm: "30%", padding: 0 } }}>
                <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <div
                  className="coursImg"
                  style={{
                    backgroundImage: `url(${specialities[2].image})`
                  }}
                >
                  <Box onClick={() => goTo(specialities[2].page)} style={{cursor: 'pointer'}}>
                    {specialities[2].sub.map((m, j) => (
                      <Typography key={j} style={underStyle}>
                        {m}
                      </Typography>
                    ))}
                  </Box>
                </div>
                { !matchDownMd && <Box style={{display: 'flex', justifyContent: 'center', justifyItems: 'center', alignContent: 'center'}}>
                  <Typography variant='h4' color='white' mt={4}>GYM DOUCE</Typography>
                </Box> }
                </div>
              </Grid>
            </Grid>
        </Container>
      </Box>
      <Box sx={{width: '100%', 
      height: {
        sm: '300px',
        xs: '650px'
      }, backgroundColor: '#222'}} />
    </>
  );
};

export default Cours;
