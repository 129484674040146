import axios from 'axios';
import { HOPLA_URL } from '../config/config';
import { PlanningSlot, PlanningState } from '../models/Planning';

export const getWeekSlots = async (date: Date) : Promise<PlanningSlot[]> => {
    return (await axios.get(HOPLA_URL + '/planning/slots/' + date.toLocaleString('sv').replace(' ', 'T'))).data;
}

export const updatePlanningSlot = async (date: Date, state: PlanningState) : Promise<{success: boolean, slots: PlanningSlot[]}> => {
    return (await axios.get(HOPLA_URL + '/planning/updateSlot/' + date.toLocaleString('sv').replace(' ', 'T') + '/' + state)).data;
}

export const updateMultiplePlanningSlots = async (date: Date, state: PlanningState) : Promise<{success: boolean, slots: PlanningSlot[]}> => {
    return (await axios.get(HOPLA_URL + '/planning/updateSlots/' + date.toLocaleString('sv').replace(' ', 'T') + '/' + state)).data;
}

export const getWeekSlotsHistory = async () : Promise<PlanningSlot[]> => {
    return (await axios.get(HOPLA_URL + '/planning/history?take=30')).data;
}

export const getClientPastHistory = async (skip?: number, take?: number) : Promise<PlanningSlot[]> => {
    return (await axios.get(HOPLA_URL + '/planning/client/past?take=' + (take ?? 10) + '&skip=' + (skip ?? 0))).data;
}

export const getClientFuturHistory = async () : Promise<PlanningSlot[]> => {
    return (await axios.get(HOPLA_URL + '/planning/client/futur')).data;
}

export const getClientPastHistoryCount = async () : Promise<number> => {
    return (await axios.get(HOPLA_URL + '/planning/client/count')).data;
}

export const acceptSlot = async (date: Date, clientId: number) : Promise<boolean> => {
    return (await axios.get(HOPLA_URL + '/planning/accept/' + (date.toLocaleString('sv').replace(' ', 'T')) + '/' + clientId)).data;
}

export const refuseSlot = async (date: Date, clientId: number) : Promise<boolean> => {
    return (await axios.get(HOPLA_URL + '/planning/refuse/' + (date.toLocaleString('sv').replace(' ', 'T')) + '/' + clientId)).data;
}

export const clientCancelsSlot = async (date: Date, coachId: number) : Promise<boolean> => {
    return (await axios.get(HOPLA_URL + '/planning/cancel/' + (date.toLocaleString('sv').replace(' ', 'T')) + '/' + coachId)).data;
}