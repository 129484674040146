import React, { FC } from "react";

import Box from "@mui/material/Box";
import classes from "./style";
import { Button, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { ProposedCoach } from "../../models/ProposedCoach";
import { Image } from "../Image";
import { Subtitle } from "../shared/subtitle";
import { Rate } from "../shared/rate";
import { CommentList } from "../shared/commentList";
import { getCoachImage } from "../../services/imageService";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface CoachsProps {
  reservations: ProposedCoach[];
  openModal: (prop: ProposedCoach) => void;
}

const Coachs: FC<CoachsProps> = ({ reservations, openModal }: CoachsProps) => {
  const store = useSelector((s: RootState) => s.reservation);

  return (
      reservations.length > 0 ? 
      (<Box sx={{...classes.coachs, top: {
        lg: '-200px',
        md: '-130px',
        sm: '-80px',
        xs: '0px'
      }}}>
        {reservations.map((r, i) => (
          <Box sx={classes.coachBloc}>
            {
              i > 0 && <hr style={{height: '3px', backgroundColor: 'black', margin: '0% 8% 2% 8%'}}/>
            }
            <Grid container alignItems="center" mt={2}>
              <Grid item sm={4} xs={12} textAlign="center">
                <Image
                    userId={r.coach.id}
                    isClient={false}
                    favoris={r.isFavorite}
                    editable={false} circle
                  />
              </Grid>
              <Grid item lg={4} sm={7} xs={12} sx={{
                m: {
                  lg: '0px',
                  xs: '10px'
                }
              }}>
                <Box display='flex' flexDirection='row'>
                  <Typography variant="h4" fontWeight='bold' mr={2}>
                    {r.coach.firstname} {r.coach.lastname}
                  </Typography>
                  <Rate rate={r.coach.note} />
                </Box>
                <Typography variant="body1" fontWeight='bold' display='inline' mr={1}>
                  Spécialités: 
                </Typography>
                <Typography variant="body1" display='inline'>
                  {r.coach.specialities?.map((j: any) => j.name).join(", ")}
                </Typography>
                <br/>
                <Typography variant="body1" fontWeight='bold' display='inline' mr={1}>
                Nombre de séances effectuées:
                </Typography>
                <Typography variant="body1" display='inline'>
                {r.lessons}
                </Typography>
              </Grid>
              <Grid lg={2} md={12} xs={12} textAlign="center" pl={3} style={{
                display: 'flex', justifyContent: 'center', alignItems: 'center'
              }}>
                <Button
                  sx={{ ...classes.submitBtn, fontSize: "22px", m: {
                    lg: '0% 0%',
                    xs: '0% 30%'
                  } }}
                  onClick={() => openModal(r)}
                >
                  Choisir
                </Button>
              </Grid>
              <Grid item xs={12} mr={2} ml={2}>
                <CommentList comments={r.comments} />
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>)
    :
    <div/>);
}

export default Coachs;
