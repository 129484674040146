import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Location } from "../models/Location";
import { ProposedCoach } from "../models/ProposedCoach";
import { ReservationResultType } from "../models/ReservationProposal";
import { validateReservation } from "../services/reservationService";
import { ADD_TO_CARD } from "../slices/reservationSlice";
import { RootState } from "../store";
import { Image } from "./Image";
import { Rate } from "./shared/rate";
import classes from "../style";
import { dateToString, getSimpleHour } from "../utils/dateUtils";
import { showMessage } from "../services/popupService";
import { ShoppingCardSession } from "../models/ShoppingCard";

export interface RecapSeanceProps {
  proposition: ProposedCoach | undefined;
  location: Location | undefined;
  speciality: number;
  price: number;
  canConfirm: boolean;
  onClose: (accepted: boolean) => void;
}

export const RecapSeance: React.FC<RecapSeanceProps> = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const state = useSelector((s: RootState) => s);

  const speciality = useSelector((s: RootState) => s.coaches.specialities).find(
    (s) => s.id === props.speciality
  );

  const validate = async () => {
    const exclus = state.reservation.card
      ?.map((c) => c as ShoppingCardSession)
      .filter((c) => c?.sessionDate != undefined)
      .map((c) => c.sessionDate) ?? [];

    // if (exclus.some(p => Math.abs(p.getTime() - (props.proposition?.date ?? new Date()).getTime()) / (1000 * 60) >= 30)) {
    //   showMessage('Vous avez déjà une séance à cette date dans votre panier');
    //   return;
    // }

    if (loading) return;
    setLoading(true);

    let accept = false;
    try {
      const r = await validateReservation({
        coachId: props.proposition?.coach.id ?? 0,
        specialityId: speciality?.id ?? 0,
        placeId: props.location?.placeId ?? "",
        date: props.proposition?.date ?? new Date(),
      });

      if (r.result === ReservationResultType.OK) {
        dispatch(ADD_TO_CARD(r.proposal));
        showMessage('Votre cours a été ajouté à votre panier !');
        accept = true;
      } else if (r.result == ReservationResultType.SessionExists) {
        showMessage('Vous avez déjà une séance sur ce créneau horaire dans votre panier.');
      } 
      else {
        showMessage('Une erreur est survenue lors de la réservation de votre séance.');
      }
    } catch (e) {
      showMessage('Une erreur est survenue, veuillez réessayer.');
    }

    setLoading(false);
    props.onClose(accept);
  };

  return (
    <Box>
      {props.proposition === undefined ||
      props.location === undefined ||
      props.speciality === undefined ? (
        ""
      ) : (
        <Grid container spacing={0} mt={5} alignItems="center">
          <Grid item xs={12} sm={5} textAlign="center">
            <Image 
              editable={false}
              favoris={props.proposition.isFavorite}
              userId={props.proposition.coach.id}
              isClient={false}
              />
            <Typography variant="h5">
              {props.proposition.coach.firstname}{" "}
              {props.proposition.coach.lastname}
            </Typography>
            <Rate rate={props.proposition.coach.note} />
          </Grid>
          <Grid item xs={12} sm={7} pl={5} sx={classes.cellBorder}>
            <Box my={2}>
              <Typography variant="body1" sx={classes.label}>
                Type de séance
              </Typography>
              <Typography variant="body2">
                {speciality?.name ?? ''}
              </Typography>
            </Box>
            <Box my={2}>
              <Typography variant="body1" sx={classes.label}>
                Adresse
              </Typography>
              <Typography variant="body2">
                {props.location.description}
              </Typography>
            </Box>
            <Box my={2}>
              <Typography variant="body1" sx={classes.label}>
                Date
              </Typography>
              <Typography variant="body2">{dateToString(new Date(props.proposition.date)) + ' ' + getSimpleHour(new Date(props.proposition.date))}</Typography>
            </Box>
            <Box my={2}>
              <Typography variant="body1" sx={classes.label}>
                Prix
              </Typography>
              <Typography variant="body2">{props.price} €</Typography>
            </Box>
          </Grid>
          {
            props.canConfirm ? 
            (<Grid item xs={12} textAlign="center">
              <Button
                onClick={() => validate()}
                sx={[classes.submitBtn, { my: 2 }]}
              >
                Confirmer la séance
              </Button>
            </Grid>)
            : ''
          }
        </Grid>
      )}
    </Box>
  );
};
