import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, debounce, put } from 'redux-saga/effects'
import { getAutocompleteLocation } from '../services/locationService';
import { CALL_LOCATION_AUTOCOMPLETE, CALL_LOCATION_AUTOCOMPLETE_RESULT } from '../slices/locationSlice';

export default function* locationSaga() {
    yield all([
        debounce(300, CALL_LOCATION_AUTOCOMPLETE, callLocationSaga)
    ]);
}

function* callLocationSaga(action: PayloadAction<{pattern: string, cities: boolean}>) {
    try {
        yield put(CALL_LOCATION_AUTOCOMPLETE_RESULT(yield call(getAutocompleteLocation, action.payload.pattern, action.payload.cities)));
    } catch (e) {
        
    }
}