import HoplaTheme from "../../../theme";
import globalStyleClasses from "../../../style";

const classes = {
  ...globalStyleClasses,
  commentList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    "& .MuiButton-root": {
      color: HoplaTheme.custom.Black,
      fontSize: "12px",
      fontStyle: "italic",
      justifyContent: "end",
    },
  },
};
export default classes;
