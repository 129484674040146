const gymDouce = "Améliorez votre souplesse et votre bien-être avec nos séances de coaching de gym douce. Des coachs expérimentés à votre disposition pour une pratique en toute sécurité.";
const mentions = "Hopla Coaching, une société transparente et engagée. Consultez nos mentions légales pour en savoir plus sur notre équipe et nos valeurs.";
const cgv = "Hopla Coaching vous propose des conditions générales claires et transparentes pour vous garantir une expérience en toute sérénité.";
const login = "Connectez-vous à votre espace client Hopla Coaching pour accéder à tous nos options de coaching sportif personnalisés.";
const boxe = "Avec Hopla Coaching entraînez-vous à la boxe et bénéficiez de l'encadrement de nos coachs professionnels pour progresser rapidement.";
const defisc = "Découvrez comment payer vos séances à moitié prix. Nous sommes à votre disposition pour vous guider.";
const home = "Chez Hopla Coaching, nous mettons notre expertise à votre disposition pour vous aider à atteindre vos objectifs sportifs. Découvrez nos programmes dès maintenant.";
const fitness = "Vous cherchez à améliorer votre forme physique en toute simplicité ? Découvrez nos séances de fitness en ligne encadrés par des coachs professionnels.";

export function getSeoMetadata(location: string) {
    let data = "";
    switch (location)
    {
        case "/forgot-password":
        case "/client":
        case "/coach":
        case "":
        case "/": data = home; break;
        case "/acces-membre": data = login; break;
        case "/defiscalisation-immédiate": data = defisc; break;
        case "/gym-douce-bien-etre": data = gymDouce; break;
        case "/boxe-entrainement": data =boxe; break;
        case "/sport-et-fitness": data = fitness; break;
        case "/conditions-generales-de-vente": data = mentions; break;
        case "/conditions-generales-de-vente-hoplacoaching": data = cgv; break;
        default: data = home; break;
    }

    return data;
}