import { Box, Grid, Link, Typography, useMediaQuery } from "@mui/material";
import { FC } from "react";
import Tuto1Icon from '../images/svg/Tuto1.svg';
import Tuto2Icon from '../images/svg/Tuto2.svg';
import Tuto3Icon from '../images/svg/Tuto3.svg';

import Tuto1 from '../images/Tuto1.jpg';
import Tuto2 from '../images/Tuto2.jpg';
import Tuto2b from '../images/Tuto2b.jpg';
import Tuto3 from '../images/Tuto3.jpg';
import HoplaTheme from "../theme";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const separatorStyle = {
    height: '5px',
    backgroundColor: 'black',
    marginTop: '20px'
}

export const ClientTuto : FC = () => {
    const isXs = useMediaQuery(HoplaTheme.breakpoints.only("xs"));
    const isLg = useMediaQuery(HoplaTheme.breakpoints.up("lg"));
    const isSm = useMediaQuery(HoplaTheme.breakpoints.down("lg"));
    
    return (
        <Box
            display='flex'
            flexDirection='column'
            justifyContent='center' mb={5}
            alignContent='center'>
            <Box display='flex'
                flexDirection='column'
                justifyContent='center'
                alignContent='center'
                py={3}
                sx={{backgroundColor: '#f36b29'}}>
                <Typography color='white' fontWeight='bold' variant='h3' align='center'>
                Voici les 3 étapes pour économiser 50% sur vos séances de coaching !
                </Typography>
            </Box>
            <Box sx={{backgroundColor: 'white', mx: {
                md: '10%',
                xs: '2%'
                }, border: '5px solid #f36b29', marginTop: '-5px'}}>
                <Box mx={4}>
                    <Grid container my={6} spacing={5}>
                        { isSm && <Grid xs={3}/>}
                        <Grid item lg={2} xs={6}>
                            <Box
                                display='flex'
                                flexDirection='column'
                                justifyContent='center'
                                alignItems='center'>
                                <img src={Tuto1Icon} height='100px'/>
                                <Typography color='black' variant='h6' fontWeight='bold' fontStyle='italic'>
                                    4 minutes
                                </Typography>
                            </Box>
                        </Grid>
                        { isSm && <Grid xs={3}/>}
                        <Grid item lg={4} sm={12}>
                            <Box
                                display='flex'
                                flexDirection='column'
                                justifyContent='center'
                                alignItems='start'>
                                <Typography color='black' variant="h5" align='left'>
                                Vous allez maintenant créer votre compte Urssaf.
                                </Typography>
                                <br/>
                                <Typography color='black' variant="h5" display='inline'>
                                Il est très important que vous utilisiez 
                                    <Box display='inline' fontWeight='bold'> la même
                                adresse mail que pour votre inscription sur Hoplà !</Box>
                                </Typography>
                                <br/>
                                <Typography color='black' variant="h5">
                                Voici le lien : <Link href='https://avance-immediate.fr/auto-declaration/63ed37f6396fc90843f87212'>
                                    https://avance-immediate.fr/auto-declaration/63ed37f6396fc90843f87212</Link>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item lg={6} md={12} xs={12}>
                            <Box
                                display='flex'
                                flexDirection='column'
                                justifyContent='center'
                                alignItems='center'>
                                <img src={Tuto1} style={{maxWidth: '100%'}}/>
                            </Box>
                        </Grid>

                        { !isXs && <Grid item lg={3} sm={2} xs={1}>
                            <hr style={separatorStyle}/>
                        </Grid> }

                        <Grid item lg={6} sm={8} xs={12}>
                            <Typography align='center' color='black' variant='h4' fontWeight='bold'>
                                On vous attend pour la prochaine étape !
                            </Typography>
                        </Grid>

                        { !isXs && <Grid item lg={3} sm={2} xs={1}>
                            <hr style={separatorStyle}/>
                        </Grid> }





                        { isSm && <Grid xs={3}/>}
                        <Grid item lg={2} xs={6}>
                            <Box
                                display='flex'
                                flexDirection='column'
                                justifyContent='center'
                                alignItems='center'>
                                <img src={Tuto2Icon} height='100px'/>
                                <Typography color='black' variant='h6' fontWeight='bold' fontStyle='italic'>
                                    2 minutes
                                </Typography>
                            </Box>
                        </Grid>
                        { isSm && <Grid xs={3}/>}
                        <Grid item lg={4} xs={12}>
                            <Box
                                display='flex'
                                flexDirection='column'
                                justifyContent='center'
                                sx={{ alignItems: {
                                    lg: 'start',
                                    xs: 'center'
                                }}}>
                                <Typography color='black' variant="h5" align='left'>
                                Votre compte est bientôt créé ! 
                                </Typography>
                                <br/>
                                <Typography color='black' variant="h5" display='inline' mb={2}>
                                Tu viens de recevoir un mail de l’Urssaf
afin de valider ton compte. 
                                </Typography>

                                <img src={Tuto2} style={{maxWidth: '100%'}}/>
                            </Box>
                        </Grid>

                        { isLg && <Grid item lg={1}>
                                <ArrowForwardIosIcon />
                            </Grid>}
                        <Grid item lg={5} md={12} xs={12}>
                            <Box
                                display='flex'
                                flexDirection='column'
                                justifyContent='center'
                                alignItems='center'>
                                <Typography color='black' variant="h5" align='left'>
                                    <ul>Pour cela, vous devez maintenant :
                                    <li> Changer votre mot de passe Urssaf</li>
                                    <li> Confirmer votre identifiant</li>
                                    <li> <Box display='inline' fontWeight='bold'> Accepter « hop là » comme prestataire</Box></li>
                                    </ul>
                                </Typography>

                                <img src={Tuto2b} style={{maxWidth: '100%'}}/>
                            </Box>
                        </Grid>

                        { !isXs && <Grid item lg={3} sm={2} xs={1}>
                            <hr style={separatorStyle}/>
                        </Grid> }

                        <Grid item lg={6} sm={8} xs={12}>
                            <Typography align='center' color='black' variant='h4' fontWeight='bold'>
                            On arrive déjà à la dernière étape !
                            </Typography>
                        </Grid>

                        { !isXs && <Grid item lg={3} sm={2} xs={1}>
                            <hr style={separatorStyle}/>
                        </Grid> }





                        { isSm && <Grid xs={3}/>}
                        <Grid item lg={2} xs={6}>
                            <Box
                                display='flex'
                                flexDirection='column'
                                justifyContent='center'
                                alignItems='center'>
                                <img src={Tuto3Icon} height='100px'/>
                                <Typography color='black' variant='h6' fontWeight='bold' fontStyle='italic'>
                                    1 minute
                                </Typography>
                            </Box>
                        </Grid>
                        { isSm && <Grid xs={3}/>}
                        <Grid item lg={4} xs={12}>
                            <Box
                                display='flex'
                                flexDirection='column'
                                justifyContent='center'
                                alignItems='start'>
                                <Typography color='black' variant="h5" display='inline'>
                                C’est fini ! Il vous suffit d’
                                <Box display='inline' fontWeight='bold'>actualiser </Box>
                                votre profil Hoplà ! et votre compte est validé ! S’il ne l’est pas, vérifiez
l‘étape 2 ou contactez-nous au 06 70 84 81 85, nous serons ravis de vous aider à finaliser votre inscription 😉
                                </Typography>
                                <br/>
                                <Typography color='black' variant="h5" display='inline'>
                                Vous pouvez maintenant réserver votre coaching !
                                </Typography>
                                <Typography color='black' variant="h5" fontWeight='bold' display='inline'>
                                Le paiement de votre séance sera effectuée par
l’Urssaf une fois votre coaching effectué 
                                </Typography>
                                <br/>
                            </Box>
                        </Grid>
                        <Grid item lg={6} md={12} xs={12}>
                            <Box
                                display='flex'
                                flexDirection='column'
                                justifyContent='center'
                                alignItems='center'>
                                <img src={Tuto3} style={{maxWidth: '100%'}}/>
                            </Box>
                        </Grid>

                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}