import { Box, Typography } from "@mui/material";
import { FC } from "react";

export interface ReductionBannerProps {
    title: string;
    subTitle: string;
}

export const ReductionBanner: FC<ReductionBannerProps> = (props) => {
    return (
        <Box style={{backgroundColor: '#afc9c9', padding: '80px 0px'}}>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <Box p={1} sx={{backgroundColor: '#ff6100', borderRadius: '15px', margin: {
                    md: '0%',
                    xs: '0% 10%'
                }}}>
                    <Typography color='white' align='center' variant='h3' fontWeight='bold' style={{margin: '5px 15px'}}>
                        { props.title }
                    </Typography>
                </Box>
                <Typography color='black' variant='h4' fontWeight='bold' mt={2}>
                    { props.subTitle }
                </Typography>
            </div>
        </Box>
    );
}