import { Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { PlanningSlot, PlanningState } from "../models/Planning";
import {
  acceptSlot,
  clientCancelsSlot,
  refuseSlot,
} from "../services/planningService";
import { needConfirmation } from "../services/popupService";
import { getSimpleDate } from "../utils/dateUtils";
import WarningIcon from "@mui/icons-material/Warning";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { LoggedType } from "../slices/loginSlice";
import classes from "../style";
import { Image } from "./Image";

export interface SlotViewerProps {
  slot: PlanningSlot;
  updated: (s: PlanningSlot) => void;
  onClose: () => void;
  small?: boolean;
  customStyle?: any;
}

export const PlanningSlotViewer: React.FC<SlotViewerProps> = (props) => {
  const [busySlot, setBusySlot] = useState<PlanningSlot>();
  const store = useSelector((s: RootState) => s);

  const isClient = store.login.loggedType === LoggedType.Client;

  const canCancel = new Date(props.slot.date).getTime() >= new Date().getTime();

  const isBusy = () => {
    return (
      busySlot != undefined &&
      busySlot.clientId != props.slot.clientId &&
      busySlot.date != props.slot?.date
    );
  };

  const refuse = async () => {
    if (isBusy() || props.slot.state != PlanningState.Reserved) return;

    needConfirmation(
      "Êtes vous sûr de vouloir refuser ce cours ?",
      async () => {
        setBusySlot(props.slot);
        try {
          const r = await refuseSlot(props.slot.date, props.slot.clientId);

          if (r) {
            props.updated(props.slot);
            props.onClose();
          } else {
            setBusySlot(undefined);
          }
        } catch (e) {
          console.error(e);
        }

        setBusySlot(undefined);
      },
      () => {}
    );
  };

  const validate = async () => {
    if (isBusy() || props.slot.state != PlanningState.Reserved) return;

    needConfirmation(
      "Êtes vous sûr de vouloir accepter ce cours ?",
      async () => {
        setBusySlot(props.slot);
        try {
          const r = await acceptSlot(props.slot.date, props.slot.clientId);
          if (r) {
            props.updated(props.slot);
            props.onClose();
          } else {
            setBusySlot(undefined);
          }
        } catch (e) {
          console.error(e);
          setBusySlot(undefined);
        }
      },
      () => {}
    );
  };

  const cancel = async () => {
    if (isBusy() || (props.slot.state !== PlanningState.Reserved && props.slot.state !== PlanningState.Validated)) return;
    setBusySlot(props.slot);
    const diff = new Date(props.slot.date).getTime() - new Date().getTime();

    // Il reste moins d'un jour
    const clientConfirm =
      isClient && diff > 0 && diff <= 24 * 60 * 60 * 1000
        ? " Vous ne serez pas remboursé !"
        : "";

    needConfirmation(
      "Êtes vous sûr de vouloir annuler ce cours ?",
      async () => {
        try {
          const r = isClient
            ? await clientCancelsSlot(props.slot.date, props.slot.coachId)
            : await refuseSlot(props.slot.date, props.slot.clientId);

          if (r) {
            props.updated(props.slot);
            props.onClose();
          }
        } catch (e) {
          console.error(e);
        }

        setBusySlot(undefined);
      },
      () => {}
    );
  };

  return (
    <Box sx={{
      ...props.customStyle ?? { backgroundColor: '#a2c2c2', my: 5, p: 5 }
    }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Box 
            display="flex"
            flexDirection='column'
            alignItems='center'
            justifyContent="center">
            <Image
              isClient={!isClient}
              userId={isClient ? props.slot.coachId : props.slot.clientId}
              editable={false} circle
              small={props.small}
            />
            <Typography variant="h5">{props.slot.clientName}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h5" fontWeight='bold'>
            Type de séance
          </Typography>
          <Typography variant="h5">{props.slot.speciality.name}</Typography>
          <br />
          <Typography variant="h5" fontWeight='bold'>
            Adresse
          </Typography>
          <Typography variant="h5">{props.slot.location.description}</Typography>
          <br />
          {isClient || new Date(props.slot.date).getTime() < new Date().getTime() ? (
            ""
          ) : (
            <Box display="flex" alignItems="center">
              <WarningIcon sx={{ color: "red", mr:2 }} />
              <Typography style={{ fontSize: "12px", color: "black", fontWeight: 'bold' }}>
                N'oubliez pas de prendre en compte les temps de déplacement !
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h5" fontWeight='bold'>
            Date
          </Typography>
          <Typography variant="h5">{getSimpleDate(new Date(props.slot.date))}</Typography>
          <br />
          <Typography variant="h5" fontWeight='bold'>
            Heure
          </Typography>
          <Typography variant="h5">
            {new Date(props.slot.date).toLocaleTimeString()}
          </Typography>
        </Grid>
        {isClient ? (
          <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
            {(props.slot.state === PlanningState.Validated ||
              props.slot.state === PlanningState.Reserved) &&
            canCancel ? (
              <>
                <Button variant='contained' size='large' onClick={cancel}
                  sx={{
                    borderRadius: 20, fontSize: '1.3em', my: 3, width: '200px'}}>
                  Annuler
                </Button>
              </>
            ) : (
              ""
            )}
          </Grid>
        ) : (
          <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
            {props.slot.state === PlanningState.Validated && canCancel ? (
              <>
                <Button variant='contained' size='large' onClick={cancel}
                  sx={{
                    borderRadius: 20, fontSize: '1.3em', my: 3, width: '200px'}}>
                  Annuler
                </Button>
              </>
            ) : props.slot.state === PlanningState.Reserved && canCancel ? (
              <Box display='flex'
                   flexDirection='column'
                   justifyContent='center'
                   alignContent='center'
                   >
                <Button variant='contained' size='large' onClick={validate}
                  sx={{
                    borderRadius: 20, fontSize: '1.3em', my: 3, width: '200px'}}>
                  Valider
                </Button><Button variant='contained' size='large' onClick={refuse}
                  sx={{
                    borderRadius: 20, fontSize: '1.3em', my: 3, width: '200px'}}>
                  Refuser
                </Button>
              </Box>
            ) : (
              ""
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
