import HoplaTheme from "../../theme";

import globalStyleClasses from "../../style";

const classes = {
  ...globalStyleClasses,
  mainContainer: {
    marginTop: "96px",
    minHeight: "calc(100vh - 96px)",
    p: 0,
    [HoplaTheme.breakpoints.down("lg")]: {
      backgroundSize: "cover",
      backgroundPosition: "bottom 0 left 20%",
    },
    "&.pageWithWaveBackground": {
      backgroundImage: `url('wave.svg')`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: `bottom`,
    },
  },
  header: {
    display: "flex",
    "& .MuiAppBar-root": {
      py: 1,
      backgroundColor: "white",
    },
    "& .MuiButtonBase-root, a": {
        fontSize: "22px",
      textTransform: "unset",
      textDecoration: "none",
      ml: 2, mr: 3,
      px: 3,
      color: "black",
      [HoplaTheme.breakpoints.down("md")]: {
        fontSize: "22px",
        px: 1,
      },
      "&.active": {
        textDecoration: "underline",
        textUnderlineOffset: "5px",
        textDecorationThickness: "2px",
      },
    },
  },
  menuDrawer: {
    textAlign: "start",
    "& img": {
      height: "60px",
    },
  },
  footer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: HoplaTheme.palette.info.main,
    borderTop: "1px solid rgba(255, 255, 255, 0.5)",
    py: 5,
    "& .MuiGrid-item ": {
      py: 2,
      [HoplaTheme.breakpoints.up("sm")]: {
        py: 1,
        px: 3,
        "&:nth-child(odd)": {
          borderRight: "1px solid rgba(255, 255, 255, 0.5)",
        },
      },
      [HoplaTheme.breakpoints.up("md")]: {
        p: 0,
        textAlign: "center",
        "&:not(:last-of-type)": {
          borderRight: "1px solid rgba(255, 255, 255, 0.5)",
        },
      },
    },
    "& a": {
        fontSize: "16px",
      color: HoplaTheme.custom.White,
      textDecoration: "none",
    },
  },
};
export default classes;
