import { Box, Typography } from "@mui/material";
import classes from "./style";
import FirstQuote from '../../../images/svg/Quoteopened.svg';
import SecondQuote from '../../../images/svg/Quoteclosed.svg';

export interface CommentProps {
  text: string;
  author: string;
}

const quoteStyle = {
  width: '20px',
  height: '20px',
  backgroundColor: 'transparent'
}

export const Comment: React.FC<CommentProps> = (props: CommentProps) => {
  return (
    <Box sx={classes.comment}>
      <Typography variant="body1">
        {props.text}
      </Typography>
      <Typography variant="body2"> {props.author}</Typography>
      <img style={quoteStyle} src={FirstQuote} id="1" />
      <img style={quoteStyle} src={SecondQuote} id="2" />
    </Box>
  );
};
