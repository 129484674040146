export function getSeoTitle(location: string) {
    let title = "";
    switch (location)
    {
        case "/forgot-password":
				case "/client":
				case "/coach":
        case "":
        case "/": title = "Entraînement sur-mesure pour tous les niveaux | Hop La Coaching"; break;
				case "/acces-membre": title = "Connectez-vous à votre compte Hop La Coaching"; break;
				case "/defiscalisation-immédiate": title = "Hop la coaching : votre séance à moitié prix avec l’avance immédiate"; break;
				case "/gym-douce-bien-etre": title = "Entraînement de gym douce pour se remettre en forme | Hop La Coaching"; break;
				case "/boxe-entrainement": title = "Réservez votre séance de boxe en ligne | Hop La Coaching"; break;
				case "/sport-et-fitness": title = "Coaching sportif pour atteindre vos objectifs de fitness | Hop La Coaching"; break;
				case "/conditions-generales-de-vente": title = "Mentions légales Hop La Coaching : politique de confidentialité"; break;
				case "/conditions-generales-de-vente-hoplacoaching": title = "Hop La Coaching : conditions générales de vente et de réservation"; break;
        default: title = "Entraînement sur-mesure pour tous les niveaux | Hop La Coaching"; break;
    }

    return title;
}