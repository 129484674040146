import React, { FC } from "react";
import { Speciality } from "../models/Speciality";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { Image } from "./Image";
import UserCircle from '../images/svg/Cercle_photo.svg';
import HoplaTheme from "../theme";
import { useDispatch } from "react-redux";
import { SET_SPECIALITY } from "../slices/reservationSlice";
import { useHistory } from "react-router";

export interface CoursInfosProps {
    title: string;
    subTitle: string;
    content: React.ReactNode;
    speciality?: Speciality;
    reversed?: boolean;
    image: string;
}

const getImgBoxStyle = (xs: boolean) => 
        !xs ? 
        {width: '100%', height: '100%', position: 'relative', top: '-66px'} as React.CSSProperties
            :
        {width: '100%', height: '100%', marginTop: '10px'} as React.CSSProperties;

export const CoursInfos : FC<CoursInfosProps> = (props: CoursInfosProps) => {
    const mx = '10%';
    const matchDownLg = useMediaQuery(HoplaTheme.breakpoints.down("lg"));
    const matchDownMd = useMediaQuery(HoplaTheme.breakpoints.down("md"));
    const dispatch = useDispatch();
    const history = useHistory();

    const goToHome = () => {
        if (props.speciality !== undefined) {
            dispatch(SET_SPECIALITY(props.speciality?.id));
        }

        history.push("/");
    }

    return props.reversed && !matchDownMd ?
        <Box sx={{backgroundColor: 'white'}} mt={10}>
            <Typography color='#ff6100' variant='h3' my={5} marginLeft={mx} fontWeight='bold'
                sx={{
                    marginLeft: {
                        xs: 0,
                        sm: '43%'
                    }
                }}>
                {props.title}
            </Typography>
            <Box sx={{backgroundColor: '#afc9c9'}}>
                <Box sx={{display: 'flex', flexDirection:'column', 
                    justifyContent: matchDownMd ? 'center' : 'left', 
                    alignItems: matchDownMd ? 'center' : 'left'}} mx={mx}>
                    <Grid container>
                        <Grid item md={5} xs={12} lg={4}>
                            <Box sx={getImgBoxStyle(matchDownMd)}>
                                <div
                                    style={{
                                        aspectRatio: '1/1',
                                        padding: 0,
                                        borderRadius: "100%",
                                        backgroundImage: props.image,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center center",
                                        margin: matchDownMd ? '10%' : '0%'
                                    }}>
                                    <img src={UserCircle} 
                                        style={{
                                            position: 'relative',
                                            left: '24px',
                                            top: '-24px',
                                            height: '100%',
                                            zIndex: 999 
                                        }}/>
                                </div>
                            </Box>
                        </Grid>
                        
                        <Grid item md={1} xs={12} lg={1}/>
                        
                        <Grid item md={6} xs={12} lg={7}>
                            <Typography variant='h4' color='black' fontWeight='bold' my={6}>
                                {props.subTitle}
                            </Typography>
                            {props.content}
                            <Button variant='contained' size='large' onClick={() => goToHome()}
                                style={{ borderRadius: 20, fontSize: '1.3em', margin: '10% 0px' }}>
                                Réservez une séance
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>

        :

        <Box sx={{backgroundColor: 'white'}} mt={10}>
            <Typography color='#ff6100' variant='h3' my={5} marginLeft={mx} fontWeight='bold'>
                {props.title}
            </Typography>

            <Box sx={{backgroundColor: '#afc9c9'}}>
                <Box sx={{display: 'flex', flexDirection:'column', justifyContent: matchDownMd ? 'center' : 'left', 
                    alignItems: matchDownMd ? 'center' : 'left'}} mx={mx}>
                    <Grid container>
                        <Grid item xs={12} md={6} lg={7}>
                            <Typography variant='h4' color='black' fontWeight='bold' my={6}>
                                {props.subTitle}
                            </Typography>
                            {props.content}
                            <Button variant='contained' size='large' onClick={() => goToHome()}
                                style={{ borderRadius: 20, fontSize: '1.3em', margin: '10% 0px' }}>
                                Réservez une séance
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={1} lg={1}/>
                        <Grid item xs={12} md={5} lg={4}>
                        <Box sx={getImgBoxStyle(matchDownMd)}>
                                <div
                                    style={{
                                        aspectRatio: '1/1',
                                        padding: 0,
                                        borderRadius: "100%",
                                        backgroundImage: props.image,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center center",
                                        margin: matchDownMd ? '10%' : '0%'
                                    }}>
                                    <img src={UserCircle} 
                                        style={{
                                            position: 'relative',
                                            left: '24px',
                                            top: '-24px',
                                            height: '100%',
                                            zIndex: 999
                                        }}/>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    ;
}