import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { Rate } from "./shared/rate";
import { Image } from "./Image";
import { Comment } from "../models/ProposedCoach";
import { getSimpleDate } from "../utils/dateUtils";

export interface DerniersAvisProps {
  getImage?: () => Promise<string>;
  onImageChanged?: (image: string) => void;
  editable?: boolean;
  favoris?: boolean;
  small?: boolean;
  responsive?: boolean;
  coachId?: number;
  comments: Comment[];
}

export const DerniersAvis: React.FC<DerniersAvisProps> = (props) => {
  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ maxHeight: "400px", overflow: "auto", width: '100%', px: 2,
          backgroundColor: '#a2c2c2', my: 5, p: 5, mb: 10
        }}
      >
        {props.comments?.map((c, i) => (
          <Grid display="flex" alignItems="center" container spacing={0} my={1}>
            <Grid item xs={4} md={2}>
              <Image editable={false} small userId={c.clientId} isClient={true}/>
            </Grid>
            <Grid item xs={8} md={9}>
              <Box className="comment">
                <Typography>
                 {c.content}
                </Typography>
              </Box>
              <Box display="flex" sx={{ mt: 1, px: 1.5, flexDirection: { xs: 'column', md: 'row' } }}>
                <Typography>{c.clientName} </Typography>
                <Typography sx={{ mx: { md: 1 } }}>
                  <Rate rate={c.note} />
                </Typography>
                <Typography>{getSimpleDate(new Date(c.date))}</Typography>
              </Box>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  );
};
