import globalStyleClasses from "../../style";

const classes = {
  ...globalStyleClasses,
  shopping: {
    "& .iciBtn": {
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
        fontWeight: 700,
      },
    },
    ".MuiTable-root": {
      minWidth: "unset",
    },
    ".MuiTableBody-root": {
      border: "none",
    },
    ".MuiTableBody-root .MuiTableRow-root:not(.collapse):not(:last-of-type)": {
      background: "rgba(140, 106, 212, 0.3)",
      "& .MuiTableCell-root": {
        py: 0,
      },
      "& .MuiTableCell-root:first-of-type": {
        borderTopLeftRadius: "15px",
        borderBottomLeftRadius: "15px",
      },
      "& .MuiTableCell-root:last-of-type": {
        borderTopRightRadius: "15px",
        borderBottomRightRadius: "15px",
      },
    },
    ".MuiTableBody-root .MuiTableRow-root:last-of-type .MuiTableCell-root": {
      borderTop: "2px solid #000",
    },
    ".MuiTableBody-root .MuiTableRow-root": {
      borderTop: "2px solid #000",
      "&:last-child td": {
        borderBottom: 0,
      },
    },
    ".MuiTableHead-root .MuiTableRow-root": {
      "&:first-child th": {
        py: 0,
        borderBottom: 0,
      },
    },
  },
};
export default classes;
