import { createTheme } from "@mui/material/styles";
import SarabunFont from "../assets/fonts/Sarabun-Regular.ttf";

declare module "@mui/material/styles" {
  interface Theme {
    custom: {
      White: string;
      Black: string;
      Gray: string;
      Gray1: string;
      Gray2: string;
      Blue: string;
    };
  }
  interface ThemeOptions {
    custom?: {
      White: string;
      Black: string;
      Gray: string;
      Gray1: string;
      Gray2: string;
      Blue: string;
    };
  }
}

const CustomTheme = createTheme(
  {
    palette: {
      mode: "light",
      primary: {
        main: "#8C6AD4",
      },
      secondary: {
        main: "#FF6104",
      },
      success: {
        main: "#7AB800",
      },
      warning: {
        main: "#5260ff",
      },
      error: {
        main: "#D60707",
      },
      info: {
        main: "#124F5C",
      },
    },
    typography: {
      allVariants: {
        fontFamily: "Sarabun",
      },
      h1: {
        fontSize: "3rem",
        "@media (min-width: 600px)": {
          fontSize: "42px !important",
        },
        "@media (min-width: 900px)": {
          fontSize: "50px !important",
        },
        "@media (min-width: 1200px)": {
          fontSize: "60px !important",
        },
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'Sarabun';
          src: url(${SarabunFont}) format('truetype');
        }
      `,
      },
      MuiContainer: {
        styleOverrides: {
          maxWidthXl: {
            "@media (max-width: 1536px)": {
              maxWidth: "90%",
              paddingLeft: 0,
              paddingRight: 0,
            },
          },
        },
      },
    },
  },
  {
    custom: {
      White: "#FFF",
      Black: "#000",
      Gray: "#E8E8E8",
      Gray1: "#EBEBEB",
      Gray2: "#DFDFDF",
      Blue: "#002B60",
    },
  }
);

export default CustomTheme;
