import axios from 'axios';
import { HOPLA_URL } from '../config/config';
import { Reduction } from '../models/Reduction';
import { ShoppingCard } from '../models/ShoppingCard';

export const getClientCard = async () : Promise<ShoppingCard[]> => {
    return (await axios.get(HOPLA_URL + '/card/clientCard')).data;
}

export const removeCard = async (cardId: number, session: boolean) : Promise<boolean> => {
    return (await axios.get(HOPLA_URL + '/card/deleteCard/' + cardId + '/' + session)).data;
}

export const getPacks = async () : Promise<boolean> => {
    return (await axios.get(HOPLA_URL + '/client/packs')).data;
}

export const getPromotion = async() :Promise<Reduction> => {
    return (await axios.get(HOPLA_URL + '/card/promotion')).data;
}

export const getReduction = async(code: string) :Promise<Reduction> => {
    return (await axios.get(HOPLA_URL + '/card/reduction/' + code)).data;
}