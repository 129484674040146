import { Chip } from "@mui/material";
import { Location } from "../models/Location";

export const LocationChip: React.FC<{
  location: Location;
  delete?: (location: Location) => void;
  customStyle?: any
}> = (props) => {
  return props.delete ? (
    <Chip
      label={props.location.description}
      onDelete={() => props.delete?.(props.location)}
      sx={{...props.customStyle}}
    />
  ) : (
    <Chip sx={{...props.customStyle}} label={props.location.description} />
  );
};
