import { ShoppingCard } from "./ShoppingCard";

export interface PayCommand {
    reductionCode?: string;
    withAI: boolean;
}
export interface PaiementResult {
    basePrice: number,
    finalPrice: number;
    command: PayCommand,
    result: PaiementResultType,
    packUsed: number,
    newPackCount: number,
    withAI: boolean;
}

export enum PaiementResultType
{
    Success = 0, Error, Timeout, NotLogged
}

export interface ReservationResult
{
    url: string,
    resultType: ReservationResultType,
    referenceId: string,
    results: {card: ShoppingCard, result: ReservationItemResultType}[]
}

export enum ReservationResultType
{
    Success = 0, Error, ReductionInvalid, NoItem, Timeout, NotLogged, TooClose, TooCloseAI
}

export enum ReservationItemResultType
{
    Success = 0, NoDispo, Error
}

export interface Price {
    default: number,
    final: number
}

export interface ShoppingPrice {
    price: Price;
    packUsed: number;
    buyingPacks: number;
    previousPacksToUse: number;
    sessionPrice: number;
    packPrice: number;
    canAI: boolean;
}