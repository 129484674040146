import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CALL_LOCATION_AUTOCOMPLETE } from "../slices/locationSlice";
import { RootState } from "../store";
import { Location } from "../models/Location";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import Select, { components } from "react-select";

const style = {
  fontWeight: 'normal',
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 99999,
    minWidth: "100px",
    "&:after": {
      position: "absolute",
      zIndex: 9999,
      content: '""',
      left: "20px",
      top: "0px",
      width: 0,
      height: 0,
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: "16px solid #104f5c",
    },
  }),
  control: (provided: any) => ({
    ...provided,
    borderRadius: 0,
    borderTop: 0,
    borderRight: 0,
    borderLeft: 0,
    fontWeight: 'normal',
    backgroundColor: '#104f5c',
    color: 'white',
    borderColor: "white",
    boxShadow: "none",
    height: "32px",
    minHeight: "32px",
    "&:hover": {
      borderColor: "white",
    },
  }),
  input: (provided: any) => ({
    ...provided,
    color: 'white',
    fontWeight: 'normal !important',
    fontStyle: 'normal'
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: 0,
    color: "white",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    backgroundColor: "transparent",
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "#104f5c !important",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected || state.isFocused ? "#104f5c" : "#000",
    background: "none",
    "&:hover": {
        color: "#104f5c",
      background: "none",
    },
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    border: "2px solid #104f5c",
    marginTop: "15px",
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: "white",
    fontStyle: "normal",
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: "white",
    fontStyle: "italic",
    fontWeight: 'normal !important'
  }),
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownOutlinedIcon />
    </components.DropdownIndicator>
  );
};
export interface PlaceAutocompleteProps {
  onChange: (selection: Location) => void;
  initValue?: Location;
  cities?: boolean;
  disabled?: boolean;
  customStyle?: any;
  placeholder?: string;
  noOptionsMessage?: string;
}

export const PlaceAutocomplete: React.FC<PlaceAutocompleteProps> = ({
  onChange,
  initValue,
  cities,
  disabled,
  customStyle,
  placeholder,
  noOptionsMessage,
}: PlaceAutocompleteProps) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [selectedOption, setSelectedOption] = useState<any>();

  const [init, setInit] = useState<Location>();

  const updateSelection = (selection: any) => {
    setSelectedOption(selection);
    onChange(selection.value);
  };

  const updateSearch = (pattern: string) => {
    setSearch(pattern);
    dispatch(CALL_LOCATION_AUTOCOMPLETE({ pattern, cities: cities ?? false }));
  };

  const filterOptions = (o: any) => true;

  const noOptionMessage = () => (
    <span>{noOptionsMessage || "Aucun élément"}</span>
  );

  const handleInputChange = (newValue: string) => {
    const inputValue = newValue; //.replace(/\W/g, '');
    updateSearch(newValue);
    return inputValue;
  };

  const results = useSelector(
    (state: RootState) => state.location.autocompleteLocations
  );
  const options = results
    ?.map((r) => {
      return {
        value: { placeId: r.placeId, description: r.description },
        label: r.description,
      };
    })
    .filter((k) => k != undefined);

  useEffect(() => {
    if (initValue && initValue.placeId !== init?.placeId) {
      setInit(initValue);
      updateSelection({
        value: {
          placeId: initValue.placeId,
          description: initValue.description,
        },
        label: initValue.description,
      });
    }
  }, [initValue]);

  return (
    <Select
      isDisabled={disabled ?? false}
      menuPortalTarget={document.body}
      styles={customStyle || style}
      value={selectedOption}
      filterOption={filterOptions}
      onInputChange={handleInputChange}
      onChange={updateSelection}
      placeholder={placeholder || "Rechercher une adresse ..."}
      options={options}
      defaultValue={selectedOption}
      components={{ DropdownIndicator }}
      noOptionsMessage={noOptionMessage}
    />
  );
};
