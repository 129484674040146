import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';

import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import coachSaga from "./saga/coachSaga";
import locationSaga from "./saga/locationSaga";
import loginSaga from "./saga/loginSaga";
import reservationSaga from "./saga/reservationSaga";
import coachSlice from "./slices/coachSlice";
import locationSlice from "./slices/locationSlice";
import loginSlice from "./slices/loginSlice";
import reservationSlice from "./slices/reservationSlice";

const sagaMiddleware = createSagaMiddleware();

function * mainSaga() {
  yield all([
    loginSaga(),
    coachSaga(),
    locationSaga(),
    reservationSaga()
  ]);
}

const reducers = combineReducers({
    login: loginSlice,
    coaches: coachSlice,
    location: locationSlice,
    reservation: reservationSlice
});

const store = createStore(
  reducers,
  process.env.NODE_ENV === "production" ?
    applyMiddleware(sagaMiddleware) :
    composeWithDevTools(applyMiddleware(sagaMiddleware)),
)

sagaMiddleware.run(mainSaga);

export default store;

export type RootState = ReturnType<typeof reducers> 