import { Box } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";

export interface RateProps {
  rate: number;
  style?: any
  onChanged?: (note: number) => void;
}

export const Rate: React.FC<RateProps> = (props: RateProps) => {
  const clickOn = (n: number) => {
    if (props.onChanged) {
      props.onChanged(n + 1);
    }
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center" sx={props.style}>
      {Array.from(Array(5).keys()).map((i) =>
        props.rate > i ? (
          <StarIcon sx={{color: '#8a6acf'}} onClick={() => clickOn(i)}/>
        ) : (
          <StarBorderIcon sx={{color: '#8a6acf'}} onClick={() => clickOn(i)}/>
        )
      )}
    </Box>
  );
};
