import { Box, Typography, TextField, Button } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoginResultType } from "../models/Login";
import { changePassword } from "../services/loginService";
import { badPasswordPopup, showMessage, showModal } from "../services/popupService";
import { SET_TOKEN } from "../slices/loginSlice";
import { RootState } from "../store";
import classes from "../style";
import { isPasswordValid } from "../utils/manyUtils";

export const PasswordForm = () => {
  const store = useSelector((s: RootState) => s.login);
  const dispatch = useDispatch();
    
  const [password, setPassword] = useState<string>('');
  const [oldPassword, setOldPassword] = useState<string>('');
  const [confirm, setConfirm] = useState<string>('');

  const change = async() => {
    if (!isPasswordValid(password)) {
      badPasswordPopup();
      return;
    }

    if (password != confirm) {
      showMessage('Les mots de passe ne correspondent pas.');
      return;
    }

    try {
        const r = await changePassword(oldPassword, password, confirm);

        if (r.result === LoginResultType.OK && r.token) {
            dispatch(SET_TOKEN(r.token));
            showMessage('Mot de passe changé !');
        } else {
            switch (r.result) {
              case LoginResultType.Wrong:
                showMessage('Le mot de passe entré est incorrect.'); break;
              default:
                showMessage('Une erreur est survenue.'); break;
            }
        }
    } catch (e)
    {
        showMessage('Une erreur est survenue.');
    }
  }

  return (
    <Box display="flex" flexDirection="column" p={3}>
      <Box my={1}>
        <Typography variant="body1" sx={classes.label}>
          Ancien mot de passe
        </Typography>
        <TextField
          hiddenLabel
          id="ancien-mot-de-passe"
          type='password'
          defaultValue={oldPassword}
          variant="filled"
          size="small"
          sx={classes.input}
          onChange={(e) => setOldPassword(e.target.value!)}
        />
      </Box>
      <Box my={1}>
        <Typography variant="body1" sx={classes.label}>
          Nouveau mot de passe
        </Typography>
        <TextField
          hiddenLabel
          id="mot-de-passe"
          type='password'
          defaultValue={password}
          variant="filled"
          size="small"
          sx={classes.input}
          onChange={(e) => setPassword(e.target.value!)}
        />
      </Box>
      <Box my={1}>
        <Typography variant="body1" sx={classes.label}>
          Confirmation du mot de passe
        </Typography>
        <TextField
          hiddenLabel
          id="confirm-mot-de-passe"
          type='password'
          defaultValue={confirm}
          variant="filled"
          size="small"
          sx={classes.input}
          onChange={(e) => setConfirm(e.target.value!)}
        />
      </Box>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'>
        <Button
          onClick={() => change()}
          sx={[classes.submitBtn, { mt: 2 }]}
        >
          Valider
        </Button>
      </Box>
    </Box>
  );
}
