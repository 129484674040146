import { all, call, put, select, takeEvery, takeLeading } from 'redux-saga/effects'
import { PurchasedPack } from '../models/Pack';
import { ShoppingCard } from '../models/ShoppingCard';
import { getClientCard, getPacks } from '../services/cardService';
import { getPrices } from '../services/reservationService';
import { DISCONNECT, LoggedType } from '../slices/loginSlice';
import { LOAD_CARD, LOAD_CARD_RESULT, LOAD_PRICES, SET_PRICES } from '../slices/reservationSlice';
import { RootState } from '../store';

export default function* reservationSaga() {
    yield all([
        takeLeading(LOAD_CARD, loadCardSaga),
        takeEvery(DISCONNECT, disconnectSaga),
        takeLeading(LOAD_PRICES, loadPricesSaga)
    ]);
}

function* loadPricesSaga() {
    const prices : {sessionPrice: number, packPrice: number} = yield call(getPrices);
    yield put (SET_PRICES(prices));
}

function* disconnectSaga() {
    yield put(LOAD_CARD_RESULT({shop: [], packs: []}));
}

function* loadCardSaga() {
    try {
        const state: RootState = yield select((s: RootState) => s);

        if (state.login.loggedType == LoggedType.Client) {
            const result: ShoppingCard[] = yield call(getClientCard);
            const packs: PurchasedPack[] = yield call(getPacks);

            yield put(LOAD_CARD_RESULT({shop: result, packs}));
        } else {
            yield put(LOAD_CARD_RESULT({shop: [], packs: []}));
        }
    } catch (e) {
        yield put(LOAD_CARD_RESULT({shop: [], packs: []}));
    }
}